import React from 'react';
import {Link} from "react-router-dom";
import {Utilities} from "./utilities";
import HttpService from '../httpservice';
import store from "store";

let httpservice = new HttpService();

let utilities = new Utilities();

class ResetPassword extends React.Component {
    constructor() {
        super();

        this.state = {
            newpassword: "",
            kpassword: "",
            validate: false,
            ajaxcalled: false,

            passwordtype: "password"
        }
    };

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    togglePasswordEye = (caller) => {
        caller === "main"
            ?
        this.setState({
            mainpasswordtype: this.state.mainpasswordtype === "password" ? "text" : "password"
        })
            :
        this.setState({
            subpasswordtype: this.state.subpasswordtype === "password" ? "text" : "password"
        })
    };

    fetchUser = () => {

        httpservice.authGet("auth/check")
            .then(response => {
                this.setState({ajaxcalled: false});

                if(!response.error) {

                    //if(!response.data.has_pin) store.set("tabs", {activetab: "Settings", activesubtab: "SetUpPin"});
                    store.set("user", response.data);

                    this.props.history.push("/dashboard");
                }
                else {
                    utilities.Notify(response.message, "error");
                    this.setState({ajaxerror: response.message, ajaxcalled: false});
                }
            })
            .catch(error => {
                this.setState({ajaxcalled: false})
                console.log("error: ", error);
            })
    };

    // signOut = () => {
    //     NProgress.inc();

    //     httpservice.authPost("auth/sign_out", {})
    //         .then(response => {
    //             NProgress.done();

    //             switch (response.error) {
    //                 case true:
    //                    utilities.Notify("Session expired. Unable to signout.", "error");

    //                     break;
    //                 case false:
    //                     store.clearAll();
    //                     this.props.history.push("/");

    //                     break;
    //                 default:
    //                     store.clearAll();
    //                     this.props.history.push("/");

    //                     break;
    //             }
    //         })
    //         .catch(error => {
    //             NProgress.done();
    //             this.setState({ajaxcalled: false});
    //             console.log("error", error);
    //         });
    // };

    handleSignIn = (phone, password) => {

        phone = utilities.packagePhoneNumber(phone);
        this.setState({ajaxcalled: true});
        
        httpservice.unAuthPost("auth/sign_in", {phone: phone, password: password})
            .then(response => {

                if(!response.error) {
                    store.set("token", response.token);

                    this.fetchUser();
                } else {
                    utilities.Notify(response.message, "error");
                    this.setState({ajaxerror: response.message || response.error, ajaxcalled: false});
                }
            })
            .catch(error => {
                this.setState({ajaxcalled: false})
                console.log("error: ", error);
            })
    };

    handlePasswordReset = () => {
        if(
            !this.state.newpassword
            || !utilities.validPassword(this.state.newpassword)
            || !this.state.kpassword
            || this.state.kpassword.toString() !== this.state.newpassword.toString()
        ) {
            this.setState({validate: true});
        } else {

            this.setState({ajaxcalled: true, validate: false});
        
            httpservice.authPost("auth/update_password", {password: this.state.newpassword})
                .then(response => {

                    if(response.error) {
                        this.setState({ajaxcalled: false});

                        this.setState({ajaxerror: response.message || response.error});
                    }
                    else if(!response.error) {
                        let parseddata = utilities.parseJwt(store.get("token"));

                        this.handleSignIn(parseddata.phone, this.state.newpassword);
                    }
                    else {
                        this.setState({ajaxcalled: false});
                        this.setState({ajaxerror: "Unable to complete your request. Please try again later."});
                    }
                })
                .catch(error => {
                    this.setState({ajaxcalled: false});
                    console.log("error: ", error);
                })
        }
    };

    render() {
        return (
            <article className="animated fadeIn delay-0.5s page-bg">
        
                <section className="mx-auto">
                    <div className="wrapper">

                        <div className="image-holder">
                            <img src="assets/imgs/herohalf.png" alt="" />
                        </div>

                        <div className="form-inner mx-auto">

                            <div className="row mb-4">
                                <div className="col px-2">
                                    <span>
                                        <a className="gen-text-color cp" onClick={() => {this.props.history.push("/")}}>
                                            <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-arrow-left-short font-weight-bold" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                                            </svg>
                                            <span className="vam font-weight-bold">Back</span>
                                        </a>
                                    </span>
                                </div>

                                <div className="col">
                                </div>
                            </div>

                            <form className="form-guy mx-auto">

                                <p className="padding2">&nbsp;</p>
                                <p className="padding2">&nbsp;</p>
                                <p className="padding2">&nbsp;</p>

                                <div className="form-header">
                                    <img src="assets/imgs/npf-logo-main.png" alt="" className="sign-up-icon w-20" />
                                </div>

                                <div className="gen-text-color font-weight-bold mb-5">
                                    <h1 className="mb-4 font-weight-bold">Create a new password.</h1>
                                    <p className="small font-weight-bold">Password must contain at least 1 special character, 1 alphabet and 1 number and must be at least 8 characters in length.</p>
                                </div>

                                <div className="form-group mb-0">
                                    <label htmlFor="" className="small darkcolor font-weight-bold">Password</label>
                                    <input placeholder="Password" type={this.state.mainpasswordtype} className="form-control" value={this.state.newpassword} name="newpassword" onChange={this.handleInput} />

                                    {
                                        this.state.mainpasswordtype === "password"
                                            ? 

                                        <span className="cp eye-icon" style={{left: "88%"}} onClick={() => {this.togglePasswordEye("main")}}>
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-eye-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                                <path fillRule="evenodd" d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                                            </svg>
                                        </span>

                                            :

                                        <span style={{left: "88%"}} className="eye-icon cp" onClick={() => {this.togglePasswordEye("main")}}>
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-eye-slash-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.79 12.912l-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
                                                <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708l-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829z"/>
                                                <path fillRule="evenodd" d="M13.646 14.354l-12-12 .708-.708 12 12-.708.708z"/>
                                            </svg>
                                        </span>
                                    }
                                    <div className="text-danger small mt-n4">{(!this.state.newpassword && this.state.validate) && "Required"}</div>
                                    <div className="text-danger small mt-n4 mb-4">{(this.state.newpassword && !utilities.validPassword(this.state.newpassword) && this.state.validate) && "Password must contain at least 1 special character, 1 alphabet and 1 number and must be at least 8 characters in length."}</div>

                                </div>

                                <div className="form-group mb-0">
                                    <label htmlFor="" className="small darkcolor font-weight-bold">Confirm Password</label>
                                    <input placeholder="Confirm Password" type={this.state.subpasswordtype} className="form-control" value={this.state.kpassword} name="kpassword" onChange={this.handleInput} />

                                    {
                                        this.state.subpasswordtype === "password"
                                            ? 

                                        <span className="cp eye-icon" style={{left: "88%"}} onClick={() => {this.togglePasswordEye("sub")}}>
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-eye-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                                <path fillRule="evenodd" d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                                            </svg>
                                        </span>
                                        
                                            :

                                        <span style={{left: "88%"}} className="eye-icon cp" onClick={() => {this.togglePasswordEye("sub")}}>
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-eye-slash-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.79 12.912l-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
                                                <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708l-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829z"/>
                                                <path fillRule="evenodd" d="M13.646 14.354l-12-12 .708-.708 12 12-.708.708z"/>
                                            </svg>
                                        </span>
                                    }
                                </div>

                                <div className="text-danger small mt-n4 mb-4">{(!this.state.kpassword && this.state.validate) && "Required"}</div>
                                <div className="text-danger small mt-n4">{(this.state.kpassword.toString() !== this.state.newpassword.toString()  && this.state.validate) && "Passwords don't match."}</div>

                                <button disabled={this.state.ajaxcalled} className="login-btn small" type="button" onClick={this.handlePasswordReset}>
                                    <span className="w-85 font-weight-bold ml-50">Reset Password</span>

                                    <span className="w-15 h-100 slant-arrow">
                                        {
                                            this.state.ajaxcalled 
                                                ? 
                                                <utilities.Gear style={{width: "26px", marginTop: "9px"}} /> 
                                                : 
                                                <svg width="1.5em" height="100%" viewBox="0 0 16 16" className="bi bi-arrow-right-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                    <path fillRule="evenodd" d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"/>
                                                </svg>
                                        }
                                    </span>

                                </button>

                                <div className="text-danger small text-center mt-4 justify-content-center">
                                    {!this.state.ajaxcalled && this.state.ajaxerror && this.state.ajaxerror}
                                </div>

                                <div className="row pt-4 text-center justify-content-center">
                                    <div className="">
                                        <Link to="/signup" className="greencolor font-weight-bold small">Create a new account</Link>
                                    </div>
                                </div>

                            </form>
                        </div>
                        
                        <div className="chat-btn">
                            <utilities.ChatButton />
                            
                        </div>

                    </div>
                </section>

            </article>
        );
    }

    componentDidMount() {
        if(store.get("token") && !store.get("signupstage")) {
            this.props.history.push("dashboard");
        }
    }
}

export default ResetPassword;