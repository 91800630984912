export const faqs = {
  main: [
    {
      ques:"For account enquiries, who do I liaise with?",
      ans: "Customer Services Officer"
    },{
      ques:"How do I know if I have any returned cheques?",
      ans: "You will be informed by phone."
    },{
      ques:"If I lose my cheque book, what am I expected to do?",
      ans: "Report in writing to the bank stating the cheques series, police report and sworn affidavit."
    },{
      ques:"How do I order for any cheque?",
      ans: "Fill in the cheque request form and come personally for the cheque."
    },{
      ques:"Can I transfer funds from my account to accounts?",
      ans: "Yes, NIP Transfer available."
    },{
      ques:"How do I get my statements of account?",
      ans: "Upon request."
    },{
      ques:"What is the minimum initial deposit to open an account with the bank?",
      ans: "Current Account ₦2, 000; Corporate Account ₦10, 000; Savings Account ₦2, 000"
    },{
      ques:"What are the charges on the current account?",
      ans: "Account Maintenance Fee, Salary Admin. Charges"
    },{
      ques:"Can I pay a cheque into my savings account?",
      ans: "No."
    },{
      ques:"Can I use NPF Microfinance bank Plc ATM card on other banks' ATMs?",
      ans: "Yes."
    },{
      ques:"Can I use NPF Microfinance Bank Plc ATM card to purchase goods at stores?",
      ans: "Yes (POS)"
    },{
      ques:"What is the bank’s operating hours?",
      ans: "8a.m-4p.m Monday-Friday"
    },{
      ques:"How long does it take to open an account with the bank?",
      ans: "Same Day"
    },{
      ques:"Can I obtain loan from the bank?",
      ans: "Loans can be obtained from all the branches if you operate current account and micro loan account."
    },{
      ques:"What are the securities required?",
      ans: ["Landed property", "Personal Guarantee"]
    },{
      ques:"Can I access my account in any other branches across the country?",
      ans: "Yes, you can."
    },{
      ques:"What other credit products does the bank offer?",
      ans: ["Micro Lease", "Overdraft/Salary Advance payment on card", "NPF Housing Loan", "Edu-KEA Account", "NPF LPO Financing", "Micro Savings", "Asset Acquisition Scheme/Lease"]
    },{
      ques:"How many days does it take to clear a cheque paid into account?",
      ans: "3 days"
    },{
      ques:"What other savings products does the bank offer?",
      ans: "IGP, Fixed deposit KEA, PASA, Edu-KEA, Micro Savings"
    },{
      ques:"How many days does it take to obtain loan?",
      ans: "1 week."
    },{
      ques:"Can I use my share certificate as a form of securities for obtaining loan?",
      ans: "Yes (share of reputable institutions)"
    },{
      ques:"Can I ask for overdraft?",
      ans: "Yes."
    },{
      ques:"Is the loan meant for Police Officers only?",
      ans: "No."
    },{
      ques:"Who does the bank give loan to?",
      ans: "Loans are always given to customers that meet the bank’s requirements."
    },{
      ques:"What charges do I need to pay before my loan is approved?",
      ans: "You need 5% mandatory savings deposit for salary account holder and 30% mandatory savings deposit for non- salary account holders."
    },{
      ques:"What are the monthly charges?",
      ans: "No monthly charges."
    },{
      ques:"Do you charge interest for your services?",
      ans: "Management fee of 2% flat--- only for credit approved."
    },{
      ques:"Can I use NPF ATM card to buy airtime of all Networks?",
      ans: "Yes."
    },{
      ques:"Can I change my pin on any bank’s ATM using NPF Microfinance ATM Verve (Debit / Credit)?",
      ans: "Yes."
    },{
      ques:"Can I do a transfer on the ATM using NPF Debit/Credit Verve card?",
      ans: "No."
    },{
      ques:"When having disputes (ie cash dispense without payment) who /where do I lay complain?",
      ans: "Walk into any Branch of NPF Microfinance Bank Plc requesting for a dispute form through the CSO/E-Business Officer of the branch."
    }
  ], bank_accounts: [
    {
      ques:"Can I open account while outside the country?",
      ans: "Yes through online platform and the bank website"
    },{
      ques:"Is it possible to open an account in the country and operate the account while out the country?",
      ans: "Yes."
    },{
      ques:"Do I need a withdrawal booklet to operate my account?",
      ans: "Yes."
    },{
      ques:"How long does it take to open an account with the bank?",
      ans: "Instantly via Mobile application and one working day via the branch"
    },{
      ques:"How can I open a salary account?",
      ans: "Fill the current account opening form with and introduction letter from your office."
    },{
      ques:"How long does it take before my account becomes Inactive?",
      ans: "6 months"
    },{
      ques:"Do I get charged for operating and doing transactions from another branch other than where my account was opened?",
      ans: "No."
    },{
      ques:"Can the same individual have more than one account with different branches?",
      ans: "Yes."
    },{
      ques:"How does a customer request a cheque book?",
      ans: "Fill a cheque request form."
    },{
      ques:"How long does it take for cheque  request to be processed?",
      ans: "Immediately."
    },{
      ques:"Can a cheque book request be made in one branch and the cheque collected in another?",
      ans: "Yes"
    },{
      ques:"Can a cheque book be requested online?",
      ans: "No"
    },{
      ques:"Can a third party collect a customer’s cheque book? If so, How?",
      ans: "Yes, by letter of authority from the customer."
    },{
      ques:"Can I update my account details from any branch?",
      ans: "Yes."
    },{
      ques:"What is the minimum account opening amount?",
      ans: "Micro Account: N 1000, Saving plus: N2000, Corporate Account: 10,000."
    },{
      ques:"What are requirement for change of address?",
      ans: "Fill the account update form."
    },{
      ques:"How long will it take for the change of address to be effected?",
      ans: "Within 24 hours."
    },{
      ques:"Will the name change take effect immediately?",
      ans: "Yes."
    },{
      ques:"What are the requirement for change of name for a Corporate account?",
      ans: "CAC Certificate."
    },{
      ques:"Will this change be effected immediately?",
      ans: "Within 24hrs"
    }
  ], bank_cards: [
    {
      ques:"Where can I use my  NPF Card?",
      ans: "ATM Terminal, POS Terminal."
    },{
      ques:"Can I use my card to shop online",
      ans: "No."
    },{
      ques:"Is there a fee for withdrawing with my NPF Card?",
      ans: "No Charge on Sterling Co-Branded Card to Sterling ATM; No Charge on UBA Co-Branded Card to UBA ATM; Charges are incurred on other Bank’s ATM (Surcharge)"
    },{
      ques:"My NPF Card expires next month, what am I supposed to do?",
      ans: "Nothing, it will be automatically renewed."
    },{
      ques:"What is the maximum amount I can withdraw from an ATM in 24 hours?",
      ans: "150,000 Naira."
    },{
      ques:"How long does it take before my account becomes Inactive?",
      ans: "6 months"
    },{
      ques:"What is Credit/Debit card?",
      ans: "Credit Card is NPFMFB’s Salary Advance Card; Debit Card is NPFMFB Savings (Regular, Micro) and Current account ( Individual, Salary) cards."
    },{
      ques:"How do I get a Credit /Debit?",
      ans: "Apply from any branch."
    },{
      ques:"I have forgotten my card pin",
      ans: "Get to the nearest branch of NPFMFB for a PIN RESET."
    },{
      ques:"Can I transfer funds using any ATM?",
      ans: "Yes."
    },{
      ques:"Can I recharge with my card?",
      ans: "Yes."
    }
  ], loans: [
    {
      ques:"What are the requirement to get loan?",
      ans: "Depends on loan product and sector"
    },{
      ques:"How much can I access as loan?",
      ans: "Depends on capacity to repay but not more than 1% shareholder’s fund."
    },{
      ques:"Is it compulsory to save money before I can get a loan?",
      ans: "Yes, mandatory savings."
    },{
      ques:"How do I know my repayment balance?",
      ans: "A repayment schedule will be attached with offer letter."
    },{
      ques:"I have repaid my loan in full and I need a letter of non-indebtedness",
      ans: "Yes, we give when the customer makes a request."
    },{
      ques:"What is our interest rate?",
      ans: "24%/36% per annum."
    },{
      ques:"How can I pay off my loan when I have the money?",
      ans: "Fill a consent letter to pay off."
    }
  ], structured_overdraft: [
    {
      ques:"What are the requirement to get Overdraft(OD)?",
      ans: ["Application letter for OD", "2 guarantors with current account", "Credit bureau of the customer", "Mandatory saving", "Collateral as case maybe"]
    },{
      ques:"How much can I access as Overdraft?",
      ans: ["Single Obligor is 100,000 to 5,000,000", "Corporate Obligor is 100,000 to 20,000,000"]
    },{
      ques:"Is it compulsory to save money before I can get an Overdraft?",
      ans: "Yes, Mandatory savings."
    },{
      ques:"How do I know my repayment balance?",
      ans: "Requesting for account statement."
    },{
      ques:"What is our interest rate?",
      ans: "24% per annum."
    },{
      ques:"How can I pay off my Overdraft when I have the money?",
      ans: "You can pay of your overdraft by paying principal and interest in to your account"
    }
  ], unstructured_overdraft: [
    {
      ques:"What are the requirement to get Overdraft?",
      ans: ["Must have salary account with the bank", "The account must be current account"]
    },{
      ques:"How much can I access as Overdraft?",
      ans: ["Single Obligor is 100,000 to 5,000,000", "Corporate Obligor is 100,000 to 20,000,000"]
    },{
      ques:"Is it compulsory to save money before I can get an Overdraft?",
      ans: "No."
    },{
      ques:"How do I know my repayment balance?",
      ans: "Requesting for account statement."
    },{
      ques:"What is our interest rate?",
      ans: "5% Flat."
    },{
      ques:"How can I pay off my Overdraft when I have the money?",
      ans: "Repayment of Overdraft is done monthly after salary payment."
    }
  ], point_of_sale: [
    {
      ques:"How can I request a POS Terminal?",
      ans: "Apply through our branch."
    },{
      ques:"How long does it take to deploy terminals to merchants?",
      ans: "Two working days or more depending on the location."
    },{
      ques:"How can I identify the type of terminal that Is deployed to me as a merchant?",
      ans: "Through the model of the device & Through the home screen of the terminal."
    },{
      ques:"Can my POS Terminal run on my local area network?",
      ans: "No, GPRS only (Sim card)."
    },{
      ques:"What do I do if I am getting the error ISSUER or SWITCH INOPERATIVE?",
      ans: "Inform the card holder to contact his/her bank or Try again Later."
    },{
      ques:"What if Cardholders account is debited without approved receipt printed on the POS Terminal?",
      ans: "The Cardholder contacts his/her bank for reversal."
    },{
      ques:"When do I get settled for transactions done on my POS?",
      ans: "Twice a week."
    },{
      ques:"What if my transactions are not settled as when due, who should I call for help?",
      ans: "Contact the Bank’s Customer care on Toll free line: 08008008008, WhatsApp No: 08074550522, 070462581253."
    }
  ], channel_services: [
    {
      ques:"How can I use your USSD?",
      ans: ["To set up, dial *5757#",
        "Input 10 digit account number",
        "Input date of birth",
        "Create Pin",
        "To Use, dial *5757# and follow the processes ",
        "Features of *5757# are:",
        "1: Fund Transfer",
        "2: Purchase of Airtime",
        "3: Account Balance",
        "4: Bill payment",
        "5: Purchase of data",
        "6: Other services (Pin, Mini Statement, Deactivate USSD, Stop Account, and Salary Advance)"
      ], alternatively: ["*322*552# : Set up",
        "*322*552*0# :Account Balance",
        "*322*552*phone number*amount# : Recharge",
        "*322*552*acct no*amount# : Transfer"
      ]
    }
  ], investing: [
    {
      ques:"What do I need to run/open a fixed account with the bank?",
      ans: "Open an account with us."
    },{
      ques:"What is the minimum and maximum tenor obtainable?",
      ans: "30-90 days."
    },{
      ques:"How is the interest accrued on a deposit calculated?",
      ans: "Daily."
    },{
      ques:"What amount is deducted as withholding Tax and how is it calculated?",
      ans: "Withholding Tax is calculated on interest earned at 10%."
    },{
      ques:"How can I liquidate my fixed/call deposit?",
      ans: "Write letter of Liquidation of Termination of the fixed deposit."
    }
  ]
}

export const branches = [
  {"branch":"GENERAL CUSTOMER CARE","address":"Email – ccare@npfmicrofinancebank.com","branch_phone":"0800-8008-008"},
  {"branch":"HEAD OFFICE ","address":"1 IKOYI ROAD, OBALENDE, LAGOS STATE","branch_phone":"0807-4550-514, 0807-4550-522 (E-BIZ LINE), 0807-7410-610 (ADMIN), 07000CALLNPFMFB"},
  {"branch":"OBALENDE","address":"1 IKOYI ROAD, OBALENDE, LAGOS STATE","branch_phone":"0807-4550-527, 0807-4550-549"},
  {"branch":"ABUJA MAIN","address":"PLOT 1469, AHMADU BELLO WAY, GARKI, FCT, ABUJA","branch_phone":"0807-4550-554, 0807-4550-565"},
  {"branch":"IKEJA","address":"POLICE COLLEGE, OBA  AKINJOBI ROAD, IKEJA, LAGOS STATE","branch_phone":"0807-4550-262, 0807-4550-294 "},
  {"branch":"PORT HARCOURT","address":"OLD ABA ROAD, BESIDE MINI OKORO POLICE STATION, RUMUOGBA, P/H, RIVERS STATE","branch_phone":"0807-4550-307, 0807-4550-331"},
  {"branch":"KANO","address":"MISSION ROAD, BESIDE BOMPAI POLICE STATION, BOMPAI, KANO STATE","branch_phone":"0807-4550-354, 0807-4550-366  "},
  {"branch":"OSOGBO","address":"OSOGBO-ILOBU ROAD, OKEFIA, BESIDE FIRE SERVICE, OSOGBO, OSUN STATE","branch_phone":"0807-4550-372, 0807-4550-374"},
  {"branch":"BENIN","address":"AREA  COMMAND, SAPELE ROAD, BENIN CITY, EDO STATE","branch_phone":"0807-4550-408, 0807-4550-415"},
  {"branch":"SOKOTO","address":"SULTAN ABUBAKAR ROAD, OLD POLICE COMMAND, SOKOTO STATE","branch_phone":"0807-4550-419, 0807-4550-429"},
  {"branch":"ONITSHA ","address":"ENUGU ROAD,BY AREA COMMANDER OFFICE, OPPOSITE COURT ROAD, ONITSHA, ANAMBRA STATE","branch_phone":"0807-4550-443, 0807-4550-439"},
  {"branch":"AKURE","address":"OPPOSITE GOVERNMENT HOUSE, BESIDE POLICE OFFICERS’ MESS, ADEKUNLE AJASIN ROAD, AKURE, ONDO STATE","branch_phone":"0807-4550-469, 0807-4550-476"},
  {"branch":"LOKOJA ","address":"CITY PLAZA, ALONG IBRAHIM TAIWO ROAD, OPPOSITE BISHOP DELISLE COLLEGE, LOKOJA, KOGI STATE.","branch_phone":"0807-4550-480, 0807-4550-495"},
  {"branch":"YOLA","address":"POWA SHOPPING COMPLEX, GALADIMA-AMINU WAY, JIMETA-YOLA, ADAMAWA STATE","branch_phone":"0807-4550-387, 0807-4550-598"},
  {"branch":"ABUJA 2","address":"BESIDE POWA SHOPPING COMPLEX, ALONG LADOKE AKINTOLA BOLUEVARD, ABUJA. ","branch_phone":"0807-4550-512, 0807-4550-510"},
  {"branch":"BAUCHI","address":"NPF MICROFINANCE BANK PLC, BAUCHI, POWA SHOPING COMPLEX, YANDOKA ROAD, BAUCHI.  ","branch_phone":"0807-455-0879, 0807-455-0966"},
  {"branch":"LAFIA","address":"NPF MICROFINANCE BANK PLC, BABA AJUJI HOUSE, JOS ROAD, LAFIA, NASSARAWA STATE ","branch_phone":"0807-460-4394, 0807-460-4089"},
  {"branch":"ENUGU","address":"NPF MICROFINANCE BANK PLC,SUITE A6B BETHEL PLAZA GARDEN AVENUE,ENUGU, ENUGU STATE.","branch_phone":"0807-4550-966"},
  {"branch":"KADUNA","address":"INDEPENDENCE WAY, POLICE COLLEGE KADUNA STATE.","branch_phone":"0807-4108-748"},
  {"branch":"OJI RIVER","address":"NPF MICROFINANCE BANK plc, POLICE COLLEGE OJI RIVER","branch_phone":"0807-7603-201"},
  {"branch":"ABEOKUTA","address":"NPF MICROFINANCE BANK PLC, 40, ISABO RD, KUTO MARKET, GREAT GRACE BUILDING NEAR OBA LIPEDE ULTRA MODERN MARKET ABEOKUTA OGUN STATE","branch_phone":"0807-7410-877"},
  {"branch":"IBADAN","address":"NPF MICROFINANCE BANK PLC, 125 MAGZINE ROAD, JERICHO, IBADAN, OYO STATE ","branch_phone":"0807-7410-630"},
  {"branch":"IKORODU","address":"NO.2, AYANGBUREN ROAD IKORODU, LAGOS STATE","branch_phone":"0807-7411-184"},
  {"branch":"TEJUOSHO","address":"SHOP S-152, TEJUOSHO ULTRA MODERM SHOPPING CENTRE, OJUELEGBA RD OPPOSITE DIAMOND BANK YABA, LAGOS STATE","branch_phone":"0807-7410-700"},
  {"branch":"ABA","address":"NO.105, JUBILEE ROAD ABA, ABIA STATE","branch_phone":"0807-7411-103"},
  {"branch":"ASABA","address":"“A” DIVISION POLICE AREA COMMAND ASABA DELTA STATE","branch_phone":"0807-7411-114"},
  {"branch":"CALABAR","address":"NO.123 GOLDIE ‘STREET,OFF AMIKAUTU, CALABAR, CROSS RIVER","branch_phone":"0807-7411-272"},
  {"branch":"ASWANI","address":"GESTRIC PLAZA 42 OSOLO WAY ISOLO LAGOS STATE","branch_phone":"0807-7411-077"},
  {"branch":"PORT HARCOURT 2","address":"NO.11B, EAST-WEST ROAD PORT HARCOURT RIVER STATE","branch_phone":"0807-7411-112"},
  {"branch":"AWKA","address":"OKEY NWOSU LANE OFF OKPUNO RD(OLD INEC RD) AWKA, ANAMBRA STATE ","branch_phone":"0807-7411-198"},
  {"branch":"JOS","address":"NO.6A, OLD CBN ROAD JOS, PLATEAU STATE","branch_phone":"0811-3490-737"},
  {"branch":"ADO-EKITI","address":"FORMER POLICE PENSION OFFICE BUILDING SITUATED AT OKESHA POLICE BARRACKS, ADO-EKITI, EKITI STATE","branch_phone":"0811-3490-736"},
  {"branch":"MAKURDI","address":"KONSHISHA STREET HIGH LEVEL NO.44, MKD OPPOSITE HIGH LEVEL GUEST HOUSE MAKURDI, BENUE STATE","branch_phone":"0811-3490-735"},
  {"branch":"UYO","address":"NO.439, ORON ROAD, UYO, AKWA-IBOM STATE","branch_phone":"0811-3490-734"},
  {"branch":"OWERRI","address":"NO.7/8B SHOPPING PLAZA OPPOSITE DEEPER LIFE CHURCH RELIEF MARKET IKENEGBU EXTENSION OWERRI IMO STATE","branch_phone":"0811-3490-733"},
  {"branch":"ILORIN","address":"TAWAKALITU PLAZA IBRAHIM TAIWO ROAD OPP. HARMONY HOLDINGS LTD ILORIN, KWARA STATE","branch_phone":"0811-3490-732"},
  {"branch":"MINNA","address":"N.O. 106, OPPOSITE SKYE (POLARIS) BANK BOSSO ROAD MINNA NIGER STATE","branch_phone":"0811-3490-731"}
];