import React from 'react';
import { useState } from 'react';
import { branches, faqs } from '../data';
import {Utilities} from './utilities';

let utilities = new Utilities();

const SupportPage = ({ switchTab, isGuest, history, switchSubTab, showCallCenter, showSuppotMails }) => {
  return (
      <div className="animated fadeIn delay-0.5s">
          <div className="gen-text-color font-weight-bold mb-5">
            <h2 className="mb-4 font-weight-bold">Support Center</h2>
            <p className="small font-weight-bold">Request for help.</p>

            {isGuest && <p className="gen-text-color cp" onClick={() => history.goBack()}>
                <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-arrow-left-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                </svg>
                <span className="vam font-weight-bold">Back</span>
            </p>}
          </div>

          {!isGuest && <div className="row mb-4 signup-cards">
              <div className="col-lg account-card p-4 bvn-card cp" onClick={() => {switchTab("ComingSoon")}} style={{cursor: "pointer"}}>
              {/* <div className="col-lg account-card p-4 bvn-card cp" data-toggle="tooltip" data-placement="top" title="Coming soon."> */}
          
                  <div className="box-part text-left">
                      <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-person" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" d="M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                      </svg>
                      
                      <div className="title mt-2">
                          <p className="font-weight-bold">Talk to live agent</p>
                      </div>
                      
                      <div className="text">
                          <span className="small">Chat with an active agent.</span>
                      </div>
                      
                  </div>
              </div>
  
              <div className="col-lg account-card p-4 bvn-card cp" onClick={() => showCallCenter && showCallCenter()} style={{cursor: "pointer"}}>
              {/* <div className="col-lg account-card p-4 bvn-card cp" data-toggle="tooltip" data-placement="top" title="Coming soon."> */}
          
                  <div className="box-part text-left">
                      
                      <svg width="1.7em" height="2em" viewBox="0 0 16 16" className="bi bi-telephone-inbound" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" d="M15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0zm-12.2 1.182a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                      </svg>
                  
                      <div className="title mt-2">
                          <p className="font-weight-bold">Call help center</p>
                      </div>
                      
                      <div className="text">
                          <span className="small">Call our help desk</span>
                      </div>
                      
                  </div>
              </div>

              <SendMailTab showSuppotMails={showSuppotMails} isGuest={isGuest} switchTab={switchTab} switchSubTab={switchSubTab} />

          </div>}

          <div className="row mb-4 signup-cards"> 


          {isGuest && <SendMailTab showSuppotMails={showSuppotMails} isGuest={isGuest} switchTab={switchTab} switchSubTab={switchSubTab} />}

              <div className="col-lg account-card p-4 bvn-card cp"  onClick={() => {isGuest ? switchTab("SupportBranch") : switchSubTab("Support", "SupportBranch")}} style={{cursor: "pointer"}}>
              {/* <div className="col-lg account-card p-4 bvn-card cp" data-toggle="tooltip" data-placement="top" title="Coming soon."> */}
          
                  <div className="box-part text-left">
                      
                      <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-house" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
                          <path fillRule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
                      </svg>
                      
                      <div className="title mt-2">
                          <p className="font-weight-bold">Locate a branch</p>
                      </div>
                      
                      <div className="text">
                          <span className="small">Discover our nationwide branches</span>
                      </div>
                      
                  </div>
              </div>
  
              <div className="col-lg account-card bvn-card p-4 cp" onClick={() => {isGuest ? switchTab("SupportFaq") : switchSubTab("Support","SupportFaq")}} style={{cursor: "pointer"}}>
              {/* <div className="col-lg account-card bvn-card p-4 cp" data-toggle="tooltip" data-placement="top" title="Coming soon."> */}
          
                  <div className="box-part text-left">
                      
                      <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-question-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.803 0-1.253.478-1.342 1.134-.018.137-.128.25-.266.25h-.825zm2.325 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927z"/>
                      </svg>
                  
                      <div className="title mt-2">
                          <p className="font-weight-bold">Read our FAQs</p>
                      </div>
                      
                      <div className="text">
                          <span className="small">Read through our frequently asked questions</span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  )
};

export const SupportEmail = ({ handleBackButton, showModal }) => {
    return (
        <section>
            <div className="date-range">
                <div>
                    <p className="gen-text-color cp" onClick={handleBackButton}>
                        <svg width="2.5em" height="2.5em" viewBox="0 0 16 16" className="bi bi-arrow-left-short font-weight-bold" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                        </svg>
                        <span className="vam font-weight-bold">BACK</span>
                    </p>
                </div>

                <div className="web-space">
                   
                </div>
                
                <div className="gen-text-color font-weight-bold mb-5 mt-2">
                    <h1 className="mb-4 font-weight-bold">Send an email</h1>
                </div>

                <div className="row">
                    <div className="form-group col-lg">
                        <label htmlFor="exampleFormControlInput1">Email Category</label>

                        <select name="cars" className="custom-select custommenu">
                            <option selected>Email Category</option>
                        </select>

                    </div>

                    <div className="form-group col-lg">
                        <label htmlFor="exampleFormControlInput1">Subject</label>
                        <input type="number" className="form-control" id="exampleFormControlInput1" placeholder="Subject" />
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="exampleFormControlTextarea1">Message</label>
                    <textarea className="form-control p-2" placeholder="Type your message here..." id="exampleFormControlTextarea1" rows="4"></textarea>
                </div>

                <button style={{marginRight: "inherit"}} className="login-btn small w-50" type="button">
                    <span className="w-85 font-weight-bold ml-50">Send Email</span>

                    <span className="w-25 h-100 slant-arrow">
                        <svg width="1.5em" height="100%" viewBox="0 0 16 16" className="bi bi-arrow-right-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path fillRule="evenodd" d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"/>
                        </svg>
                    </span>
                </button>
            </div>
        </section>
    )
};

export const SupportBranch = ({ handleBackButton, isGuest }) => {
    const [search, setSearch] = useState("");
    return (
        <section className={`animated fadeIn delay-0.5s temp-height ${isGuest ? "pt-5 p-3" : ""}`}>
            <div className="">
                {utilities.urlSearchParams().get("source") !== "app" && <div>
                    <p className="gen-text-color cp" onClick={handleBackButton}>
                        <svg width="2.5em" height="2.5em" viewBox="0 0 16 16" className="bi bi-arrow-left-short font-weight-bold" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                        </svg>
                        <span className="vam font-weight-bold">BACK</span>
                    </p>
                </div>}

                <div className="web-space">
                   
                </div>
                
                <div className="gen-text-color font-weight-bold mb-4 mt-2">
                    <h1 className="mb-4 font-weight-bold">Branch Locator</h1>
                </div>

                <div className="py-1 mb-5">
                    <div className="input-group">
                        <input
                            type="search"
                            placeholder="Find a branch..."
                            aria-describedby="button-addon1"
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            className="form-control" />
                        <div className="input-group-append">
                            <button id="button-addon1" type="button" className="btn btn-link form-control">
                                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-search gen-text-color gen-text-color" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
                                    <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                {branches.map(({branch, branch_phone, address}, idx) => {
                    const searchTerm = search.trim().toLowerCase();
                    if(!!searchTerm && !branch.toLowerCase().includes(searchTerm) && !address.toLowerCase().includes(searchTerm)) return null;
                    return(
                        <div key={idx} className="mb-4">
                            <p className="gen-text-color font-weight-bold mb-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="currentColor" className="greencolor bi bi-geo-alt-fill mr-2" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                </svg>

                                <span style={{fontSize: "20px"}}>{branch}</span>
                            </p>

                            <p className="near-fade" style={{fontSize: "19px", fontWeight: "300"}}>{address}</p>
                            <div className="near-fade" style={{fontSize: "19px", fontWeight: "300"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="greencolor mr-2 bi bi-telephone-fill" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M2.267.98a1.636 1.636 0 0 1 2.448.152l1.681 2.162c.309.396.418.913.296 1.4l-.513 2.053a.636.636 0 0 0 .167.604L8.65 9.654a.636.636 0 0 0 .604.167l2.052-.513a1.636 1.636 0 0 1 1.401.296l2.162 1.681c.777.604.849 1.753.153 2.448l-.97.97c-.693.693-1.73.998-2.697.658a17.47 17.47 0 0 1-6.571-4.144A17.47 17.47 0 0 1 .639 4.646c-.34-.967-.035-2.004.658-2.698l.97-.969z"/>
                            </svg>
                                {branch_phone}
                            </div>
                        </div>
                    );
                })}
            </div>
        </section>
    )
};

export const SupportFaq = ({ handleBackButton, isGuest }) => {
    const showFAQ = (category) => {
        return faqs[category].map(({ques, ans, alternatively}, idx) => {
            const prefix = `${category}${idx}`;
            const answer = typeof ans === "string" ? ans : (<ul>
                {ans.map((v, index) => <li key={index}>{v}</li>)}
            </ul>);
            return(
                <div key={idx} className="card faq-card py-2">
                    <div className="card-header py-1 px-0" id={`${prefix}Heading`}>
                        <h5 className="mb-0">
                            <a className="btn btn-link text-decoration-none gen-text-color text-left px-0" data-toggle="collapse" data-target={`#${prefix}Collapse` }aria-expanded={idx === 0 ? "true" : "false"} aria-controls={`${prefix}Collapse`}>
                                {ques}
                            </a>
                        </h5>
                    </div>

                    <div id={`${prefix}Collapse`} className={`collapse ${idx === 0 ? "show" : ""}`} aria-labelledby={`${prefix}Heading`} data-parent={`#${category}Accordion`}>
                        <div className="card-body py-1 px-0">
                            {answer}
                            {alternatively && (<>
                                <p className="pl-3">Or Alternatively</p>
                                <ul>
                                    {alternatively.map((_v, _idx) => (<li key={_idx}>{_v}</li>))}
                                </ul>
                            </>)}
                        </div>
                    </div>
                </div>);
        });
    };
    return (
        <section className={`animated fadeIn delay-0.5s temp-height ${isGuest ? "pt-5 p-3" : ""}`}>
            {utilities.urlSearchParams().get("source") !== "app" && <div>
                <a className="gen-text-color cp" onClick={handleBackButton}>
                    <svg width="2.5em" height="2.5em" viewBox="0 0 16 16" className="bi bi-arrow-left-short font-weight-bold" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                    </svg>
                    <span className="vam font-weight-bold">BACK</span>
                </a>
            </div>}

            <div className="web-space">
                
            </div>
            
            <div className="gen-text-color font-weight-bold mb-4 mt-2">
                <h1 className="mb-4 font-weight-bold">FAQ</h1>
            </div>

            <div id="mainAccordion">
                {showFAQ("main")}
            </div>
            
            <div className="gen-text-color font-weight-bold mb-4 mt-5">
                <h2 className="mb-4 font-weight-bold">Bank Accounts</h2>
            </div>

            <div id="bank_accountsAccordion">
                {showFAQ("bank_accounts")}
            </div>
            
            <div className="gen-text-color font-weight-bold mb-4 mt-5">
                <h2 className="mb-4 font-weight-bold">Bank Cards</h2>
            </div>

            <div id="bank_cardsAccordion">
                {showFAQ("bank_cards")}
            </div>
            
            <div className="gen-text-color font-weight-bold mb-4 mt-5">
                <h2 className="mb-4 font-weight-bold">Loans</h2>
            </div>

            <div id="loansAccordion">
                {showFAQ("loans")}
            </div>
            
            <div className="gen-text-color font-weight-bold mb-4 mt-5">
                <h2 className="mb-4 font-weight-bold">Structured Overdraft</h2>
            </div>

            <div id="structured_overdraftAccordion">
                {showFAQ("structured_overdraft")}
            </div>
            
            <div className="gen-text-color font-weight-bold mb-4 mt-5">
                <h2 className="mb-4 font-weight-bold">Unstructured Overdraft</h2>
            </div>

            <div id="unstructured_overdraftAccordion">
                {showFAQ("unstructured_overdraft")}
            </div>
            
            <div className="gen-text-color font-weight-bold mb-4 mt-5">
                <h2 className="mb-4 font-weight-bold">Point of Sale</h2>
            </div>

            <div id="point_of_saleAccordion">
                {showFAQ("point_of_sale")}
            </div>
            
            <div className="gen-text-color font-weight-bold mb-4 mt-5">
                <h2 className="mb-4 font-weight-bold">Channel Services</h2>
            </div>

            <div id="channel_servicesAccordion">
                {showFAQ("channel_services")}
            </div>
            
            <div className="gen-text-color font-weight-bold mb-4 mt-5">
                <h2 className="mb-4 font-weight-bold">Investing</h2>
            </div>

            <div id="investingAccordion">
                {showFAQ("investing")}
            </div>
        </section>
    )
};

export const SupportLiveAgent = ({ handleBackButton, showModal }) => {
    return (
        <section>
            <div className="auth-guy">
                <div>
                    <a className="gen-text-color cp" onClick={handleBackButton}>
                        <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-arrow-left-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                        </svg>
                        <span className="vam font-weight-bold">Back</span>
                    </a>
                </div>

                <div className="web-space">
                    <p className="padding2">&nbsp;</p>
                    <p className="padding2">&nbsp;</p>
                </div>
                
                <div className="gen-text-color font-weight-bold mb-5">
                    <h2 className="mb-4 font-weight-bold">Authentication</h2>
                    <p className="small font-weight-bold">Kindly input transaction pin to complete.</p>
                </div>

                <div id="code-form">
                    <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                    <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                    <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                    <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                </div>

                <button className="login-btn small" type="button" onClick={() => {showModal("transactionsuccessfulmodal")}}>
                    <span className="w-85 font-weight-bold ml-50">Continue</span>

                    <span className="w-15 h-100 slant-arrow">
                        <svg width="1.5em" height="100%" viewBox="0 0 16 16" className="bi bi-arrow-right-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path fillRule="evenodd" d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"/>
                        </svg>
                    </span>
                </button>

                <div className="text-danger text-center font-weight-bold mt-4">Cancel Transaction</div>
            </div>
        </section>
    )
};

export default SupportPage;

function SendMailTab({isGuest, switchTab, switchSubTab, showSuppotMails}) {
    //console.log("showSuppotMails: ", showSuppotMails);
    
    return <div className={`col-lg account-card p-4 cp ${isGuest ? "bvn-card" : ""}`} onClick={() => showSuppotMails("supportmailsmodal")} style={{ cursor: "pointer", marginRight: isGuest ? "1.5rem" : "" }}>

        <div className="box-part text-left">

            <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-envelope" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z" />
            </svg>

            <div className="title mt-2">
                <p className="font-weight-bold">Send an email</p>
            </div>

            <div className="text">
                <span className="small">Send us a descriptive Email</span>
            </div>

        </div>
    </div>;
};