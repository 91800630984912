import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

import NotFound from "./components/notfound";
import Login from "./components/login";
import SignUp from './components/signup';
import {Dashboard} from './components/dashboard';
import ForgotPassword from './components/forgotpassword';
import ResetPassword from './components/resetpassword';
import PrivacyPolicy from './components/privacyPolicy';
import TermsAndConditions from './components/terms';

class App extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
	        <Router>
				<Switch>
					<Route exact path={['/', '/support', '/support/:action', '/comingsoon', '/supportemail', '/supportfaq', '/supportbranch']} component={Login} />
					<Route path={['/signup', '/signup/:stage/:path', '/signup/:stage/:path/:livecheckparentpath/:livecheckparentstage']} component={SignUp} />
					<Route path='/forgotpassword' component={ForgotPassword} />
					<Route path='/resetpassword' component={ResetPassword} />
					<Route path='/privacy' component={PrivacyPolicy} />
					<Route path='/terms' component={TermsAndConditions} />
					<Route path={['/dashboard', '/dashboard/:activetab/', '/dashboard/:activetab/:activesubtab', '/dashboard/:activetab/:activesubtab/:activeinnertab']} component={Dashboard} />
					<Route path='' component={NotFound} />
				</Switch>
	        </Router>
	    )
	}
}

ReactDOM.render(<App />, document.getElementById('root'));