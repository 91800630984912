import React from 'react';
import {Link} from "react-router-dom";

class NotFound extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (
            <article className="animated fadeIn delay-0.5s pt-5">
        
                <section className="mx-auto w-75 pt-5">
                    <div className="w-100 text-center mt-5 mb-3 font-weight-bold pt-5">
                        Sorry, this page is not available.
                    </div>

                    <div className="text-center">
                    	The link you followed may have been broken, or the page may have been removed. <Link to="/">Go back to NPF Microfinance Bank.</Link>
                    </div>
                </section>

                {/* <footer className="footerel">
                    <div className="row">
                        <div className="col-lg-6 ">
                            <p className="row copyright mb-0 small">&copy;<script>document.write(new Date().getFullYear());</script> 
                                WealthTech Limited. All rights reserved <br /> 
                                "Wealth Registrax" is part of the Wealth.ng suit of applications.
                            </p>
                        </div>
                        <div className="col-lg-6 righty">
                            <div className="row mb-0">
                                <div className="ft">Terms of Service</div>
                                <div className="ft">Partnership & Intergration</div>
                                <div className="ft">Renew License</div>
                            </div>
                        </div>
                    </div>
                </footer> */}
            </article>
        );
    }

    componentDidMount() {
        /*let tokendata = store.get("userdata");

        if(tokendata) {
            this.props.history.push('/newprofile');
        }*/
    }
}

export default NotFound;