import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Utilities } from "./utilities";
import store from "store";
import HttpService from "../httpservice";
import NProgress from "nprogress";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

let utilities = new Utilities();
let httpservice = new HttpService();

let count = 0;
let recorder = {};
let callbackfunction = {};
let timeout;
let almightyblob;

function retrieveBlob(blob) {
  almightyblob = recorder.getBlob();

  callbackfunction.callback(almightyblob, blob);
}

class SignUp extends React.Component {
  constructor() {
    super();

    this.state = {
      ismobile: false,
      canproceed: true,
      captureallowed: false,
      ajaxcalled: false,
      ajaxerror: "",
      validate: false,
      showmodal: false,
      targetmodal: "",
      contactearningtype: "",

      signuppath: "",
      signupstage: "",
      livecheckparentstage: "",
      livecheckparentpath: "",

      bvn: "",
      dateofbirth: "",
      bvntype: "password",
      bvncode1: "",
      bvncode2: "",
      bvncode3: "",
      bvncode4: "",
      bvncode5: "",
      bvncode6: "",
      signature: "",
      photo: "",
      firstname: "",
      lastname: "",
      idtype: "",
      idnumber: "",
      sector: "",
      bankaccount: "",
      gender: "",
      docissuedate: "",
      docexpirydate: "",

      rank: "",
      securitynumber: "",
      genotype: "",
      bloodgroup: "",
      nhisnumber: "",

      kpassword: "",
      password: "",
      email: "",
      phone: "",
      salarytype: "",
      forcenumber: "",

      mainpasswordtype: "password",
      subpasswordtype: "password",

      imageheight: 0,
      customerphoto: "",
      customervideo: "",
      recordingstarted: false,

      listofdoctypes: [],
    };
  }

  toTitleCase = (text) => {
    if (text) {
      let value = text[0].toUpperCase() + text.substring(1).toLowerCase();

      return value;
    }
  };

  handleChange = (name, date) => {
    this.setState({
      [name]: date,
    });
  };

  handleInput = (e) => {
    //let value = this.toTitleCase(e.target.value);
    const { name, value } = e.target;
    this.setState({
      [name]: name?.includes?.("password") ? value.trim() : value,
    });

    let otp = document.querySelector("#code-form");

    if (otp) utilities.activateNextInput();
  };

  handleShowHide = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      bvntype: this.state.bvntype === "text" ? "password" : "text",
    });
  };

  handleFileUpload = (e) => {
    e.persist();

    let file = e.target.files[0];

    if (
      file &&
      (file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "application/pdf")
    ) {
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function () {
        this.setState({
          [e.target.name]: file,
          [e.target.name + "invalid"]: false,
          [e.target.name + "string"]: reader.result,
          [e.target.name + "filename"]: file.name,
        });
      }.bind(this);
    } else {
      this.setState({
        [e.target.name + "invalid"]: true,
      });
    }
  };

  showModal = (targetmodal) => {
    this.setState({ targetmodal: targetmodal, showmodal: true });
  };

  hideModal = () => {
    this.setState({ showmodal: false });
  };

  activateNextInput = () => {
    let otp = document.querySelector("#code-form");

    for (let pin of otp.children) {
      pin.onkeyup = function () {
        if (pin.nextElementSibling) {
          pin.nextElementSibling.focus();
        }
      };
    }
  };

  saveCustomerLivelinessCheckPhoto = (id) => {
    this.setState({ ajaxcalled: true });
    let formdata = new FormData();

    let photo = utilities.dataURLtoFile(store.get("customerphoto"));

    formdata.append("photo", photo);
    formdata.append("photo_type", "Liveliness check image");
    formdata.append("photo_number", 1);
    formdata.append("customer_id", id);

    httpservice
      .authPost("signup/create_photo", formdata, true)
      .then((response) => {
        this.setState({ ajaxcalled: false });

        if (!response.error) {
          this.saveCustomerLivelinessCheckVideo(id);
        }
      })
      .catch((error) => {
        this.setState({ ajaxcalled: false });
        console.log("error: ", error);
      });
  };

  saveCustomerLivelinessCheckVideo = (id) => {
    this.setState({ ajaxcalled: true });
    let formdata = new FormData();

    formdata.append("video", this.state.customervideo);
    formdata.append("customer_id", id);

    httpservice
      .authPost("signup/create_video", formdata, true)
      .then((response) => {
        this.setState({ ajaxcalled: false });

        if (!response.error) {
          this.completeSignup();
        }
      })
      .catch((error) => {
        this.setState({ ajaxcalled: false });
        console.log("error: ", error);
      });
  };

  setSignUpStage = (stage, path, livecheckparentpath = "") => {
    if (this.state.canproceed) {
      path = path ? path : this.state.signuppath;

      this.setState({ signupstage: stage, signuppath: path, ajaxerror: "" });

      store.set("signupstage", { path: path, stage: stage });

      //livecheckparentpath ? window.location.pathname = "/signup/" + stage + "/" + path + "/" + livecheckparentpath + "/" + livecheckparentstage : window.location.pathname = "/signup/" + stage + "/" + path;

      livecheckparentpath === ""
        ? this.props.history.push("/signup/" + stage + "/" + path)
        : this.props.history.push(
            "/signup/" +
              stage +
              "/" +
              path +
              "/" +
              store.get("livecheckparentpath") +
              "/" +
              store.get("livecheckparentstage")
          );
    }
  };

  completeSignup = () => {
    this.setState({ ajaxcalled: true });

    httpservice
      .authPost("signup/complete_signup", {})
      .then((response) => {
        this.setState({ ajaxcalled: false });

        if (!response.error) {
          this.goToDashboard();
        } else {
          utilities.Notify(response.message, "error");
        }
      })
      .catch((error) => {
        this.setState({ ajaxcalled: false });
        console.log("error: ", error);
      });
  };

  goToDashboard = () => {
    store.remove("customer");
    store.remove("personaldetails");
    store.remove("contactdetails");
    store.remove("signupstage");
    store.remove("liveimage");
    store.remove("livevideo");
    store.remove("bvn");
    store.remove("dateofbirth");
    store.remove("customerphoto");

    window.location.pathname = "dashboard/Dashboard";

    this.props.history.push("/dashboard/Dashboard");
  };

  handleBackButton = () => {
    let stage = this.state.signupstage;
    this.setState({ validate: false });

    switch (stage) {
      case "image-check":
        let correctstage = "four";
        let correctpath = store.get("livecheckparentpath");

        this.setSignUpStage(
          correctstage,
          correctpath,
          store.get("livecheckparentpath")
        ); //would face modification
        store.set("signupstage", { path: correctpath, stage: correctstage });
        break;
      case "video-check":
        this.setSignUpStage(
          "image-check",
          "LivelinessCheckImage",
          store.get("signupstage").livecheckparentpath
        );
        store.set("signupstage", {
          path: "LivelinessCheckImage",
          stage: "image-check",
          livecheckparentpath: store.get("livecheckparentpath"),
        });

        setTimeout(() => {
          utilities.startLivelinessCheckImage(this);
        }, 2000);

        break;
      case "final":
        this.setSignUpStage(
          "video-check",
          "LivelinessCheckVideo",
          store.get("livecheckparentpath")
        );
        store.set("signupstage", {
          path: "LivelinessCheckVideo",
          stage: "video-check",
          livecheckparentpath: store.get("livecheckparentpath"),
        });

        break;
      case "four":
        if (store.get("personaldetails")) {
          this.persistPersonalDetails();
        }

        if (store.get("contactdetails")) {
          this.persistContactDetails();
        }

        this.setSignUpStage("three");
        store.set("signupstage", {
          path: this.state.signuppath,
          stage: "three",
        });
        break;
      case "three":
        if (store.get("personaldetails")) {
          this.persistPersonalDetails();
        }

        if (store.get("contactdetails")) {
          this.persistContactDetails();
        }

        this.setSignUpStage("two");
        store.set("signupstage", { path: this.state.signuppath, stage: "two" });
        break;
      case "two":
        this.setSignUpStage("one");
        store.set("signupstage", { path: this.state.signuppath, stage: "one" });
        break;
      case "one":
        this.setState({ signupstage: "", signuppath: "" });
        this.props.history.push("/signup");

        store.clearAll();
        break;
      default:
        this.props.history.push("/");
        store.clearAll();
        break;
    }
  };

  togglePasswordEye = (caller) => {
    caller === "main"
      ? this.setState({
          mainpasswordtype:
            this.state.mainpasswordtype === "password" ? "text" : "password",
        })
      : this.setState({
          subpasswordtype:
            this.state.subpasswordtype === "password" ? "text" : "password",
        });
  };

  persistContactDetails = () => {
    this.setState({
      email: store.get("contactdetails").email,
      phone: store.get("contactdetails").phone,
      password: this.state.password,
      salarytype: this.state.salarytype,
      ippisnumber: this.state.ippisnumber,
      forcenumber: this.state.forcenumber,
      rank: this.state.rank,
      securitynumber: this.state.securitynumber,
      nhisnumber: this.state.nhisnumber,
    });
  };

  persistPersonalDetails = () => {
    this.setState({
      dateofbirth: new Date(store.get("dateofbirth"))
        .toISOString()
        .split("T")[0],
      firstname: utilities.toTitleCase(store.get("personaldetails").firstname),
      lastname: utilities.toTitleCase(store.get("personaldetails").lastname),
      phone: store.get("customer").phone,
      idnumber: store.get("personaldetails").idnumber,
      idtype: store.get("personaldetails").idtype,
      //signature: store.get("personaldetails").signature,
      //photo: store.get("personaldetails").photo,
      docissuedate: store.get("personaldetails").document_issue_date,
      docexpirydate: store.get("personaldetails").document_expiry_date,
      photostring: store.get("personaldetails").photostring,
      signaturestring: store.get("personaldetails").signaturestring,
      sector: store.get("personaldetails").sector_id,
      gender: store.get("personaldetails").gender,
      genotype: store.get("personaldetails").genotype,
      bloodgroup: store.get("personaldetails").bloodgroup,
    });
  };

  persistBvnData = () => {
    this.setState({
      firstname: this.toTitleCase(store.get("customer").firstname),
      lastname: this.toTitleCase(store.get("customer").lastname),
      phone: store.get("customer").phone,
      dateofbirth: new Date(store.get("dateofbirth"))
        .toISOString()
        .split("T")[0],
    });
  };

  resendCode = () => {
    let bvn = store.get("bvn");
    let date_of_birth = store.get("dateofbirth");

    let payload, url;

    if (this.state.signuppath === "bvn") {
      url = "signup/fetch_by_BVN";
      payload = { bvn: bvn, date_of_birth: date_of_birth };
    } else if (this.state.signuppath === "bankaccount") {
      url = "signup/fetch_by_bank_account";
      payload = { account: store.get("bankaccount") };
    } else {
      url = "signup/fetch_by_phone";
      payload = {
        phone: store.get("phone"),
        date_of_birth: store.get("dateofbirth"),
      };
    }

    NProgress.inc();

    if (url && payload) {
      httpservice
        .unAuthPost(url, payload)
        .then((response) => {
          NProgress.done();

          switch (response.error) {
            case true:
              utilities.Notify(response.message, "error");
              break;
            case false:
              utilities.Notify("Code sent.", "success");
              this.setState({ ajaxerror: "" });
              break;
            default:
              utilities.Notify("Service unavailable", "error");
              break;
          }
        })
        .catch((error) => {
          NProgress.done();
          console.log("error: ", error);
        });
    }
  };

  startBvnVerification = (nextstage, nextpath) => {
    let thisyear = new Date().getFullYear();

    if (
      !this.state.bvn ||
      !this.state.dateofbirth ||
      isNaN(this.state.bvn) ||
      this.state.bvn.length !== 11 ||
      new Date(this.state.dateofbirth).getFullYear() + 18 > thisyear
    ) {
      this.setState({ validate: true });
    } else {
      this.setState({ validate: false, ajaxcalled: true });
      let date = moment(this.state.dateofbirth).format().split("T")[0];

      let data = { bvn: this.state.bvn, date_of_birth: date };

      httpservice
        .unAuthPost("signup/fetch_by_BVN", data)
        .then((response) => {
          this.setState({ ajaxcalled: false });
          NProgress.done();

          switch (response.error) {
            case true:
              this.setState({ ajaxerror: response.message || response.result });
              break;
            case false:
              store.set("bvn", this.state.bvn);
              store.set("dateofbirth", date);

              //this.setState({dateofbirth: date, ajaxerror: ""});

              nextpath = nextpath ? nextpath : this.state.signuppath;
              this.setSignUpStage(nextstage, nextpath);

              break;
            default:
              this.setState({ ajaxerror: "Service unavailable." });
              break;
          }
        })
        .catch((error) => {
          this.setState({ ajaxcalled: false });
          console.log("error: ", error);
        });
    }
  };

  startBankAccountVerification = (nextstage, nextpath) => {
    let thisyear = new Date().getFullYear();

    if (
      !this.state.bankaccount ||
      !this.state.dateofbirth ||
      new Date(this.state.dateofbirth).getFullYear() + 18 > thisyear
    ) {
      this.setState({ validate: true });
    } else {
      store.set(
        "dateofbirth",
        moment(this.state.dateofbirth).format().split("T")[0]
      );
      this.setState({ validate: false, ajaxcalled: true });

      store.set("bankaccount", this.state.bankaccount);

      //, date_of_birth: this.state.dateofbirth

      httpservice
        .unAuthPost("signup/fetch_by_bank_account", {
          account: this.state.bankaccount,
        })
        .then((response) => {
          this.setState({ ajaxcalled: false });

          switch (response.error) {
            case true:
              this.setState({ ajaxerror: response.message || response.result });
              break;
            case false:
              if (response.data) {
                store.set(
                  "dateofbirth",
                  moment(this.state.dateofbirth).format().split("T")[0]
                );
                store.set("phone", response.data.phone);

                this.setState({
                  //dateofbirth: moment(this.state.dateofbirth).format().split("T")[0],
                  //phone: response.data.phone,
                  firstname: response.data.firstname,
                  lastname: response.data.lastname,
                });
              }

              nextpath = nextpath ? nextpath : this.state.signuppath;
              this.setSignUpStage(nextstage, nextpath);

              break;
            default:
              this.setState({ ajaxerror: "Service unavailable." });
              break;
          }
        })
        .catch((error) => {
          this.setState({ ajaxcalled: false });
          console.log("error: ", error);
        });
    }
  };

  startPhoneVerification = (nextstage, nextpath) => {
    let thisyear = new Date().getFullYear();

    if (
      !this.state.phone ||
      this.state.phone.length < 11 ||
      this.state.phone.length > 14 ||
      !this.state.dateofbirth ||
      new Date(this.state.dateofbirth).getFullYear() + 18 > thisyear
    ) {
      this.setState({ validate: true });
    } else {
      this.setState({ validate: false, ajaxcalled: true });

      let phone = utilities.packagePhoneNumber(
        this.state.phone.replace(/\s+/g, "")
      );
      let date = moment(this.state.dateofbirth).format().split("T")[0];
      //.toISOString().split("T")[0]

      httpservice
        .unAuthPost("signup/fetch_by_phone", {
          phone: phone,
          date_of_birth: date,
        })
        .then((response) => {
          this.setState({ ajaxcalled: false, ajaxerror: "" });

          switch (response.error) {
            case true:
              this.setState({ ajaxerror: response.message || response.result });
              break;
            case false:
              store.set("phone", phone);
              store.set("dateofbirth", date);

              //this.setState({phone: phone, dateofbirth: date });

              nextpath = nextpath ? nextpath : this.state.signuppath;
              this.setSignUpStage(nextstage, nextpath);

              break;
            default:
              this.setState({ ajaxerror: "Service unavailable." });
              break;
          }
        })
        .catch((error) => {
          this.setState({ ajaxcalled: false });
          console.log("error: ", error);
        });
    }
  };

  verifyPhone = (nextstage, nextpath) => {
    let bvncode =
      this.state.bvncode1 +
      "" +
      this.state.bvncode2 +
      "" +
      this.state.bvncode3 +
      "" +
      this.state.bvncode4 +
      "" +
      this.state.bvncode5 +
      "" +
      this.state.bvncode6;

    if (!bvncode || bvncode.length !== 6) {
      this.setState({ validate: true });
    } else {
      this.setState({ validate: false, ajaxcalled: true, ajaxerror: "" });

      let url, payload;

      if (this.state.signuppath === "bvn") {
        url = "signup/verify_phone_by_bvn";
        payload = { code: bvncode, bvn: store.get("bvn") };
      } else if (this.state.signuppath === "bankaccount") {
        url = "signup/verify_phone_by_account";
        payload = { code: bvncode, account: store.get("bankaccount") };
      } else {
        url = "signup/verify_phone";
        payload = { code: bvncode, phone: store.get("phone") };
      }

      httpservice
        .unAuthPost(url, payload)
        .then((response) => {
          this.setState({ ajaxcalled: false });

          if (response.temp_token) {
            this.showModal("bvnverifiedmodal");

            //this.state.signuppath !== "newaccount" && store.set("customer", response.data);

            store.set("token", response.temp_token);
            store.set("customer", response.data);

            setTimeout(() => {
              this.hideModal();

              this.setState({
                firstname: this.toTitleCase(response.data.firstname),
                lastname: this.toTitleCase(response.data.lastname),
                phone: response.data.phone,
                dateofbirth: moment(this.state.dateofbirth)
                  .format()
                  .split("T")[0],
                ajaxerror: "",
              });

              nextpath = nextpath ? nextpath : this.state.signuppath;
              this.setSignUpStage(nextstage, nextpath);
            }, 2500);
          } else {
            switch (response.error) {
              case response.error:
                this.setState({
                  ajaxerror: response.message || response.result,
                });
                break;
              default:
                this.setState({ ajaxerror: "Service unavailable." });
                break;
            }
          }
        })
        .catch((error) => {
          this.setState({ ajaxcalled: false });
          console.log("error: ", error);
        });
    }
  };

  handleDocUpload = (id) => {
    let formdata = new FormData();
    let photo = utilities.dataURLtoFile(
      store.get("personaldetails").photostring,
      "IdentityDoc.png"
    );

    formdata.append("document", photo);
    formdata.append("document_number", store.get("personaldetails").idnumber);
    formdata.append("document_type_id", store.get("personaldetails").idtype);
    formdata.append(
      "document_issue_date",
      store.get("personaldetails").document_issue_date
    );
    formdata.append(
      "document_expiry_date",
      store.get("personaldetails").document_expiry_date
    );
    formdata.append("customer_id", id);

    this.setState({ ajaxcalled: true });

    httpservice
      .authPost("signup/create_document", formdata, true)
      .then((response) => {
        switch (response.error) {
          case true:
            this.setState({ ajaxcalled: false });
            utilities.Notify(
              "Unable to process your request. Please try again later.",
              "error"
            );
            break;
          case false:
            let formdata2 = new FormData();

            let signature = utilities.dataURLtoFile(
              store.get("personaldetails").signaturestring,
              "signature.png"
            );

            formdata2.append("signature", signature);
            formdata2.append("customer_id", id);

            httpservice
              .authPost("signup/create_signature", formdata2, true)
              .then((response) => {
                switch (response.error) {
                  case true:
                    this.setState({ ajaxcalled: false });
                    utilities.Notify(
                      "Unable to process your request. Please try again later.",
                      "error"
                    );
                    break;
                  case false:
                    this.setState({ ajaxcalled: false });

                    this.saveCustomerLivelinessCheckPhoto(id);
                    break;
                  default:
                    this.setState({ ajaxcalled: false });
                    utilities.Notify(
                      "Unable to process your request. Please try again later.",
                      "error"
                    );
                    break;
                }
              })
              .catch((error) => {
                this.setState({ ajaxcalled: false });
                console.log("error: ", error);
              });
            break;
          default:
            this.setState({ ajaxcalled: false });
            utilities.Notify(
              "Unable to process your request. Please try again later.",
              "error"
            );
            break;
        }
      })
      .catch((error) => {
        this.setState({ ajaxcalled: false });
        console.log("error: ", error);
      });
  };

  fetchUser = () => {
    httpservice
      .authGet("auth/check")
      .then((response) => {
        if (!response.error) {
          //if(!response.data.has_pin) store.set("tabs", {activetab: "Settings", activesubtab: "SetUpPin"});
          store.set("user", response.data);

          this.handleDocUpload(response.data.id);
        } else {
          utilities.Notify(
            "Unable to sign in. Please try again later.",
            "error"
          );
          this.setState({ ajaxerror: response.result, ajaxcalled: false });
        }
      })
      .catch((error) => {
        this.setState({ ajaxcalled: false });
        console.log("error: ", error);
      });
  };

  handleSignIn = () => {
    NProgress.inc();

    let phone = utilities.packagePhoneNumber(store.get("contactdetails").phone);
    this.setState({ ajaxcalled: true });

    httpservice
      .unAuthPost("auth/sign_in", {
        phone: store.get("contactdetails").phone,
        password: store.get("contactdetails").password,
      })
      .then((response) => {
        NProgress.done();

        if (!response.error) {
          store.set("token", response.token);
          store.remove("signupstage");

          this.fetchUser();
        } else {
          utilities.Notify(
            "Unable to sign in. Please try again later.",
            "error"
          );
          this.setState({ ajaxerror: response.result, ajaxcalled: false });
        }
      })
      .catch((error) => {
        NProgress.done();

        this.setState({ ajaxcalled: false });
        console.log("error: ", error);
      });
  };

  toFinalStep = () => {
    this.setState({ signuppath: "", signupstage: "final" });

    this.props.history.push("/signup/final");
  };

  handlePersonalDetailsForm = () => {
    if (
      !this.state.firstname ||
      !this.state.lastname ||
      !this.state.photo ||
      !this.state.signature ||
      !this.state.docexpirydate ||
      !this.state.docissuedate ||
      !this.state.idtype ||
      !this.state.sector ||
      !this.state.idnumber ||
      this.state.idnumber.length < 5 ||
      this.state.idnumber.length > 20 ||
      !utilities.isAlphabetic(this.state.firstname) ||
      !utilities.isAlphabetic(this.state.lastname) ||
      !this.state.dateofbirth ||
      !this.state.gender ||
      !this.state.genotype ||
      !this.state.bloodgroup
    ) {
      this.setState({ validate: true });
    } else {
      this.setState({ validate: false });

      let data = {
        firstname: utilities.toTitleCase(this.state.firstname),
        lastname: utilities.toTitleCase(this.state.lastname),
        idnumber: this.state.idnumber,
        idtype: this.state.idtype,
        signature: this.state.signature,
        photo_id: this.state.photo,
        document_issue_date: this.state.docissuedate,
        document_expiry_date: this.state.docexpirydate,
        photostring: this.state.photostring,
        signaturestring: this.state.signaturestring,
        sector_id: this.state.sector,
        gender: this.state.gender,
        dateofbirth: moment(this.state.dateofbirth).format().split("T")[0],
        blood_group: this.state.bloodgroup,
        genotype: this.state.genotype,
      };

      store.set("personaldetails", data);

      store.set("livecheckparentpath", this.state.signuppath);
      store.set("livecheckparentstage", "four");

      this.setSignUpStage("four");
    }
  };

  handleContactDetailsForm = () => {
    if (
      !this.state.email ||
      !utilities.validmail(this.state.email) ||
      !this.state.phone ||
      this.state.phone.length < 11 ||
      this.state.phone.length > 14 ||
      !this.state.password ||
      !utilities.validPassword(this.state.password) ||
      !this.state.kpassword ||
      this.state.kpassword.toString() !== this.state.password.toString() ||
      !this.state.salarytype ||
      (this.state.salarytype === "true" && !this.state.ippisnumber) ||
      (this.state.salarytype === "true" && !this.state.forcenumber) ||
      (this.state.salarytype === "true" &&
        this.state.ippisnumber.length > 15) ||
      (this.state.salarytype === "true" &&
        this.state.forcenumber.length > 15) ||
      (this.state.salarytype === "true" && this.state.ippisnumber.length < 5) ||
      (this.state.salarytype === "true" && this.state.forcenumber.length < 6) ||
      (this.state.salarytype === "true" && isNaN(this.state.forcenumber)) ||
      (this.state.salarytype === "true" && !this.state.rank) ||
      (this.state.salarytype === "true" && !this.state.securitynumber) ||
      (this.state.salarytype === "true" && !this.state.nhisnumber) ||
      (this.state.salarytype === "true" && this.state.rank.length < 10) ||
      (this.state.salarytype === "true" &&
        this.state.securitynumber.length < 6) ||
      (this.state.salarytype === "true" && this.state.nhisnumber.length < 6)
    ) {
      this.setState({ validate: true });
    } else {
      this.setState({ validate: false, ajaxcalled: true });

      let data;
      let url;
      let phone = utilities.packagePhoneNumber(
        this.state.phone.replace(/\s+/g, "")
      );
      let type;

      data = {
        firstname: store.get("personaldetails").firstname,
        lastname: store.get("personaldetails").lastname,
        middlename: store.get("personaldetails").middlename || "N/A",
        gender: store.get("personaldetails").gender || "N/A",
        address: store.get("personaldetails").address || "N/A",
        email: this.state.email,
        phone: phone,
        date_of_birth: this.state.dateofbirth
          ? moment(this.state.dateofbirth).format().split("T")[0]
          : moment(store.get("dateofbirth")).format().split("T")[0],
        password: this.state.password,
        sector_id: store.get("personaldetails").sector_id,
        salary_officer: this.state.salarytype,
        force_number:
          this.state.salarytype === "true" ? this.state.forcenumber : "N/A",
        ippis_number:
          this.state.salarytype === "true" ? this.state.ippisnumber : "N/A",
        rank: this.state.salarytype === "true" ? this.state.rank : "N/A",
        security_number:
          this.state.salarytype === "true" ? this.state.securitynumber : "N/A",
        nhis_number:
          this.state.salarytype === "true" ? this.state.nhisnumber : "N/A",
      };

      url = "signup/update_account";
      type = "authPost";

      store.set("contactdetails", data);

      httpservice[type](url, data)
        .then((response) => {
          this.setState({ ajaxcalled: false });

          switch (response.error) {
            case true:
              utilities.Notify(response.message, "error");
              break;
            case false:
              this.setSignUpStage(
                "image-check",
                "LivelinessCheckImage",
                store.get("livecheckparentpath")
              );

              store.set("signupstage", {
                stage: "image-check",
                path: "LivelinessCheckImage",
              });

              window.navigator.mediaDevices
                .getUserMedia({
                  video: true,
                  audio: true,
                })
                // on success, stream it in video tag
                .then(
                  function (stream) {
                    this.setState({ captureallowed: true });
                    store.set("captureallowed", true);

                    console.log("check:");

                    Utilities.startLivelinessCheckImage(this);
                  }.bind(this)
                )
                .catch(
                  function (err) {
                    this.setState({ captureallowed: false });
                    store.set("captureallowed", false);

                    console.log("An error occurred: " + err);
                  }.bind(this)
                );

              break;
            default:
              if (response.status === 401) {
                store.clearAll();
                this.setSignUpStage("", "");
              }

              utilities.Notify(
                "Service unavailable. Try again later.",
                "error"
              );
              this.setState({
                ajaxerror: response.statusText || "Service unavailable.",
              });
              break;
          }
        })
        .catch((error) => {
          this.setState({ ajaxcalled: false });
          console.log("error: ", error);
        });
    }
  };

  handleRecordingStop = () => {
    if ("speechSynthesis" in window) {
      setTimeout(() => {
        recorder.stopRecording(retrieveBlob);
      }, 100);
    } else {
      recorder.stopRecording(retrieveBlob);
    }
  };

  stopRecordingCallback = (almightyblob, actualblob) => {
    this.setState({ recordingstarted: false });

    var video = document.getElementById("live-video");

    video.src = video.srcObject = null;
    video.muted = false;
    video.volume = 1;

    video.src = actualblob; //URL.createObjectURL(this.getBlob());

    var file = new File([almightyblob], "livevideo.mp4", { type: "video/mp4" });

    this.setState({ customervideo: file });

    store.set("livevideo", file);

    recorder.camera.stop();
    recorder.destroy();
    recorder = null;

    count = 0;
  };

  captureCamera = (callback) => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(function (camera) {
        callback(camera);
      })
      .catch(function (error) {
        //alert('Unable to proceed. Check that your camera is enabled.');
        console.error(error);
      });
  };

  waitOneSec = (messages) => {
    if (count === 3) {
      clearTimeout(timeout);

      if (recorder) this.handleRecordingStop();
    }

    if (messages && messages.length && this.state.recordingstarted) {
      let msg = new SpeechSynthesisUtterance();

      msg.text = messages[count];

      window.speechSynthesis.speak(msg);

      msg.onend = function () {
        timeout = setTimeout(() => {
          if (count !== 3) {
            this.waitOneSec(Utilities.messages);
          }

          if (count === 3) {
            msg.volume = 1;
            clearTimeout(timeout);

            if (recorder) this.handleRecordingStop();
          }
        }, 50);

        count++;
      }.bind(this);
    }
  };

  startLivelinessCheckVideo = () => {
    var video = document.getElementById("live-video");

    this.setState({ recordingstarted: true, captureallowed: true });
    callbackfunction.callback = this.stopRecordingCallback;

    this.captureCamera(
      function (camera) {
        video.muted = true;
        video.volume = 0;
        video.srcObject = camera;

        recorder = window.RecordRTC(camera, {
          type: "video",
          mimeType: "video/mp4",
        });

        recorder.camera = camera;

        video.oncanplay = function () {
          if ("speechSynthesis" in window && !Utilities.mobileCheck()) {
            setTimeout(() => {
              this.waitOneSec(Utilities.messages);
            });
          } else {
            setTimeout(() => {
              this.handleRecordingStop();
            }, 7000);
          }

          if (recorder) {
            recorder.startRecording();

            recorder.camera = camera;
          }
        }.bind(this);
      }.bind(this)
    );
  };

  SignUpMenu = () => {
    return (
      <div className="animated fadeIn delay-0.5s">
        {this.state.canproceed ? (
          <div className="gen-text-color font-weight-bold mt-4 mb-5 font-weight-bold">
            <h1 className="font-weight-bold">Create Account</h1>
            <p className="mb-1">
              Kindly select the appropriate option below toget started with
              creating your account.
            </p>
          </div>
        ) : (
          <div className="gen-text-color font-weight-bold mt-4 mb-5 font-weight-bold">
            <p className="mb-1 text-danger">
              Your browser does not support image/video capture. Please switch
              to chrome or firefox to proceed.
            </p>
          </div>
        )}

        <div className="success-color mb-4">
          <p className="mb-1 pb-2 small font-weight-bold">Sign up with BVN</p>
          <hr className="h-rule" />
        </div>

        <div className="row mb-4 signup-cards">
          {/* () => {this.setSignUpStage("one", "bvn")} */}

          <div
            className="col-lg-5 account-card p-4 bvn-card cp"
            onClick={() => {
              this.setSignUpStage("one", "bvn");
            }}
          >
            <div className="box-part text-left">
              <svg
                width="2.3em"
                height="2.3em"
                viewBox="0 0 16 16"
                className="bi bi-check2-circle"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"
                />
                <path
                  fillRule="evenodd"
                  d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"
                />
              </svg>

              <div className="title mt-2">
                <p className="font-weight-bold">BVN</p>
              </div>

              <div className="text mb-4">
                <span className="small">
                  Use your BVN to create and validate your new account.
                </span>
              </div>

              <div className="w-100 text-right">
                <a>
                  <svg
                    width="2.3em"
                    height="2.3em"
                    viewBox="0 0 16 16"
                    className="success-color bi bi-arrow-right-circle"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>

          <div
            className="col-lg-5 account-card p-4 cp"
            onClick={() => {
              this.setSignUpStage("one", "bankaccount");
            }}
          >
            {/* this.setSignUpStage("one", "bankaccount")} */}

            <div className="box-part text-left">
              <img
                alt="Account Number Icon"
                src="/assets/imgs/acct-no.png"
                className="w-18"
              />

              <div className="title mt-2">
                <p className="font-weight-bold">Account Number</p>
              </div>

              <div className="text mb-4">
                <span className="small">
                  Use your NPF MFB account number to signup.
                </span>
              </div>

              <div className="w-100 text-right">
                <a>
                  <svg
                    width="2.3em"
                    height="2.3em"
                    viewBox="0 0 16 16"
                    className="success-color bi bi-arrow-right-circle"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="success-color mb-4">
          <p className="mb-1 pb-2 small font-weight-bold">
            Create a new account
          </p>
          <hr className="h-rule" />
        </div>

        <div className="row signup-cards">
          <div
            className="col-lg-5 account-card p-4 cp"
            onClick={() => {
              this.setSignUpStage("one", "newaccount");
            }}
          >
            {/* this.setSignUpStage("one", "newaccount")*/}

            <div className="box-part text-left">
              <svg
                width="2.3em"
                height="2.3em"
                viewBox="0 0 16 16"
                className="bi bi-plus-circle"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                />
                <path
                  fillRule="evenodd"
                  d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                />
              </svg>

              <div className="title mt-2">
                <p className="font-weight-bold">Create Account</p>
              </div>

              <div className="text mb-4">
                <span className="small">
                  Don't have a BVN or NPF MFB Bank Account?
                </span>
              </div>

              <div className="w-100 text-right">
                <a>
                  <svg
                    width="2.3em"
                    height="2.3em"
                    viewBox="0 0 16 16"
                    className="success-color bi bi-arrow-right-circle"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  SignUpViaBankAccount = () => {
    return (
      <div className="mx-auto form-guy animated fadeIn delay-0.5s">
        <div className="web-space">
          <p className="padding2">&nbsp;</p>
          <p className="padding2">&nbsp;</p>
        </div>

        <div className="gen-text-color font-weight-bold mb-5">
          <h2 className="mb-4 font-weight-bold">Bank Account</h2>
          <p className="small font-weight-bold">
            Input your account number and date of birth to proceed.
          </p>
        </div>

        {/* <div className="form-group" >
                    <label htmlFor="" className="small darkcolor mb-1 font-weight-bold">Select your bank</label>
                    <input type="password" className="form-control" data-validation="length" data-validation-length="min8" />
                </div> */}

        <div className="form-group">
          <label htmlFor="" className="small darkcolor mb-1 font-weight-bold">
            Account number
          </label>
          <input
            placeholder="Account number"
            maxLength="10"
            type="number"
            className="form-control"
            value={this.state.bankaccount}
            name="bankaccount"
            onChange={this.handleInput}
          />
          <div className="text-danger small">
            {!this.state.bankaccount && this.state.validate && "Required"}
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="" className="small darkcolor mb-1 font-weight-bold">
            Date of birth
          </label>
          {/* <input 
                        type="date" 
                        name="dateofbirth" 
                        value={this.state.dateofbirth} 
                        onChange={this.handleInput} 
                        className="dateofbirth form-control" 
                    /> */}

          <DatePicker
            selected={this.state.dateofbirth}
            value={this.state.dateofbirth}
            onChange={(date) => this.handleChange("dateofbirth", date)}
            name="dateofbirth"
            dateFormat="dd/MM/yyyy"
            placeholderText="DD/MM/YYYY"
            className="dateofbirth form-control w-100"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            locale="en-GB"
          />

          <div className="text-danger small">
            {!this.state.dateofbirth && this.state.validate && "Required"}
          </div>
          <div className="text-danger small">
            {new Date(this.state.dateofbirth).getFullYear() + 18 >
              new Date().getFullYear() &&
              this.state.validate &&
              "You must be over 18 to sign up."}
          </div>
        </div>

        <div className="mb-4">
          By clicking Continue, you agree to our
          <Link to="/privacy"> privacy policy</Link> and{" "}
          <Link to="/terms"> terms & conditions.</Link>
        </div>

        <button
          disabled={this.state.ajaxcalled}
          className="login-btn small"
          type="button"
          onClick={() => {
            this.startBankAccountVerification("two");
          }}
        >
          <span className="w-85 font-weight-bold ml-50">Continue</span>

          <span className="w-15 h-100 slant-arrow">
            {this.state.ajaxcalled ? (
              <utilities.Gear style={{ width: "26px", marginTop: "9px" }} />
            ) : (
              <svg
                width="1.5em"
                height="100%"
                viewBox="0 0 16 16"
                className="bi bi-arrow-right-circle"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                />
                <path
                  fillRule="evenodd"
                  d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"
                />
              </svg>
            )}
          </span>
        </button>

        <div className="text-danger small text-center mt-4">
          {this.state.signuppath === "bankaccount" &&
            this.state.signupstage === "one" &&
            !this.state.ajaxcalled &&
            this.state.ajaxerror &&
            this.state.ajaxerror}
        </div>
      </div>
    );
  };

  SignUpViaBVN = () => {
    return (
      <div className="mx-auto form-guy animated fadeIn delay-0.5s">
        <div className="web-space">
          <p className="padding2">&nbsp;</p>
          <p className="padding2">&nbsp;</p>
        </div>

        <div className="gen-text-color font-weight-bold mb-5">
          <h2 className="mb-4 font-weight-bold">BVN</h2>
          <p className="small font-weight-bold">
            Input your 11 digit BVN to proceed.
          </p>
        </div>

        <div className="form-group mb-0">
          <label htmlFor="" className="small darkcolor mb-1 font-weight-bold">
            BVN
          </label>
          <input
            placeholder="11 digit BVN"
            type={this.state.bvntype}
            value={this.state.bvn}
            name="bvn"
            onChange={this.handleInput}
            className="form-control"
            maxLength="11"
          />

          {this.state.bvntype === "password" ? (
            <span className="eye-icon cp" onClick={this.handleShowHide}>
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                className="bi bi-eye-fill"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                <path
                  fillRule="evenodd"
                  d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
                />
              </svg>
            </span>
          ) : (
            <span className="eye-icon cp" onClick={this.handleShowHide}>
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                className="bi bi-eye-slash-fill"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.79 12.912l-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708l-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829z" />
                <path
                  fillRule="evenodd"
                  d="M13.646 14.354l-12-12 .708-.708 12 12-.708.708z"
                />
              </svg>
            </span>
          )}

          <div className="text-danger small mt-n4 mb-4">
            {!this.state.bvn && this.state.validate && "Required"}
          </div>
          <div className="text-danger small mt-n4 mb-4">
            {this.state.bvn &&
              this.state.bvn.length !== 11 &&
              this.state.validate &&
              "Input must be 11 digits."}
          </div>
          <div className="text-danger small mt-n4 mb-4">
            {isNaN(this.state.bvn) &&
              this.state.validate &&
              "Input must consist of only digits."}
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="" className="small darkcolor mb-1 font-weight-bold">
            Date of birth
          </label>

          {/* <input 
                        type="date" 
                        name="dateofbirth" 
                        value={this.state.dateofbirth} 
                        onChange={this.handleInput} 
                        className="dateofbirth form-control" 
                    /> */}

          <DatePicker
            selected={this.state.dateofbirth}
            value={this.state.dateofbirth}
            onChange={(date) => this.handleChange("dateofbirth", date)}
            name="dateofbirth"
            dateFormat="dd/MM/yyyy"
            placeholderText="DD/MM/YYYY"
            className="dateofbirth form-control w-100"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            locale="en-GB"
          />

          <div className="text-danger small">
            {!this.state.dateofbirth && this.state.validate && "Required"}
          </div>
          <div className="text-danger small">
            {new Date(this.state.dateofbirth).getFullYear() + 18 >
              new Date().getFullYear() &&
              this.state.validate &&
              "You must be over 18 to sign up."}
          </div>
        </div>

        <div className="mb-4">
          By clicking Continue, you agree to our
          <Link to="/privacy"> privacy policy</Link> and{" "}
          <Link to="/terms"> terms & conditions.</Link>
        </div>

        <button
          disabled={this.state.ajaxcalled}
          className="login-btn small"
          type="button"
          onClick={() => {
            this.startBvnVerification("two");
          }}
        >
          <span className="w-85 font-weight-bold ml-50">Continue</span>

          <span className="w-15 h-100 slant-arrow">
            {this.state.ajaxcalled ? (
              <utilities.Gear style={{ width: "26px", marginTop: "9px" }} />
            ) : (
              <svg
                width="1.5em"
                height="100%"
                viewBox="0 0 16 16"
                className="bi bi-arrow-right-circle"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                />
                <path
                  fillRule="evenodd"
                  d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"
                />
              </svg>
            )}
          </span>
        </button>

        <div className="text-danger small text-center mt-4">
          {this.state.signuppath === "bvn" &&
            this.state.signupstage === "one" &&
            !this.state.ajaxcalled &&
            this.state.ajaxerror}
        </div>

        <div className="success-color text-center font-weight-bold mt-4">
          Why do you need my BVN?
        </div>
      </div>
    );
  };

  SignUpViaNewAccount = () => {
    return (
      <div className="mx-auto form-guy animated fadeIn delay-0.5s">
        <div className="web-space">
          <p className="padding2">&nbsp;</p>
          <p className="padding2">&nbsp;</p>
        </div>

        <div className="gen-text-color font-weight-bold mb-5">
          <h2 className="mb-4 font-weight-bold">Phone</h2>
          <p className="small font-weight-bold">
            Enter your phone number to proceed.
          </p>
        </div>

        <div className="form-group">
          <label htmlFor="" className="small darkcolor font-weight-bold">
            Phone Number
          </label>
          <input
            placeholder="234"
            type="number"
            className="form-control"
            value={this.state.phone}
            name="phone"
            onChange={this.handleInput}
            maxLength="14"
            minLength="11"
            pattern="[0-9]{14}"
          />
          <div className="text-danger small">
            {!this.state.phone && this.state.validate && "Required"}
          </div>
          <div className="text-danger small">
            {this.state.phone.length > 14 &&
              this.state.validate &&
              "Phone number is invalid."}
          </div>
          <div className="text-danger small">
            {this.state.phone.length < 11 &&
              this.state.validate &&
              "Enter at least 11 digits."}
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="" className="small darkcolor mb-1 font-weight-bold">
            Date of birth
          </label>

          <DatePicker
            selected={this.state.dateofbirth}
            value={this.state.dateofbirth}
            onChange={(date) => this.handleChange("dateofbirth", date)}
            name="dateofbirth"
            dateFormat="dd/MM/yyyy"
            placeholderText="DD / MM / YYYY"
            className="dateofbirth form-control w-100"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            locale="en-GB"
          />

          {/* <input type="date" 
                        name="dateofbirth" 
                        value={this.state.dateofbirth} 
                        onChange={this.handleInput} 
                        className="dateofbirth form-control" 
                    /> */}

          <div className="text-danger small">
            {!this.state.dateofbirth && this.state.validate && "Required"}
          </div>
          <div className="text-danger small">
            {new Date(this.state.dateofbirth).getFullYear() + 18 >
              new Date().getFullYear() &&
              this.state.validate &&
              "You must be over 18 to sign up."}
          </div>
        </div>

        <div className="mb-4">
          By clicking Continue, you agree to our
          <Link to="/privacy"> privacy policy</Link> and{" "}
          <Link to="/terms"> terms & conditions.</Link>
        </div>

        <button
          disabled={this.state.ajaxcalled}
          className="login-btn small"
          type="button"
          onClick={() => {
            this.startPhoneVerification("two");
          }}
        >
          <span className="w-85 font-weight-bold ml-50">Continue</span>

          <span className="w-15 h-100 slant-arrow">
            {this.state.ajaxcalled ? (
              <utilities.Gear style={{ width: "26px", marginTop: "9px" }} />
            ) : (
              <svg
                width="1.5em"
                height="100%"
                viewBox="0 0 16 16"
                className="bi bi-arrow-right-circle"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                />
                <path
                  fillRule="evenodd"
                  d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"
                />
              </svg>
            )}
          </span>
        </button>

        <div className="text-danger small text-center mt-4">
          {!this.state.ajaxcalled && this.state.ajaxerror}
        </div>
      </div>
    );
  };

  BVNVerifiedModal = () => {
    //this caters for all successful verification notifications for each of the signup modes.
    return (
      <div
        style={{ top: "250px" }}
        id={
          this.state.showmodal && this.state.targetmodal === "bvnverifiedmodal"
            ? "showmodal"
            : ""
        }
        className="p-3 bvnverifiedmodal animated fadeIn delay-0.5s"
      >
        <div className="text-center">
          <div>
            <svg
              width="3em"
              height="3em"
              viewBox="0 0 16 16"
              className="success-color bi bi-check2-circle"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"
              />
              <path
                fillRule="evenodd"
                d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"
              />
            </svg>
          </div>
          <p className="padding2">&nbsp;</p>

          {this.state.signuppath === "bvn" && (
            <div className="choice-menu">
              <div className="gen-text-color font-weight-bold mb-5">
                <h2 className="mb-2 font-weight-bold">BVN Verified</h2>
                <p className="small font-weight-bold">
                  Your BVN was verified successfully.
                </p>
              </div>
            </div>
          )}

          {this.state.signuppath === "bankaccount" && (
            <div className="choice-menu">
              <div className="gen-text-color font-weight-bold mb-5">
                <h2 className="mb-2 font-weight-bold">Bank Account Verified</h2>
                <p className="small font-weight-bold">
                  Your bank account was verified successfully.
                </p>
              </div>
            </div>
          )}

          {this.state.signuppath === "newaccount" && (
            <div className="choice-menu">
              <div className="gen-text-color font-weight-bold mb-5">
                <h2 className="mb-2 font-weight-bold">Phone number Verified</h2>
                <p className="small font-weight-bold">
                  Your phone number was verified successfully.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  ContactDetailsForm = () => {
    return (
      <div className="animated fadeIn delay-0.5s">
        <div className="gen-text-color font-weight-bold mb-5">
          <h1 className="mb-4 font-weight-bold">Create Account</h1>
          <p className="small font-weight-bold">
            Input further details to complete account creation.
          </p>
        </div>

        <div className="success-color mb-4">
          <p className="mb-1 pb-2 small font-weight-bold">Contact Details</p>
          <hr className="h-rule" />
        </div>

        <div className="row">
          <div className="col-lg">
            <div className="form-group">
              <label htmlFor="" className="small darkcolor font-weight-bold">
                Email Address
              </label>
              <input
                placeholder="Email Address"
                value={this.state.email}
                name="email"
                onChange={this.handleInput}
                type="text"
                className="form-control"
                data-validation="email"
              />
              <div className="text-danger small">
                {!this.state.email && this.state.validate && "Required"}
              </div>
              <div className="text-danger small mb-3">
                {!utilities.validmail(this.state.email) &&
                  this.state.validate &&
                  "Email address is not valid."}
              </div>
            </div>
          </div>

          <div className="col-lg">
            <div className="form-group">
              <label htmlFor="" className="small darkcolor font-weight-bold">
                Phone Number
              </label>
              <input
                disabled
                placeholder="234"
                type="number"
                className="form-control"
                value={this.state.phone}
                name="phone"
                onChange={this.handleInput}
                maxLength="14"
                minLength="11"
                pattern="[0-9]{14}"
              />
              <div className="text-danger small">
                {!this.state.phone && this.state.validate && "Required"}
              </div>
              <div className="text-danger small">
                {this.state.phone.length > 14 &&
                  this.state.validate &&
                  "Phone number is invalid."}
              </div>
              <div className="text-danger small">
                {this.state.phone.length < 11 &&
                  this.state.validate &&
                  "Enter at least 11 digits."}
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-lg">
            <div className="form-group mb-0">
              <label htmlFor="" className="small darkcolor font-weight-bold">
                Password
              </label>
              <input
                placeholder="Password"
                type={this.state.mainpasswordtype}
                className="form-control"
                value={this.state.password}
                name="password"
                onChange={this.handleInput}
              />

              {this.state.mainpasswordtype === "password" ? (
                <span
                  className="cp icon-correction1 eye-icon"
                  style={{ left: "83%", width: "44px" }}
                  onClick={() => {
                    this.togglePasswordEye("main");
                  }}
                >
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className="bi bi-eye-fill"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                    <path
                      fillRule="evenodd"
                      d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
                    />
                  </svg>
                </span>
              ) : (
                <span
                  style={{ left: "83%", width: "44px" }}
                  className="icon-correction1 eye-icon cp"
                  onClick={() => {
                    this.togglePasswordEye("main");
                  }}
                >
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className="bi bi-eye-slash-fill"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.79 12.912l-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                    <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708l-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829z" />
                    <path
                      fillRule="evenodd"
                      d="M13.646 14.354l-12-12 .708-.708 12 12-.708.708z"
                    />
                  </svg>
                </span>
              )}
              <div className="text-danger small mt-n4">
                {!this.state.password && this.state.validate && "Required"}
              </div>
              <div className="text-danger small mt-n4 mb-4">
                {this.state.password &&
                  !utilities.validPassword(this.state.password) &&
                  this.state.validate &&
                  "Password must contain at least 1 special character(!#$@%), 1 uppercase letter, 1 lowercase letter, 1 number and must be at least 8 characters in length."}
              </div>
            </div>
          </div>

          <div className="col-lg">
            <div className="form-group mb-0">
              <label htmlFor="" className="small darkcolor font-weight-bold">
                Confirm Password
              </label>
              <input
                placeholder="Confirm Password"
                type={this.state.subpasswordtype}
                className="form-control"
                value={this.state.kpassword}
                name="kpassword"
                onChange={this.handleInput}
              />

              {this.state.subpasswordtype === "password" ? (
                <span
                  className="cp icon-correction1 eye-icon"
                  style={{ left: "83%" }}
                  onClick={() => {
                    this.togglePasswordEye("sub");
                  }}
                >
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className="bi bi-eye-fill"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                    <path
                      fillRule="evenodd"
                      d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
                    />
                  </svg>
                </span>
              ) : (
                <span
                  style={{ left: "83%" }}
                  className="icon-correction1 eye-icon cp"
                  onClick={() => {
                    this.togglePasswordEye("sub");
                  }}
                >
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className="bi bi-eye-slash-fill"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.79 12.912l-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                    <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708l-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829z" />
                    <path
                      fillRule="evenodd"
                      d="M13.646 14.354l-12-12 .708-.708 12 12-.708.708z"
                    />
                  </svg>
                </span>
              )}
            </div>

            <div className="text-danger small mt-n4 mb-4">
              {!this.state.kpassword && this.state.validate && "Required"}
            </div>
            <div className="text-danger small mt-n4">
              {this.state.kpassword.toString() !==
                this.state.password.toString() &&
                this.state.validate &&
                "Passwords don't match."}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="" className="small darkcolor font-weight-bold">
                Are you a salary officer?
              </label>

              <select
                value={this.state.salarytype}
                name="salarytype"
                onChange={this.handleInput}
                className="custom-select custommenu"
              >
                <option value="" disabled={true}>
                  Salary officer?
                </option>
                <option value="true">Yes I am</option>
                <option value="false">No I'm not</option>
              </select>

              <div className="text-danger small">
                {!this.state.salarytype && this.state.validate && "Required"}
              </div>
            </div>
          </div>

          {this.state.salarytype === "true" && (
            <div className="col-lg">
              <div className="form-group">
                <label htmlFor="" className="small darkcolor font-weight-bold">
                  Force Number
                </label>

                {/* <span className="cp eye-icon" style={{left: "83%"}}>
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-eye-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                        <path fillRule="evenodd" d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                                    </svg>
                                </span> */}

                <input
                  placeholder="Force Number"
                  maxLength="15"
                  type="text"
                  className="form-control"
                  value={this.state.forcenumber}
                  name="forcenumber"
                  onChange={this.handleInput}
                />
                <div className="text-danger small">
                  {!this.state.forcenumber && this.state.validate && "Required"}
                </div>

                <div className="text-danger small">
                  {this.state.forcenumber.length < 6 &&
                    this.state.validate &&
                    "Force number must not be below 6 characters."}
                </div>
                <div className="text-danger small">
                  {this.state.forcenumber.length > 15 &&
                    this.state.validate &&
                    "Force number must not exceed 15 characters."}
                </div>
                <div className="text-danger small">
                  {isNaN(this.state.forcenumber) &&
                    this.state.validate &&
                    "Force number must consist of only numeric characters."}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="row">
          {this.state.salarytype === "true" && (
            <>
              <div className="col-lg-6">
                <div className="form-group">
                  <label
                    htmlFor=""
                    className="small darkcolor font-weight-bold"
                  >
                    Rank
                  </label>

                  <input
                    placeholder="Rank"
                    maxLength="50"
                    type="text"
                    className="form-control"
                    value={this.state.rank}
                    name="rank"
                    onChange={this.handleInput}
                  />
                  <div className="text-danger small">
                    {!this.state.rank && this.state.validate && "Required"}
                  </div>

                  <div className="text-danger small">
                    {this.state.rank &&
                      this.state.rank.length < 10 &&
                      this.state.validate &&
                      "Rank must not be below 10 characters."}
                  </div>
                  <div className="text-danger small">
                    {this.state.rank &&
                      this.state.rank.length > 50 &&
                      this.state.validate &&
                      "Rank must not exceed 50 characters."}
                  </div>
                  {/* <div className="text-danger small">
                                    {isNaN(this.state.rank) && this.state.validate && "IPPIS number must consist of only numeric characters."}
                                </div> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label
                    htmlFor=""
                    className="small darkcolor font-weight-bold"
                  >
                    Security Number
                  </label>

                  <input
                    placeholder="Security Number"
                    maxLength="10"
                    type="text"
                    className="form-control"
                    value={this.state.securitynumber}
                    name="securitynumber"
                    onChange={this.handleInput}
                  />
                  <div className="text-danger small">
                    {!this.state.securitynumber &&
                      this.state.validate &&
                      "Required"}
                  </div>

                  <div className="text-danger small">
                    {this.state.securitynumber &&
                      this.state.securitynumber.length < 6 &&
                      this.state.validate &&
                      "Security number must not be below 6 characters."}
                  </div>
                  <div className="text-danger small">
                    {this.state.securitynumber &&
                      this.state.securitynumber.length > 11 &&
                      this.state.validate &&
                      "Security number must not exceed 11 characters."}
                  </div>
                  {/* <div className="text-danger small">
                                    {isNaN(this.state.securitynumber) && this.state.validate && "IPPIS number must consist of only numeric characters."}
                                </div> */}
                </div>
              </div>
            </>
          )}
        </div>

        <div className="row">
          {this.state.salarytype === "true" && (
            <>
              <div className="col-lg-6">
                <div className="form-group">
                  <label
                    htmlFor=""
                    className="small darkcolor font-weight-bold"
                  >
                    IPPIS Number
                  </label>

                  <input
                    placeholder="IPPIS Number"
                    maxLength="10"
                    type="text"
                    className="form-control"
                    value={this.state.ippisnumber}
                    name="ippisnumber"
                    onChange={this.handleInput}
                  />
                  <div className="text-danger small">
                    {!this.state.ippisnumber &&
                      this.state.validate &&
                      "Required"}
                  </div>

                  <div className="text-danger small">
                    {this.state.ippisnumber &&
                      this.state.ippisnumber.length < 5 &&
                      this.state.validate &&
                      "IPPIS number must not be below 5 characters."}
                  </div>
                  <div className="text-danger small">
                    {this.state.ippisnumber &&
                      this.state.ippisnumber.length > 15 &&
                      this.state.validate &&
                      "IPPIS number must not exceed 15 characters."}
                  </div>
                  {/* <div className="text-danger small">
                                    {isNaN(this.state.ippisnumber) && this.state.validate && "IPPIS number must consist of only numeric characters."}
                                </div> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label
                    htmlFor=""
                    className="small darkcolor font-weight-bold"
                  >
                    NHIS Number
                  </label>

                  <input
                    placeholder="NHIS Number"
                    maxLength="15"
                    type="text"
                    className="form-control"
                    value={this.state.nhisnumber}
                    name="nhisnumber"
                    onChange={this.handleInput}
                  />
                  <div className="text-danger small">
                    {!this.state.nhisnumber &&
                      this.state.validate &&
                      "Required"}
                  </div>

                  <div className="text-danger small">
                    {this.state.nhisnumber &&
                      this.state.nhisnumber.length < 6 &&
                      this.state.validate &&
                      "NHIS number must not be below 6 characters."}
                  </div>
                  <div className="text-danger small">
                    {this.state.nhisnumber &&
                      this.state.nhisnumber.length > 11 &&
                      this.state.validate &&
                      "NHIS number must not exceed 11 characters."}
                  </div>
                  {/* <div className="text-danger small">
                                    {isNaN(this.state.nhisnumber) && this.state.validate && "IPPIS number must consist of only numeric characters."}
                                </div> */}
                </div>
              </div>
            </>
          )}
        </div>
        <button
          type="button"
          disabled={this.state.ajaxcalled}
          className="chat-btn"
          style={
            this.state.ajaxcalled
              ? { padding: "16px 10px", left: "40%", background: "#29C186" }
              : { left: "40%", background: "#29C186" }
          }
          onClick={this.handleContactDetailsForm}
        >
          {this.state.ajaxcalled ? (
            <utilities.Gear style={{ marginTop: "-5px" }} />
          ) : (
            <svg
              width="2.5em"
              height="2.5em"
              style={{ marginLeft: "-11px", marginTop: "-5px" }}
              viewBox="0 0 16 16"
              className="bi bi-arrow-right-short"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
              />
            </svg>
          )}
        </button>

        {/* <utilities.ContinueButton /> */}
      </div>
    );
  };

  PersonalDetailsForm = () => {
    return (
      <div className="animated fadeIn delay-0.5s">
        <div className="gen-text-color font-weight-bold mb-5">
          <h1 className="mb-4 font-weight-bold">Create Account</h1>
          <p className="small font-weight-bold">
            Fill in personal details and upload an ID.
          </p>
        </div>

        <div className="success-color mb-4">
          <p className="mb-1 pb-2 small font-weight-bold">Personal Details</p>
          <hr className="h-rule" />
        </div>

        <div className="row">
          <div className="col-lg">
            <div className="form-group">
              <label htmlFor="" className="small darkcolor font-weight-bold">
                First Name
              </label>
              <input
                disabled={this.state.signuppath !== "newaccount"}
                placeholder="First name"
                name="firstname"
                value={this.state.firstname}
                onChange={this.handleInput}
                type="text"
                className="form-control"
              />
              <div className="text-danger small">
                {!this.state.firstname && this.state.validate && "Required"}
                {this.state.firstname &&
                  this.state.validate &&
                  !utilities.isAlphabetic(this.state.firstname) &&
                  "Only letters are allowed."}
              </div>
            </div>
          </div>

          <div className="col-lg">
            <div className="form-group">
              <label htmlFor="" className="small darkcolor font-weight-bold">
                Last Name
              </label>
              <input
                disabled={this.state.signuppath !== "newaccount"}
                placeholder="Last name"
                name="lastname"
                value={this.state.lastname}
                onChange={this.handleInput}
                type="text"
                className="form-control"
              />
              <div className="text-danger small">
                {!this.state.lastname && this.state.validate && "Required"}
                {this.state.lastname &&
                  this.state.validate &&
                  !utilities.isAlphabetic(this.state.lastname) &&
                  "Only letters are allowed."}
              </div>
            </div>
          </div>
        </div>

        <utilities.DocUploadSegment
          photofilename={this.state.photofilename}
          photo={this.state.photo}
          validate={this.state.validate}
          photoinvalid={this.state.photoinvalid}
          listofdoctypes={this.state.listofdoctypes}
          docexpirydate={this.state.docexpirydate}
          dateofbirth={this.state.dateofbirth}
          idtype={this.state.idtype}
          sector={this.state.sector}
          idnumber={this.state.idnumber}
          docissuedate={this.state.docissuedate}
          signature={this.state.signature}
          signatureinvalid={this.state.signatureinvalid}
          signaturefilename={this.state.signaturefilename}
          utilities={utilities}
          handleInput={this.handleInput}
          handleChange={this.handleChange}
          handleFileUpload={this.handleFileUpload}
        />

        <div className="row">
          <div className="col-lg">
            <div className="form-group">
              <label htmlFor="" className="small darkcolor font-weight-bold">
                Sector
              </label>
              <select
                className="custom-select custommenu"
                value={this.state.sector}
                name="sector"
                onChange={this.handleInput}
              >
                <option value="" disabled={true}>
                  Sector
                </option>
                <option value="Police">Police</option>
                <option value="Paramillitary">Paramillitary</option>
                <option value="Other">Other</option>
              </select>

              <div className="text-danger small">
                {!this.state.sector && this.state.validate && "Required"}
              </div>
            </div>
          </div>

          <div className="col-lg">
            {/* {
                            this.state.signuppath === "newaccount"
                            && */}
            <div className="form-group">
              <label htmlFor="" className="small darkcolor font-weight-bold">
                Date of birth
              </label>
              <input
                disabled
                placeholder="DD-MM-YYYY"
                type="date"
                className="dateofbirth form-control"
                value={this.state.dateofbirth}
                name="dateofbirth"
                onChange={this.handleInput}
              />
              <div className="text-danger small">
                {!this.state.dateofbirth && this.state.validate && "Required"}
              </div>
            </div>
            {/* } */}
          </div>
        </div>

        <div className="row">
          <div className="col-lg">
            <div className="form-group">
              <label htmlFor="" className="small darkcolor font-weight-bold">
                Blood Group
              </label>
              <input
                // disabled={this.state.signuppath !== "newaccount"}
                placeholder="Blood Group"
                name="bloodgroup"
                value={this.state.bloodgroup}
                onChange={this.handleInput}
                type="text"
                className="form-control"
              />
              <div className="text-danger small">
                {!this.state.bloodgroup && this.state.validate && "Required"}
                {this.state.bloodgroup &&
                  this.state.validate &&
                  !utilities.isAlphabetic(this.state.bloodgroup) &&
                  "Only letters are allowed."}
              </div>
            </div>
          </div>

          <div className="col-lg">
            <div className="form-group">
              <label htmlFor="" className="small darkcolor font-weight-bold">
                Genotype
              </label>
              <input
                // disabled={this.state.signuppath !== "newaccount"}
                placeholder="Genotype"
                name="genotype"
                value={this.state.genotype}
                onChange={this.handleInput}
                type="text"
                className="form-control"
              />
              <div className="text-danger small">
                {!this.state.genotype && this.state.validate && "Required"}
                {this.state.genotype &&
                  this.state.validate &&
                  !utilities.isAlphabetic(this.state.genotype) &&
                  "Only letters are allowed."}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            {/* {
                            this.state.signuppath === "newaccount"
                            && */}
            <div className="form-group">
              <label htmlFor="" className="small darkcolor font-weight-bold">
                Gender
              </label>
              <select
                className="custom-select custommenu"
                value={this.state.gender}
                name="gender"
                onChange={this.handleInput}
              >
                <option value="" disabled={true}>
                  Gender
                </option>

                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
              <div className="text-danger small">
                {!this.state.gender && this.state.validate && "Required"}
              </div>
            </div>
            {/* } */}
          </div>
        </div>

        <button
          type="button"
          disabled={this.state.ajaxcalled}
          className="chat-btn bg-success"
          style={
            this.state.ajaxcalled
              ? { padding: "16px 10px", left: "40%", background: "#29C186" }
              : { left: "40%", background: "#29C186" }
          }
          onClick={this.handlePersonalDetailsForm}
        >
          {this.state.ajaxcalled ? (
            <utilities.Gear style={{ marginTop: "-5px" }} />
          ) : (
            <svg
              width="2.5em"
              height="2.5em"
              style={{ marginLeft: "-11px", marginTop: "-5px" }}
              viewBox="0 0 16 16"
              className="bi bi-arrow-right-short"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
              />
            </svg>
          )}
        </button>

        {/* <div className="chat-btn" style={{left: "40%", background: "#29C186"}} onClick={this.handlePersonalDetailsForm}>
                    <svg width="2.5em" height="2.5em" style={{marginLeft: "-11px", marginTop: "-5px"}} viewBox="0 0 16 16" className="bi bi-arrow-right-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                    </svg>
                </div> */}

        {/* <utilities.ContinueButton /> pass in the stage here for utilities to set the state for you.*/}
      </div>
    );
  };

  VerifyPhone = () => {
    return (
      <div className="mx-auto form-guy animated fadeIn delay-0.5s">
        <div className="web-space">
          <p className="padding2">&nbsp;</p>
          <p className="padding2">&nbsp;</p>
        </div>

        {this.state.signuppath === "bvn" && (
          <div className="gen-text-color font-weight-bold mb-5">
            <h1 className="mb-4 font-weight-bold">Verify BVN</h1>
            <p className="small font-weight-bold">
              Input code sent to BVN linked email and phone.
            </p>
          </div>
        )}

        {this.state.signuppath === "bankaccount" && (
          <div className="gen-text-color font-weight-bold mb-5">
            <h1 className="mb-4 font-weight-bold">Verify Bank Account</h1>
            <p className="small font-weight-bold">
              Input code sent to bank account linked email and phone.
            </p>
          </div>
        )}

        {this.state.signuppath === "newaccount" && (
          <div className="gen-text-color font-weight-bold mb-5">
            <h1 className="mb-4 font-weight-bold">Verify Phone Number</h1>
            <p className="small font-weight-bold">
              Input code sent to phone number.
            </p>
          </div>
        )}

        <div id="code-form" className="code-form">
          <input
            className="code-inputs"
            type="text"
            id="code1"
            name="bvncode1"
            onChange={this.handleInput}
            value={this.state["bvncode1"]}
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            className="code-inputs"
            type="text"
            id="code2"
            name="bvncode2"
            onChange={this.handleInput}
            value={this.state["bvncode2"]}
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            className="code-inputs"
            type="text"
            id="code3"
            name="bvncode3"
            onChange={this.handleInput}
            value={this.state["bvncode3"]}
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            className="code-inputs"
            type="text"
            id="code4"
            name="bvncode4"
            onChange={this.handleInput}
            value={this.state["bvncode4"]}
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            className="code-inputs"
            type="text"
            id="code5"
            name="bvncode5"
            onChange={this.handleInput}
            value={this.state["bvncode5"]}
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            className="code-inputs"
            type="text"
            id="code6"
            name="bvncode6"
            onChange={this.handleInput}
            value={this.state["bvncode6"]}
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
        </div>

        <div className="text-danger small mb-4">
          {(!this.state.bvncode1 ||
            !this.state.bvncode2 ||
            !this.state.bvncode3 ||
            !this.state.bvncode4 ||
            !this.state.bvncode5 ||
            !this.state.bvncode6) &&
            this.state.validate &&
            "Required"}
        </div>

        <div className="mb-4">
          By clicking Continue, you agree to our
          <Link to="/privacy"> privacy policy</Link> and{" "}
          <Link to="/terms"> terms & conditions.</Link>
        </div>

        <button
          disabled={this.state.ajaxcalled}
          className="login-btn small"
          type="button"
          onClick={() => {
            this.verifyPhone("three");
          }}
        >
          <span className="w-85 font-weight-bold ml-50">Continue</span>

          <span className="w-15 h-100 slant-arrow">
            {this.state.ajaxcalled ? (
              <utilities.Gear style={{ width: "26px", marginTop: "9px" }} />
            ) : (
              <svg
                width="1.5em"
                height="100%"
                viewBox="0 0 16 16"
                className="bi bi-arrow-right-circle"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                />
                <path
                  fillRule="evenodd"
                  d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"
                />
              </svg>
            )}
          </span>
        </button>

        <div className="text-danger small text-center mt-4">
          {!this.state.ajaxcalled &&
            this.state.ajaxerror &&
            this.state.ajaxerror}
        </div>

        <div
          onClick={this.resendCode}
          className="cp success-color text-center font-weight-bold mt-4"
        >
          Resend code?
        </div>
      </div>
    );
  };

  SignUpEnd = () => {
    return (
      <div className="form-guy mx-auto animated fadeIn delay-0.5s">
        <p className="padding2">&nbsp;</p>
        <p className="padding2">&nbsp;</p>
        <p className="padding2">&nbsp;</p>

        <div>
          <svg
            width="6em"
            height="6em"
            viewBox="0 0 16 16"
            className="text-success bi bi-check2-circle font-weight-bold"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"
            />
            <path
              fillRule="evenodd"
              d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"
            />
          </svg>
        </div>
        <p className="padding2">&nbsp;</p>

        <div className="gen-text-color font-weight-bold mb-5">
          <h1 className="my-4 font-weight-bold">Created Successfully</h1>
          <p className="small font-weight-bold ft14">
            Your account was created successfully, click the button below to
            complete signup and access your account and start banking
            seamlessly.
          </p>
        </div>

        <p className="padding2">&nbsp;</p>
        <p className="padding2">&nbsp;</p>
        <p className="padding2">&nbsp;</p>

        <div className="text-right w-100">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className="d-flex cp"
            style={{ justifyContent: "flex-end" }}
            onClick={this.handleSignIn}
          >
            <div
              className="font-weight-bold mr-3 gen-text-color ft14"
              style={{ lineHeight: "3.5" }}
            >
              Dashboard
            </div>

            <button
              type="button"
              disabled={this.state.ajaxcalled}
              className="dash-btn bg-success text-right"
              style={
                this.state.ajaxcalled
                  ? { padding: "16px 10px", left: "40%", background: "#29C186" }
                  : { left: "40%", background: "#29C186" }
              }
            >
              {this.state.ajaxcalled ? (
                <utilities.Gear style={{ marginTop: "-5px" }} />
              ) : (
                <svg
                  style={{ marginTop: "5px", marginRight: "3px" }}
                  width="3em"
                  height="3em"
                  viewBox="0 0 16 16"
                  className="bi bi-arrow-right-short"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                  />
                </svg>
              )}
            </button>
          </a>
        </div>
      </div>
    );
  };

  LivelinessCheckVideo = () => {
    return (
      <section className=" mx-auto">
        <div className="gen-text-color font-weight-bold mb-5">
          <h1 className="mb-4 font-weight-bold text-center">
            Liveliness Check
          </h1>

          {!this.state.captureallowed && (
            <p className="small font-weight-bold text-danger">
              You need to enable camera and microphone to perform this
              operation.
            </p>
          )}
          {!this.state.captureallowed && (
            <p className="small font-weight-bold text-danger">
              Click on the lock icon in the address bar. Then select allow
              camera and microphone from the menu. Then refresh your page to
              effect the change.
            </p>
          )}

          {this.state.captureallowed &&
            !this.state.voiceallowed &&
            Utilities.mobileCheck() && (
              <div>
                <p className="small font-weight-bold">
                  Click on Start Recording to make a 5 second verification
                  video.
                </p>
                <p className="small font-weight-bold">
                  The video must show the front and two sides of your head.
                </p>
                <p className="small font-weight-bold">
                  Follow the steps below to perform this verification:
                </p>
                <p>
                  1. Once the recording starts, look straight into the camera.
                </p>
                <p>
                  2. Say <b>ONE.</b>
                </p>
                <p>3. Turn right.</p>
                <p>
                  4. Say <b>TWO.</b>
                </p>
                <p>5. Turn left.</p>
                <p>
                  6. Say <b>THREE.</b> Then click on Stop Recording.
                </p>
              </div>
            )}

          {!this.state.voiceallowed &&
            this.state.captureallowed &&
            !this.state.ismobile && (
              <div className="text-danger">
                Your browser does not support text to speech. Switch to Chrome
                or Firefox or continue by following the instructions above.
              </div>
            )}

          {this.state.voiceallowed && this.state.captureallowed && (
            <div>
              Follow the voice prompt after you click on "Start Recording".
            </div>
          )}
        </div>

        <div className="w400 mx-auto">
          <div className="">
            <button
              disabled={
                this.state.recordingstarted || !this.state.captureallowed
              }
              id="btn-start-recording"
              className="login-btn small my-4"
              type="button"
              onClick={() => {
                this.startLivelinessCheckVideo();
              }}
            >
              <span className="w-85 font-weight-bold ml-50">
                Start Recording
              </span>

              <span className="w-15 h-100 slant-arrow">
                <svg
                  width="1.5em"
                  height="100%"
                  viewBox="0 0 16 16"
                  className="bi bi-arrow-right-circle"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"
                  />
                </svg>
              </span>
            </button>
          </div>

          <div className="">
            <button
              disabled={!this.state.recordingstarted}
              id="btn-start-recording"
              className="login-btn small my-4"
              type="button"
              onClick={() => {
                this.handleRecordingStop();
              }}
            >
              <span className="w-85 font-weight-bold ml-50">
                Stop Recording
              </span>

              <span className="w-15 h-100 slant-arrow">
                <svg
                  width="1.5em"
                  height="100%"
                  viewBox="0 0 16 16"
                  className="bi bi-arrow-right-circle"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>

        <div className="mx-auto text-center">
          <video id="live-video" controls autoPlay playsInline>
            Video Stream is not available
          </video>
        </div>

        {!store.get("user") && (
          <button
            type="button"
            disabled={
              this.state.ajaxcalled ||
              !this.state.customervideo ||
              this.state.recordingstarted
            }
            className="chat-btn"
            style={
              this.state.ajaxcalled
                ? { padding: "16px 10px", left: "40%", background: "#29C186" }
                : { left: "40%", background: "#29C186" }
            }
            onClick={this.toFinalStep}
          >
            {this.state.ajaxcalled ? (
              <Utilities.UtilitiesGear style={{ marginTop: "-5px" }} />
            ) : (
              <svg
                width="2.5em"
                height="2.5em"
                style={{ marginLeft: "-11px", marginTop: "-5px" }}
                viewBox="0 0 16 16"
                className="bi bi-arrow-right-short"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                />
              </svg>
            )}
          </button>
        )}
      </section>
    );
  };

  render() {
    return (
      <article className="animated fadeIn delay-0.5s page-bg">
        <section className="mx-auto">
          <div className="wrapper" id="signup-wrapper">
            <div className="form-inner mx-auto pb-4">
              <form className="mx-auto">
                <div className="row mb-4">
                  <div className="col px-2">
                    {this.state.signupstage !== "image-check" &&
                      this.state.signupstage !== "video-check" &&
                      this.state.signupstage !== "final" && (
                        <span>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a
                            className="gen-text-color cp"
                            onClick={this.handleBackButton}
                          >
                            <svg
                              width="2em"
                              height="2em"
                              viewBox="0 0 16 16"
                              className="bi bi-arrow-left-short font-weight-bold"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                              />
                            </svg>
                            <span className="vam font-weight-bold">Back</span>
                          </a>
                        </span>
                      )}
                  </div>

                  <div className="col">
                    <div className="text-right">
                      <img
                        src="/assets/imgs/npf-logo-main.png"
                        alt=""
                        className="sign-up-icon w-20"
                      />
                    </div>
                  </div>
                </div>

                {!this.state.signupstage && !this.state.signuppath && (
                  <this.SignUpMenu />
                )}

                {this.state.signuppath === "bvn" &&
                  this.state.signupstage === "one" && <this.SignUpViaBVN />}
                {this.state.signuppath === "bvn" &&
                  this.state.signupstage === "two" && <this.VerifyPhone />}

                {this.state.signupstage === "image-check" &&
                  this.state.signuppath === "LivelinessCheckImage" && (
                    <utilities.LivelinessCheckImage
                      this={this}
                      customerphoto={this.state.customerphoto}
                      captureallowed={this.state.captureallowed}
                      utilities={utilities}
                      handler={this.setSignUpStage}
                      takePicture={this.takePicture}
                    />
                  )}
                {this.state.signupstage === "video-check" &&
                  this.state.signuppath === "LivelinessCheckVideo" && (
                    <this.LivelinessCheckVideo
                      this={this}
                      captureallowed={this.state.captureallowed}
                      voiceallowed={this.state.voiceallowed}
                      ismobile={this.state.ismobile}
                      ajaxcalled={this.state.ajaxcalled}
                      recordingstarted={this.state.recordingstarted}
                      customervideo={this.state.customervideo}
                      utilities={utilities}
                      startLivelinessCheckVideo={
                        Utilities.startLivelinessCheckVideo
                      }
                      handleRecordingStop={Utilities.handleRecordingStop}
                      handler={this.toFinalStep}
                    />
                  )}

                {this.state.signupstage === "final" &&
                  !this.state.signuppath && <this.SignUpEnd />}

                {(this.state.signuppath === "bvn" ||
                  this.state.signuppath === "bankaccount" ||
                  this.state.signuppath === "newaccount") &&
                  this.state.signupstage === "three" && (
                    <this.PersonalDetailsForm />
                  )}
                {(this.state.signuppath === "bvn" ||
                  this.state.signuppath === "bankaccount" ||
                  this.state.signuppath === "newaccount") &&
                  this.state.signupstage === "four" && (
                    <this.ContactDetailsForm />
                  )}

                {this.state.signuppath === "bankaccount" &&
                  this.state.signupstage === "one" && (
                    <this.SignUpViaBankAccount />
                  )}
                {this.state.signuppath === "bankaccount" &&
                  this.state.signupstage === "two" && <this.VerifyPhone />}

                {this.state.signuppath === "newaccount" &&
                  this.state.signupstage === "one" && (
                    <this.SignUpViaNewAccount />
                  )}
                {this.state.signuppath === "newaccount" &&
                  this.state.signupstage === "two" && <this.VerifyPhone />}
              </form>
            </div>

            <div className="image-holder">
              <img src="/assets/imgs/herohalf.png" alt="" />
            </div>

            {/* <div className="chat-btn">
                            <utilities.ChatButton />
                        </div> */}
          </div>
        </section>

        <utilities.Modal
          showmodal={
            this.state.targetmodal === "bvnverifiedmodal"
              ? this.state.showmodal
              : false
          }
          child={this.BVNVerifiedModal}
        />
      </article>
    );
  }

  handleNavigation = () => {
    if (
      window.location.href.split("/").length > 4 &&
      window.location.href.split("/").length !== 8
    ) {
      //this.handleBackButton();

      let paths = window.location.href.split("/");

      //else {
      if (store.get("personaldetails")) {
        this.persistPersonalDetails();
      }

      if (store.get("contactdetails")) {
        this.persistContactDetails();
      }

      this.setState(
        {
          signupstage: paths[window.location.href.split("/").length - 2],
          signuppath: paths[window.location.href.split("/").length - 1],
        },
        () => {
          //store.set("signupstage", {stage: paths[window.location.href.split("/").length -2], path: paths[window.location.href.split("/").length-1]});
        }
      );
      //}

      //window.location.pathname("/signup/" + paths[0] + "")
    } else if (
      window.location.href.split("/").length > 6 &&
      window.location.href.split("/").length === 8
    ) {
      if (store.get("personaldetails")) {
        this.persistPersonalDetails();
      }

      if (store.get("contactdetails")) {
        this.persistContactDetails();
      }

      this.setState(
        {
          signupstage: "image-check",
          signuppath: "LivelinessCheckImage",
        },
        () => {
          //this.startLivelinessCheckImage();
          //store.set("signupstage", {stage: "image-check", path: "LivelinessCheckImage"});
        }
      );
    } else {
      this.setState({ signupstage: "", signuppath: "" });
      store.clearAll();
    }
  };

  componentDidMount() {
    this.handleNavigation();

    window.addEventListener(
      "popstate",
      function (e) {
        var state = e.state;
        this.handleNavigation();
      }.bind(this)
    );

    if ("navigator" in window) {
      //then we can proceed with any other thing.
      this.setState({ canproceed: true });

      if (store.get("token") && !store.get("signupstage")) {
        this.props.history.push("/dashboard/Dashboard");
      }

      if (Utilities.mobileCheck()) {
        this.setState({ ismobile: true });
      }

      if ("speechSynthesis" in window && !Utilities.mobileCheck()) {
        this.setState({ voiceallowed: true });
      } else {
        this.setState({ voiceallowed: false });
      }

      utilities.fetchDocumentTypes(this, httpservice);

      if (store.get("signupstage")) {
        (store.get("signupstage").stage === "image-check" ||
          store.get("signupstage").stage === "video-check") &&
          (store.get("signupstage").path === "LivelinessCheckImage" ||
            store.get("signupstage").path === "LivelinessCheckVideo") &&
          setTimeout(() => {
            Utilities.startLivelinessCheckImage(this);
          }, 1000);

        store.get("signupstage").stage === "video-check" &&
          store.get("signupstage").path === "LivelinessCheckVideo" &&
          setTimeout(() => {
            this.setState({ canproceed: true, captureallowed: true });
          }, 1000);
      }

      if (store.get("customer")) {
        if (store.get("personaldetails")) this.persistPersonalDetails();
        if (store.get("contactdetails")) this.persistContactDetails();
      }

      //if(store.get("dateofbirth")) this.setState({dateofbirth: store.get("dateofbirth")});
      //if(store.get("phone")) this.setState({phone: store.get("phone")});
    } else {
      this.setState({ canproceed: false });
    }
  }
}

export default withRouter(SignUp);
