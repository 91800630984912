import React from "react";
import { Utilities } from "./utilities";
import store from "store";
import HttpService from "../httpservice";
import NProgress from "nprogress";
import numeral from "numeral";
import SupportPage, {
  SupportEmail,
  SupportFaq,
  SupportBranch,
  SupportLiveAgent,
} from "./supportCenter";
import { ReactComponent as NothingFoundIcon } from "../assets/svg/nothing-found.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import CryptoJS from "crypto-js";

let utilities = new Utilities();
let httpservice = new HttpService();
// const loanUrl = "http://localhost:1909";
const loanUrl = "https://npf-loan-web.herokuapp.com";
// const loanUrl = "http://20.51.246.136:8185";
class Dashboard extends React.Component {
  constructor() {
    super();

    this.state = {
      ismobile: false,
      ajaxcalled: false,
      ownaccounttransfer: false,
      pageloading: true,
      activetab: "Dashboard",
      activesubtab: "",
      activeinnertab: "",
      activecard: "",
      loansurl: "",

      targetmodal: "",
      showmodal: false,

      beneficiarytype: "new",
      trxntype: "all",

      pin: "",
      dashboardaccount: {},
      listofbankaccounts: [],
      listofalltransactions: [],
      listoflocalbeneficiaries: [],
      listofairtimebillers: [],
      listofinterbankbeneficiaries: [],
      listofdataproviders: [],
      listofdataplans: [],
      listofserviceoptions: [],
      listofbillercategories: [],
      listofcategorybillers: [],
      listofrequiredfields: [],
      debittrxn: [],
      credittrxn: [],
      transactionkeys: [],
      listofbanks: [],
      listofsupportlines: [],
      listofsupportemails: [],
      transactionsdisplayed: {},
      destinationbankcode: "",
      destinationaccountname: "",
      destinationaccountnumber: "",
      transactionref: "",
      paymentamount: "",
      narrative: "",
      analyticsdateto: "",
      analyticsdatefrom: "",
      doesPinMatch: true,
      accountpending: false,
      hidepagecontent: false,
      accountmessage: "",
      validateDestinationAccountNumber: false,
      destinationaccountnumber_called: "no",

      airtimephone: "",
      airtimephoneself: false,
      airtimeserviceid: "",
      billerid: "",
      interbankbeneficiary: false,
      intrabankbeneficiary: false,

      categoryid: "",
      serviceid: "",
      requiredfields: {},
      requiredfieldvalue: "",
      email: "",
      phone: "",
    };
  }

  handleInput = (e) => {
    e.persist();

    if (e.target.name === "paymentamount") {
      this.setState({
        paymentamount: e.target.value.toLocaleString({
          minimumFractionDigits: 2,
        }),
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });

      if (e.target.name?.includes?.("confirm"))
        this.setState({ validate: false });
      let otp = document.querySelectorAll(".code-form");

      if (otp) utilities.activateNextInput();
    }
  };

  handleInputClear = () => {
    this.setState({
      destinationbankcode: "",
      destinationaccountname: "",
      destinationaccountnumber: "",
      paymentamount: "",
      narrative: "",
      transactionref: "",
      airtimephone: "",
      airtimephoneself: false,
      airtimeserviceid: "",
      billerid: "",
      ownaccounttransfer: false,

      categoryid: "",
      serviceid: "",
      requiredfields: {},
      requiredfieldvalue: "",
      email: "",
      phone: "",

      trxnreference: "",
    });

    let inputs = document.getElementsByTagName("input");
    let selects = document.getElementsByTagName("select");
    let textareas = document.getElementsByTagName("textarea");

    for (var i = 0; i < inputs.length; i++) {
      inputs[i].value = "";
    }

    for (var j = 0; j < selects.length; j++) {
      selects[j].value = "";
    }

    for (var k = 0; k < textareas.length; k++) {
      textareas[k].value = "";
    }
  };

  showModal = (targetmodal) => {
    this.setState({ targetmodal: targetmodal, showmodal: true });
  };

  hideModal = () => {
    this.setState({
      showmodal: false,
      requestmessage: "",
      requestcaller: "",
      requeststatus: "",
    });
  };

  toggleZoom = (activecard) => {
    this.setState({ activecard: activecard });
  };

  startPayment = (step) => {
    this.setState({ paymentstep: step });
  };

  checkPendingConditions = () => {
    //livechecks is for only existing users. hide page also
    //livechecks is only for new users when status is DISAPPROVED
    //PND is only for outbound transactions.

    if (
      store.get("user") &&
      !store.get("user").isnewbankcustomer &&
      store.get("user").livelinesschecked !== "APPROVED"
    ) {
      this.setState(
        {
          accountpending: true,
          hidepagecontent: true,
          accountmessage:
            "Your account is pending approval. You will receive an email/SMS once this is concluded.",
        },
        () => {
          this.showModal("accountpendingmodal");
        }
      );
    } else if (
      store.get("user") &&
      store.get("user").isnewbankcustomer &&
      store.get("user").livelinesschecked === "DISAPPROVED"
    ) {
      this.setState(
        {
          accountpending: true,
          accountmessage:
            "Your account is pending approval. You will receive an email/SMS once this is concluded.",
          hidepagecontent: true,
        },
        () => {
          this.showModal("accountpendingmodal");
        }
      );
    } else if (store.get("user") && store.get("user").PND) {
      this.setState({
        accountpending: true,
        hidepagecontent: false,
        accountmessage: "Your account is pending verification.",
      });

      this.fetchPageData();
    } else {
      this.fetchPageData();
    }
  };

  fetchAirtimeNetworks = () => {
    NProgress.inc();

    httpservice
      .unAuthGet("biller/airtime_options")
      .then((response) => {
        NProgress.done();
        if (response.result) {
          this.setState({ listofairtimebillers: response.result });
        } else if (response.status === 401) this.handleSignOut();
      })
      .catch((error) => {
        NProgress.done();
        utilities.Notify(error.message, "error");
      });
  };

  fetchBillerCategories = () => {
    NProgress.inc();

    this.setState({ listofbillercategories: [] });

    httpservice
      .unAuthGet("biller/biller_categories")
      .then((response) => {
        NProgress.done();
        if (response.result) {
          this.setState({ listofbillercategories: response.result });
        } else if (response.status === 401) this.handleSignOut();
      })
      .catch((error) => {
        NProgress.done();
        utilities.Notify(error.message, "error");
      });
  };

  fetchCategoryBillers = (e) => {
    this.setState(
      { categoryid: e.target.value, listofcategorybillers: [] },
      () => {
        NProgress.inc();

        httpservice
          .unAuthGet(
            "biller/view_billers?biller_category_id=" + this.state.categoryid
          )
          .then((response) => {
            NProgress.done();
            if (response.result) {
              this.setState({ listofcategorybillers: response.result });
            } else if (response.status === 401) this.handleSignOut();
          })
          .catch((error) => {
            NProgress.done();
            utilities.Notify(error.message, "error");
          });
      }
    );
  };

  fetchRequiredFields = (e) => {
    this.setState({ serviceid: e.target.value, requiredfields: {} }, () => {
      NProgress.inc();

      httpservice
        .unAuthGet("biller/required_fields?service_id=" + this.state.serviceid)
        .then((response) => {
          NProgress.done();
          if (response.result) {
            this.setState({ requiredfields: response.result });
          } else if (response.status === 401) this.handleSignOut();
          else {
            utilities.Notify(response.message, "error");
          }
        })
        .catch((error) => {
          NProgress.done();
          utilities.Notify(error.message, "error");
        });
    });
  };

  fetchDataProviders = () => {
    NProgress.inc();

    httpservice
      .unAuthGet("biller/data_billers")
      .then((response) => {
        NProgress.done();
        if (response.result) {
          this.setState({ listofdataproviders: response.result });
        } else if (response.status === 401) this.handleSignOut();
      })
      .catch((error) => {
        NProgress.done();
        utilities.Notify(error.message, "error");
      });
  };

  fetchServiceOptions = (e) => {
    this.setState({ billerid: e.target.value }, () => {
      NProgress.inc();

      httpservice
        .unAuthGet("biller/service_options?biller_id=" + this.state.billerid)
        .then((response) => {
          NProgress.done();
          if (response.result) {
            this.state.activesubtab === "QuickTellerPayments"
              ? this.setState({ listofserviceoptions: response.result })
              : this.setState({ listofdataplans: response.result });
          } else if (response.status === 401) this.handleSignOut();
        })
        .catch((error) => {
          NProgress.done();
          utilities.Notify(error.message, "error");
        });
    });
  };

  fetchPageData = () => {
    //if(store.get("tabs")) this.setState({activetab: store.get("tabs").activetab, activesubtab: store.get("tabs").activesubtab});

    this.fetchBankAccounts();
    this.fetchBanks();
    this.fetchInterBankBeneficiaries();
    this.fetchLocalBankBeneficiaries();
    this.fetchAirtimeNetworks();
    this.fetchDataProviders();
    this.fetchSupportEmails();
    this.fetchSupportLines();
    this.fetchBillerCategories();
  };

  fetchBankAccounts = () => {
    NProgress.inc();

    httpservice
      .authGet("bank-account/all_accounts")
      .then((response) => {
        NProgress.done();
        if (response.result) {
          this.setState({
            listofbankaccounts: response.result,
            dashboardaccount: response.result[0],
          });

          if (response.result.length)
            this.setDebitAccount(response.result[0].accountNo);

          this.fetchRecentTransactions(response.result[0].accountNo);
        } else if (response.status === 401) this.handleSignOut();
      })
      .catch((error) => {
        NProgress.done();
        utilities.Notify(error.message, "error");
      });
  };

  fetchBanks = () => {
    NProgress.inc();

    httpservice
      .authGet("funds-transfer/get_banks")
      .then((response) => {
        NProgress.done();
        if (response.result) {
          const tempBanks = response.result || [];
          const sortedTempBanks = tempBanks.sort((a, b) =>
            a.name?.toLowerCase?.() > b.name?.toLowerCase?.()
              ? 1
              : a.name?.toLowerCase?.() < b.name?.toLowerCase?.()
              ? -1
              : 0
          );
          this.setState({ listofbanks: sortedTempBanks });
        } else if (response.status === 401) this.handleSignOut();
      })
      .catch((error) => {
        NProgress.done();
        utilities.Notify(error.message, "error");
      });
  };

  fetchSupportEmails = () => {
    NProgress.inc();

    httpservice
      .authGet("support/get_support_emails")
      .then((response) => {
        NProgress.done();
        if (response.result) {
          this.setState({ listofsupportemails: response.result });
        } else if (response.status === 401) this.handleSignOut();
      })
      .catch((error) => {
        NProgress.done();
        utilities.Notify(error.message, "error");
      });
  };

  fetchSupportLines = () => {
    NProgress.inc();

    httpservice
      .authGet("support/get_support_lines")
      .then((response) => {
        NProgress.done();
        if (response.result) {
          this.setState({ listofsupportlines: response.result });
        } else if (response.status === 401) this.handleSignOut();
      })
      .catch((error) => {
        NProgress.done();
        utilities.Notify(error.message, "error");
      });
  };

  fetchInterBankBeneficiaries = () => {
    NProgress.inc();

    httpservice
      .authGet("bank-account/view_inter_bank_beneficiaries")
      .then((response) => {
        NProgress.done();
        if (response.result) {
          this.setState({ listofinterbankbeneficiaries: response.result });
        } else if (response.status === 401) this.handleSignOut();
      })
      .catch((error) => {
        NProgress.done();
        utilities.Notify(error.message, "error");
      });
  };

  fetchLocalBankBeneficiaries = () => {
    NProgress.inc();

    httpservice
      .authGet("bank-account/view_local_bank_beneficiaries")
      .then((response) => {
        NProgress.done();
        if (response.result) {
          this.setState({ listoflocalbeneficiaries: response.result });
        } else if (response.status === 401) this.handleSignOut();
      })
      .catch((error) => {
        NProgress.done();
        utilities.Notify(error.message, "error");
      });
  };

  fetchRecentTransactions = (accountno) => {
    NProgress.inc();

    let startdate = this.state.analyticsdatefrom
      ? moment(this.state.analyticsdatefrom).format().split("T")[0]
      : utilities.getYesterdaysDate();
    let enddate = this.state.analyticsdateto
      ? moment(this.state.analyticsdateto).format().split("T")[0]
      : moment().format().split("T")[0];

    if (String.prototype.replaceAll) {
      startdate = startdate.replaceAll("/", "-");
      enddate = enddate.replaceAll("/", "-");
    }

    httpservice
      .authGet(
        "bank-account/recent_transactions?account_number=" +
          accountno +
          "&start_date=" +
          startdate +
          "&end_date=" +
          enddate
      )
      .then((response) => {
        NProgress.done();

        if (
          response.error &&
          response?.message?.toLowerCase?.().includes("no results found")
        ) {
          this.setState({
            debittrxn: [],
            credittrxn: [],
            listofalltransactions: [],
            transactionkeys: [],
            transactionsdisplayed: {},
          });

          this.computeAnalytics(0, 0);
          return;
        }

        if (response.result) {
          let group = response.result.slice(0, 100).reduce((r, a) => {
            r[a.date] = [...(r[a.date] || []), a];
            return r;
          }, {});

          let debit = 0,
            credit = 0,
            debittrxn = [],
            credittrxn = [];

          response.result.forEach((trxn) => {
            if (trxn.type === "Debit") {
              debittrxn.push(trxn);

              debit += trxn.amount;
            }
            if (trxn.type === "Credit") {
              credittrxn.push(trxn);

              credit += trxn.amount;
            }
          });

          this.setState({
            debittrxn: debittrxn,
            credittrxn: credittrxn,
            listofalltransactions: response.result,
            transactionkeys: Object.keys(group),
            transactionsdisplayed: group,
          });

          this.computeAnalytics(debit, credit);
        } else if (response.status === 401) this.handleSignOut();
      })
      .catch((error) => {
        NProgress.done();
        utilities.Notify(error.message, "error");
      });
  };

  cancelTransaction = () => {
    document.getElementById("trxnpin1").value = "";
    document.getElementById("trxnpin2").value = "";
    document.getElementById("trxnpin3").value = "";
    document.getElementById("trxnpin4").value = "";

    this.setState({
      destinationbankcode: "",
      destinationaccountnumber: "",
      paymentamount: "",
      narrative: "",
    });

    this.switchTab("Payments");
  };

  switchTab = (targettab) => {
    this.hideModal();
    this.handleInputClear();

    this.setState({
      airtimephoneself: false,
      activetab: targettab,
      activesubtab: "",
      ajaxerror: "",
      activeinnertab: "",
    });

    document.getElementById("navbarSupportedContent").classList.remove("show");

    this.props.history.push("/dashboard/" + targettab);

    store.set("tabs", {
      activetab: targettab,
      activesubtab: "",
      activeinnertab: "",
    });

    utilities.scrollToTopFunction();

    if (targettab === "Loans") {
      this.encryptToken(store.get("token"));
    }

    if (targettab === "ManageLoans") {
      this.encryptManageToken(store.get("token"));
    }
  };

  switchSubTab = (parent, child) => {
    this.hideModal();

    this.setState(
      { activetab: parent, activesubtab: child, ajaxerror: "" },
      () => {
        if (this.state.ownaccounttransfer) {
          let data = {
            destination_account: this.state.listofbankaccounts[1].accountNo,
            source_account: this.state.sourceaccount,
          };

          this.runBankAccountConfirmation(data);
        }
      }
    );

    this.props.history.push("/dashboard/" + parent + "/" + child);

    document.getElementById("navbarSupportedContent").classList.remove("show");

    store.set("tabs", { activetab: parent, activesubtab: child });

    utilities.scrollToTopFunction();
  };

  getConfirmPinFields = () => [
    document.getElementById("confirmPin1"),
    document.getElementById("confirmPin2"),
    document.getElementById("confirmPin3"),
    document.getElementById("confirmPin4"),
  ];

  clearConfirmPinFields = () =>
    this.getConfirmPinFields().forEach((elem) => (elem.value = ""));

  changeTransactionPin = () => {
    const [confirmPin1, confirmPin2, confirmPin3, confirmPin4] =
      this.getConfirmPinFields();
    let existingpin =
      document.getElementById("expin1").value +
      "" +
      document.getElementById("expin2").value +
      "" +
      document.getElementById("expin3").value +
      "" +
      document.getElementById("expin4").value;
    let newpin =
      document.getElementById("npin1").value +
      "" +
      document.getElementById("npin2").value +
      "" +
      document.getElementById("npin3").value +
      "" +
      document.getElementById("npin4").value;

    let confirmPin =
      confirmPin1.value +
      "" +
      confirmPin2.value +
      "" +
      confirmPin3.value +
      "" +
      confirmPin4.value;

    if (existingpin.length !== 4 || newpin.length !== 4) {
      this.setState({ validate: true });
    } else if (newpin !== confirmPin) {
      this.setState({ validate: true, doesPinMatch: false });
    } else {
      this.setState({
        validate: false,
        existingpin: existingpin,
        newpin: newpin,
        ajaxcalled: true,
        doesPinMatch: true,
      });

      let data = {
        existing_pin: existingpin,
        new_pin: newpin,
        customer_id: store.get("user").id,
      };

      httpservice
        .authPost("bank-account/change_pin", data)
        .then((response) => {
          this.setState({ ajaxcalled: false });

          switch (response.error) {
            case true:
              this.setState(
                {
                  requestmessage: response.message,
                  requestcaller: "pinchange",
                  requeststatus: "error",
                },
                () => {
                  this.showModal("pinoperationmodal");
                }
              );

              //this.setState({requestmessage: "", requestcaller: "", requeststatus: ""});
              break;
            case false:
              document.getElementById("expin1").value = "";
              document.getElementById("expin2").value = "";
              document.getElementById("expin3").value = "";
              document.getElementById("expin4").value = "";

              document.getElementById("npin1").value = "";
              document.getElementById("npin2").value = "";
              document.getElementById("npin3").value = "";
              document.getElementById("npin4").value = "";
              this.clearConfirmPinFields();

              this.setState(
                {
                  requestmessage: response.message,
                  requestcaller: "pinchange",
                  requeststatus: "success",
                },
                () => {
                  this.showModal("pinoperationmodal");
                }
              );

              setTimeout(() => {
                this.handleInputClear();

                this.switchTab("Payments");
              }, 4000);
              break;
            default:
              this.setState(
                {
                  requestmessage:
                    "Unable to complete your request. Try again later.",
                  requestcaller: "pinchange",
                  requeststatus: "error",
                },
                () => {
                  this.showModal("pinoperationmodal");
                }
              );
              break;
          }
        })
        .catch((error) => {
          this.setState({ ajaxcalled: false });
          console.log("error: ", error);
        });
    }
  };

  setUpTransactionPin = () => {
    const pin1 = document.getElementById("pin1");
    const pin2 = document.getElementById("pin2");
    const pin3 = document.getElementById("pin3");
    const pin4 = document.getElementById("pin4");
    const [confirmPin1, confirmPin2, confirmPin3, confirmPin4] =
      this.getConfirmPinFields();
    let pin = pin1.value + "" + pin2.value + "" + pin3.value + "" + pin4.value;
    let confirmPin =
      confirmPin1.value +
      "" +
      confirmPin2.value +
      "" +
      confirmPin3.value +
      "" +
      confirmPin4.value;

    if (pin.length !== 4) {
      this.setState({ validate: true });
    } else if (pin !== confirmPin) {
      this.setState({ validate: true, doesPinMatch: false });
    } else {
      this.setState({
        validate: false,
        pin: pin,
        ajaxcalled: true,
        doesPinMatch: true,
      });
      let data = { pin: pin, customer_id: store.get("user").id };

      httpservice
        .authPost("bank-account/create_pin", data)
        .then((response) => {
          this.setState({ ajaxcalled: false });

          switch (response.error) {
            case true:
              this.setState(
                {
                  requestmessage: response.message,
                  requestcaller: "pinsetup",
                  requeststatus: "error",
                },
                () => {
                  this.showModal("pinoperationmodal");
                }
              );

              //this.setState({requestmessage: "", requestcaller: "", requeststatus: ""});
              break;
            case false:
              pin1.value = "";
              pin2.value = "";
              pin3.value = "";
              pin4.value = "";

              this.clearConfirmPinFields();

              let userobj = store.get("user");

              userobj["has_pin"] = true;
              store.set("user", userobj);

              this.setState(
                {
                  requestmessage: response.message,
                  requestcaller: "pinsetup",
                  requeststatus: "success",
                },
                () => {
                  this.showModal("pinoperationmodal");
                }
              );

              setTimeout(() => {
                this.handleInputClear();

                this.switchTab("Payments");
              }, 5000);
              break;
            default:
              this.setState(
                {
                  requestmessage:
                    "Unable to complete your request. Try again later.",
                  requestcaller: "pinsetup",
                  requeststatus: "error",
                },
                () => {
                  this.showModal("pinoperationmodal");
                }
              );
              break;
          }
        })
        .catch((error) => {
          this.setState({ ajaxcalled: false });
          console.log("error: ", error);
        });
    }
  };

  setDebitAccount = (account) => {
    this.setState({ sourceaccount: account });
  };

  confirmPin = () => {
    if (!this.state.paymentamount) this.props.history.goBack();

    let trxnpin =
      document.getElementById("trxnpin1").value +
      "" +
      document.getElementById("trxnpin2").value +
      "" +
      document.getElementById("trxnpin3").value +
      "" +
      document.getElementById("trxnpin4").value;

    if (trxnpin.length !== 4) {
      this.setState({ validate: true });
    } else {
      this.setState({ validate: false, trxnpin: trxnpin });

      if (
        this.state.activesubtab === "AirtimePurchase" ||
        this.state.activesubtab === "DataPurchase" ||
        this.state.activesubtab === "QuickTellerPayments"
      ) {
        this.handleBilling(trxnpin);
      } else {
        this.handleBankTransfers(trxnpin);
      }
    }
  };

  encryptToken = (token) => {
    NProgress.inc();
    var ciphertext = CryptoJS.AES.encrypt(
      token,
      process.env.REACT_APP_SECRET_KEY
    ).toString();

    let url = `${loanUrl}/apply/loans?auth=` + ciphertext;

    this.setState({ loansurl: url });

    setTimeout(() => {
      NProgress.done();
    }, 2000);
  };

  encryptManageToken = (token) => {
    NProgress.inc();
    var ciphertext = CryptoJS.AES.encrypt(
      token,
      process.env.REACT_APP_SECRET_KEY
    ).toString();

    let url = `${loanUrl}/manage/loans?auth=` + ciphertext;

    this.setState({ loansurl: url });

    setTimeout(() => {
      NProgress.done();
    }, 2000);
  };

  switchInnerTab = (tab) => {
    this.hideModal();

    this.setState({ activeinnertab: tab });

    this.props.history.push(
      "/dashboard/" +
        this.state.activetab +
        "/" +
        this.state.activesubtab +
        "/" +
        tab
    );
  };

  toResetPassword = () => {
    NProgress.inc();

    httpservice
      .unAuthPost("auth/reset_password", { phone: store.get("user").phone })
      .then((response) => {
        this.setState({ ajaxcalled: false, phone: "" });

        NProgress.done();

        if (!response.error) {
          this.handleSignOut();
        } else {
          this.setState({ ajaxerror: response.message });
        }
      })
      .catch((error) => {
        NProgress.done();
        this.setState({ ajaxcalled: false });
        console.log("error", error);
      });
  };

  confirmPaymentDetails = () => {
    if (
      !this.state.sourceaccount ||
      (this.state.activesubtab === "InterbankTransfer" &&
        this.state.beneficiarytype === "new" &&
        !this.state.destinationbankcode) ||
      !this.state.destinationaccountnumber ||
      this.state.destinationaccountnumber === this.state.sourceaccount ||
      isNaN(this.state.destinationaccountnumber) ||
      !this.state.paymentamount ||
      !this.state.narrative
    ) {
      this.setState({ validate: true, validateDestinationAccountNumber: true });
    } else {
      let account;

      if (
        this.state.beneficiarytype === "saved" &&
        this.state.activesubtab === "IntrabankTransfer"
      ) {
        account = this.state.listoflocalbeneficiaries.find((value) => {
          return (
            Number(value.accountnumber) ===
            Number(this.state.destinationaccountnumber)
          );
        });
      } else {
        account = this.state.listofinterbankbeneficiaries.find((value) => {
          return (
            Number(value.accountnumber) ===
            Number(this.state.destinationaccountnumber)
          );
        });
      }

      if (account) {
        this.setState({
          validate: false,
          validateDestinationAccountNumber: false,
          destinationbankcode: account.bankcode,
          destinationaccountname: account.accountname,
        });
      } else {
        this.setState({
          validate: false,
          validateDestinationAccountNumber: false,
          destinationbankcode: this.state.destinationbankcode,
          destinationaccountname: this.state.destinationaccountname,
        });
      }

      this.showModal("transactionconfirmationmodal");
    }
  };

  handleBankTransfers = (pin) => {
    let data, url, type;

    if (this.state.activesubtab === "InterbankTransfer") {
      data = {
        amount: this.state.paymentamount,
        narration: this.state.narrative,
        pin: pin,
        transaction_ref: this.state.transactionref,
      };

      url = "funds-transfer/inter_bank";
      type = "authPost";
    } else {
      data = {
        transaction_ref: this.state.transactionref,
        destination_account: this.state.destinationaccountnumber,
        amount: this.state.paymentamount,
        narration: this.state.narrative,
        pin: pin,
      };

      url = "funds-transfer/local_transfer";
      type = "authPost";
    }

    this.setState({ ajaxcalled: true, ajaxerror: "" });

    httpservice[type](url, data)
      .then((response) => {
        this.setState({ ajaxcalled: false });

        if (response.status === 401) this.handleSignOut();

        switch (response.error) {
          case true:
            this.setState({
              ajaxerror:
                response.message === "No_sufficient_funds"
                  ? "Insufficient funds. Fund your account and try again."
                  : response.message,
            });

            //this.handleInputClear();

            document.getElementById("trxnpin1").value = "";
            document.getElementById("trxnpin2").value = "";
            document.getElementById("trxnpin3").value = "";
            document.getElementById("trxnpin4").value = "";
            break;
          case false:
            store.set("t24Reference", response.result.t24Reference);
            store.set("ownaccounttransfer", false);

            document.getElementById("trxnpin1").value = "";
            document.getElementById("trxnpin2").value = "";
            document.getElementById("trxnpin3").value = "";
            document.getElementById("trxnpin4").value = "";

            this.fetchBankAccounts();

            this.handleInputClear();

            this.showModal("transactionsuccessfulmodal");

            //this.switchTab("Payments");

            break;
          default:
            break;
        }
      })
      .catch((error) => {
        this.setState({ ajaxcalled: false });
        console.log("error", error);
      });
  };

  goBackToPayments = () => {
    this.hideModal();
    this.handleInputClear();

    this.switchTab("Payments");
  };

  handleBilling = (pin) => {
    let data;

    this.state.activesubtab === "QuickTellerPayments"
      ? (data = {
          field_id: this.state.requiredfields.id,
          field_value: this.state.requiredfieldvalue,
          service_id: this.state.serviceid,
          phone: this.state.phone,
          pin: pin,
          source_account: this.state.sourceaccount,
          amount: this.state.paymentamount,
          narration: this.state.narrative,
          rrr: this.state.trxnreference,
        })
      : (data = {
          service_id: this.state.airtimeserviceid,
          phone: this.state.airtimephone,
          pin: pin,
          source_account: this.state.sourceaccount,
          amount: this.state.paymentamount,
          narration: this.state.narrative,
          rrr: this.state.trxnreference,
        });

    this.setState({ ajaxcalled: true });

    httpservice
      .authPost("biller/pay_bill", data)
      .then((response) => {
        this.setState({ ajaxcalled: false });

        if (response.status === 401) this.handleSignOut();

        switch (response.error) {
          case true:
            this.setState({
              ajaxerror:
                response.message === "No_sufficient_funds"
                  ? "Insufficient funds. Fund your account and try again."
                  : response.message,
            });

            this.handleInputClear();
            break;
          case false:
            store.set(
              "t24Reference",
              response.result.t24Reference || response.result.paymentRef
            );

            document.getElementById("trxnpin1").value = "";
            document.getElementById("trxnpin2").value = "";
            document.getElementById("trxnpin3").value = "";
            document.getElementById("trxnpin4").value = "";

            this.fetchBankAccounts();
            this.handleInputClear();

            this.showModal("transactionsuccessfulmodal");

            this.setState({
              listofcategorybillers: [],
              listofrequiredfields: [],
            });

            break;
          default:
            break;
        }
      })
      .catch((error) => {
        this.setState({ ajaxcalled: false });
        console.log("error", error);
      });
  };

  handleShareReceipt = async (imageUrl) => {
    try {
      const fetchedImg = await fetch(
        "https://cors-viktor.herokuapp.com/" + imageUrl
      );
      console.log(fetchedImg, "fetchedImg");
      const imgBlob = await fetchedImg.blob();
      console.log(imgBlob, "imgBlob");
      const imgFile = new File([imgBlob], "Transaction Recipt.jpg", {
        type: imgBlob.type,
      });
      console.log(imgFile, "file");
      await navigator.share({
        files: [imgFile],
        text: "Transaction Receipt",
        title: "NPF MFB",
      });
    } catch (error) {
      console.log("no-work", error);
    }
  };

  handleDownloadReceipt = () => {
    NProgress.inc();

    httpservice
      .generateReciept(
        "bank-account/receipt?transaction_ref=" + store.get("t24Reference")
      )
      .then((response) => {
        NProgress.done();

        if (response.status === 401) {
          this.handleSignOut();
        } else if (response.type === "application/json") {
          utilities.Notify(
            "Unable to generate receipt. Try again later.",
            "error"
          );
        } else {
          let pdffile = new Blob([response], { type: "application/pdf" });

          let downloadlink = document.createElement("a");

          downloadlink.download = "TransactionReceipt.pdf";

          downloadlink.href = window.URL.createObjectURL(pdffile);

          downloadlink.style.display = "none";

          document.body.appendChild(downloadlink);

          downloadlink.click();

          setTimeout(() => {
            this.goBackToPayments();
          }, 1500);
        }
      })
      .catch((error) => {
        NProgress.done();
        utilities.Notify(error.message, "error");
      });
  };

  handleCheck = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  filterTransactions = (name, date) => {
    this.setState(
      {
        [name]: date,
      },
      () => {
        if (this.state.analyticsdatefrom && this.state.analyticsdateto)
          this.fetchRecentTransactions(this.state.sourceaccount);
      }
    );
  };

  computeAnalytics = (debit, credit) => {
    let creditpercentage, debitpercentage, upperlimit;

    debit > upperlimit ? (upperlimit = debit) : (upperlimit = 50000);
    credit > upperlimit ? (upperlimit = credit) : (upperlimit = 50000);

    creditpercentage = (credit / upperlimit) * 100;
    debitpercentage = (debit / upperlimit) * 100;

    this.setState({
      creditpercentage: creditpercentage,
      debitpercentage: debitpercentage,
      credit: credit,
      debit: debit,
    });
  };

  handleSignOut = () => {
    NProgress.inc();

    httpservice
      .authPost("auth/sign_out", {})
      .then((response) => {
        NProgress.done();

        switch (response.error) {
          case true:
            utilities.Notify("Session expired. Unable to signout.", "error");

            break;
          case false:
            store.clearAll();
            this.props.history.push("/");

            break;
          default:
            store.clearAll();
            this.props.history.push("/");

            break;
        }
      })
      .catch((error) => {
        NProgress.done();
        this.setState({ ajaxcalled: false });
        console.log("error", error);
      });
  };

  determineCardColor = (idx) => {
    if (idx === 1) return "cp payment-account2";
    else return "cp payment-account1";
  };

  setInterBankBankCode = (e) => {
    e.persist();

    this.setState({
      [e.target.name]: e.target.value,
    });

    let account = this.state.listofinterbankbeneficiaries.find((value) => {
      return Number(value.accountnumber) === Number(e.target.value);
    });

    let data = {
      source_account: this.state.sourceaccount,
      destination_institution_code: account.bankcode,
      destination_account: e.target.value,
    };

    this.runBankAccountConfirmation(data);
  };

  runBankAccountConfirmation = (data) => {
    NProgress.start();
    let url =
      this.state.activesubtab === "IntrabankTransfer"
        ? "funds-transfer/local_transfer_query"
        : "funds-transfer/inter_bank_query";

    httpservice
      .authPost(url, data)
      .then((response) => {
        this.setState({
          ajaxcalled: false,
          destinationaccountnumber_called: "no",
        });
        NProgress.done();

        if (response.status === 401 || response.status === 403) {
          this.handleSignOut();
        } else {
          if (!response.error) {
            // set transaction ref
            this.setState({
              destinationaccountname: utilities.toTitleCase(
                response.result.receiver_name
              ),
              transactionref: response.result.transaction_ref,
              ajaxerror: "",
            });
          } else {
            this.setState({ ajaxerror: response.message });
          }
        }
      })
      .catch((error) => {
        this.setState({ ajaxcalled: false });
        NProgress.done();
        console.log("error: ", error);
      });
  };

  confirmBankAccount = (e) => {
    e.persist();

    this.setState({ [e.target.name]: e.target.value }, () => {
      if (this.state.destinationaccountnumber.length === 10) {
        if (isNaN(this.state.destinationaccountnumber)) {
          this.setState({
            validateDestinationAccountNumber: true,
            ajaxerror: "",
          });
          return;
        }

        this.setState({
          ajaxcalled: true,
          validateDestinationAccountNumber: false,
          destinationaccountnumber_called: "yes",
          ajaxerror: "",
        });

        let data =
          this.state.activesubtab === "IntrabankTransfer"
            ? {
                source_account: this.state.sourceaccount,
                destination_account: this.state.destinationaccountnumber,
              }
            : {
                source_account: this.state.sourceaccount,
                destination_institution_code: this.state.destinationbankcode,
                destination_account: this.state.destinationaccountnumber,
              };

        this.runBankAccountConfirmation(data);
      }
    });
  };

  setAirtimePhoneAsSelf = (e) => {
    this.setState({
      airtimephoneself: !this.state.airtimephoneself,
      airtimephone: this.state.airtimephone ? "" : store.get("user").phone,
    });
  };

  setDataPlan = (e) => {
    let billeramount = this.state.listofdataplans.find((value) => {
      return Number(value.service_id) === Number(e.target.value);
    });

    this.setState({
      airtimeserviceid: e.target.value,
      paymentamount: billeramount.amount,
    });
  };

  startOwnAccountsTransfer = () => {
    if (this.state.listofbankaccounts.length < 2) {
      utilities.Notify(
        "You need at least two accounts to perform this operation",
        "error"
      );
    } else {
      this.setState(
        {
          ownaccounttransfer: true,
          destinationaccountnumber: this.state.listofbankaccounts[1].accountNo,
          destinationaccountname: this.state.listofbankaccounts[1].accountName,
        },
        () => {
          store.set("ownaccounttransfer", true);

          this.checkPinSetUpStatus("Payments", "IntrabankTransfer");
        }
      );
    }
  };

  reConfirmOwnAccount = (e) => {
    let data = {
      destination_account: e.target.value,
      source_account: this.state.sourceaccount,
    };

    this.setState({ destinationaccountnumber: e.target.value });

    this.runBankAccountConfirmation(data);
  };

  checkPinSetUpStatus = (parent, child) => {
    if (!this.state.accountpending) {
      if (store.get("user").has_pin) {
        this.switchSubTab(parent, child);
      } else {
        this.switchSubTab("Settings", "SetUpPin");
      }
    } else {
      utilities.Notify("PND has been set on this account.", "error");
    }
  };

  confirmAirtimePayment = () => {
    if (
      !this.state.sourceaccount ||
      !this.state.airtimephone ||
      this.state.airtimephone.length < 11 ||
      this.state.airtimephone.length > 14 ||
      !this.state.airtimeserviceid ||
      !this.state.paymentamount ||
      !this.state.narrative
    ) {
      this.setState({ validate: true });
    } else {
      this.setState({ validate: false });

      let billerlabel;

      if (this.state.activesubtab === "AirtimePurchase") {
        billerlabel = this.state.listofairtimebillers.find((value) => {
          return (
            Number(value.service_id) === Number(this.state.airtimeserviceid)
          );
        });

        billerlabel = billerlabel.label;
      } else {
        billerlabel = this.state.listofdataproviders.find((value) => {
          return Number(value.biller_id) === Number(this.state.billerid);
        });

        if (billerlabel) {
          billerlabel = billerlabel.biller_name;
        }
      }

      let data = {
        service_id: this.state.airtimeserviceid,
        phone: this.state.airtimephone,
        amount: this.state.paymentamount,
      };

      this.setState({ airtimeserviceidlabel: billerlabel }, () => {
        this.fetchBillingData(data);
      });
    }
  };

  handleBackButton = (state) => {
    this.setState({
      activesubtab: "",
      beneficiarytype: "new",
      validate: false,
      ajaxerror: "",
      activeinnertab: "",
      paymentamount: "",
      airtimephone: "",
      narrative: "",
      airtimephoneself: false,
      //ownaccounttransfer: state === "ownaccounttransfer" ? true : false
    });

    this.handleInputClear();

    store.set("tabs", {
      activetab: store.get("tabs").activetab,
      activesubtab: "",
    });
    store.set("ownaccounttransfer", state === false);
    this.props.history.goBack();
  };

  pinAuthNavigation = () => {
    this.setState({ activeinnertab: "" });

    this.switchSubTab("Payments", this.state.activesubtab);
  };

  saveBankBeneficiary = (e) => {
    e.persist();

    this.setState(
      {
        [e.target.name]: e.target.checked,
        ajaxerror: "",
      },
      () => {
        if (this.state[e.target.name]) {
          NProgress.start();

          let data, url;

          if (this.state.activesubtab === "InterbankTransfer") {
            data = {
              account_number: this.state.destinationaccountnumber,
              account_name: this.state.destinationaccountname,
              bank_code: this.state.destinationbankcode,
            };

            url = "bank-account/create_inter_bank_beneficiary";
          } else {
            data = {
              account_number: this.state.destinationaccountnumber,
              account_name: this.state.destinationaccountname,
            };

            url = "bank-account/create_local_bank_beneficiary";
          }

          httpservice
            .authPost(url, data)
            .then((response) => {
              if (response.status === 401) this.handleSignOut();

              this.setState({ ajaxcalled: false });
              NProgress.done();

              if (!response.error) {
                utilities.Notify("Beneficiary added.", "success");
                this.state.activesubtab === "InterbankTransfer"
                  ? this.fetchInterBankBeneficiaries()
                  : this.fetchLocalBankBeneficiaries();
              } else {
                this.setState({ ajaxerror: response.message });
              }
            })
            .catch((error) => {
              this.setState({ ajaxcalled: false });
              NProgress.done();
              console.log("error: ", error);
            });
        }
      }
    );
  };

  AccountSelectionRow = () => {
    return (
      <div className="row">
        {this.state.listofbankaccounts.map((account, idx) => {
          return (
            <div
              className={"col-lg-4 cp"}
              key={account.accountNo}
              onClick={() => {
                this.setDebitAccount(account.accountNo);
              }}
            >
              <label htmlFor="zoomCheck">
                <div
                  className={
                    this.state.sourceaccount === account.accountNo
                      ? this.determineCardColor(idx) + " zoom-card"
                      : this.determineCardColor(idx)
                  }
                >
                  <input type="checkbox" className="d-none" />

                  <div className="text-right">
                    <span className="badge badge-pill sec-color text-white p-2">
                      {account.accountType}
                    </span>
                  </div>

                  <div className="small nearwhite">{account.accountNo}</div>

                  <div>
                    <h3 className="font-weight-bold text-white">
                      <utilities.NairaSympbol />
                      {numeral(account.availableBalance).format("0,0.00")}
                    </h3>
                  </div>

                  <p className="padding2 mb-0">&nbsp;</p>
                </div>
              </label>
            </div>
          );
        })}
      </div>
    );
  };

  setBeneficiaryType = (type) => {
    this.setState({
      beneficiarytype: type,
      ajaxerror: "",
      destinationaccountnumber: "",
      destinationaccountname: "",
      paymentamount: "",
      narrative: "",
      airtimephone: "",
      destinationbankcode: "",
      validate: false,
    });
  };

  confirmBillingForm = () => {
    if (
      !this.state.sourceaccount ||
      !this.state.serviceid ||
      (this.state.requiredfields && !this.state.requiredfields.id) ||
      !this.state.paymentamount ||
      !this.state.phone ||
      this.state.phone.length < 11 ||
      this.state.phone.length > 14 ||
      !this.state.email ||
      !this.state.requiredfieldvalue
    ) {
      this.setState({ validate: true });
    } else {
      let data = {
        service_id: this.state.serviceid,
        field_id: this.state.requiredfields.id,
        field_value: this.state.requiredfieldvalue,
        phone: this.state.phone,
        email: this.state.email,
        amount: this.state.paymentamount,
      };

      this.fetchBillingData(data);
    }
  };

  fetchBillingData = (data) => {
    this.setState({ ajaxcalled: true });

    httpservice
      .authPost("biller/fetch_billing_data", data)
      .then((response) => {
        this.setState({ ajaxcalled: false, ajaxerror: "" });

        if (response.status === 401) this.handleSignOut();

        switch (response.error) {
          case true:
            this.setState({ ajaxerror: response.message });

            //this.handleInputClear();
            break;
          case false:
            this.setState({ trxnreference: response.result.rrr });

            this.showModal("transactionconfirmationmodal");

            break;
          default:
            break;
        }
      })
      .catch((error) => {
        this.setState({ ajaxcalled: false });
        console.log("error", error);
      });
  };

  DashboardPage = () => {
    return (
      <div className="resume-section-content animated fadeIn delay-0.5s">
        <h2 className="mb-0 gen-text-color font-weight-bold mb-3">
          Hi{" "}
          {store.get("user")
            ? utilities.toTitleCase(store.get("user").firstname) + "."
            : ""}
        </h2>
        <div className="subheading mb-5 gen-text-color font-weight-bold">
          Welcome back. Get started with doing this.
        </div>

        <div className="row">
          <div className="col-lg mobile-bottom-space">
            <div className="h55 dash-account">
              <div className="text-right">
                <span className="badge badge-pill sec-color text-white p-2">
                  {this.state.dashboardaccount.accountType}
                </span>
              </div>

              <div className="small nearwhite">
                {this.state.dashboardaccount.accountNo}
              </div>

              <div>
                <h2 className="font-weight-bold text-white">
                  <utilities.NairaSympbol />
                  {numeral(this.state.dashboardaccount.availableBalance).format(
                    "0,0.00"
                  )}
                </h2>
              </div>

              <p className="padding2 mb-0">&nbsp;</p>

              <div className="small nearwhite">Ledger balance</div>

              <div>
                <h4 className="font-weight-bold text-white">
                  <utilities.NairaSympbol />
                  {numeral(this.state.dashboardaccount.bookBalance).format(
                    "0,0.00"
                  )}
                </h4>
              </div>
            </div>
          </div>

          <div
            className="col-lg cp"
            onClick={() => {
              this.switchTab("ComingSoon");
            }}
          >
            {/* <div className="col-lg" data-toggle="tooltip" data-placement="top" title="Coming soon."> */}
            <div className="add-button h55">
              <div className="text-center">
                <svg
                  width="2em"
                  height="2em"
                  viewBox="0 0 16 16"
                  className="bi bi-plus-circle-fill"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"
                  />
                </svg>

                <p className="font-weight-bold">Add an account</p>
              </div>
            </div>
          </div>
        </div>

        <p className="padding2 mb-0">&nbsp;</p>
        <p className="padding2 mb-0">&nbsp;</p>

        <div className="row mb-3">
          <div className="col font-weight-bold gen-text-color">
            Banking Links
          </div>

          <div className="col text-right gen-text-color">
            <svg
              width="3em"
              height="2em"
              viewBox="0 0 16 16"
              className="bi bi-three-dots threedots"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
              />
            </svg>
          </div>
        </div>

        <div className="row">
          <div className="col-lg mobile-bottom-space">
            <div
              className="funds-transfer p-3 cp"
              onClick={() => {
                this.switchTab("Payments");
              }}
            >
              <div className="text-right pb-3">
                <svg
                  width="3em"
                  height="2em"
                  viewBox="0 0 16 16"
                  className="bi bi-three-dots"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                  />
                </svg>
              </div>

              <div>
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-arrow-left-right st-icons"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"
                  />
                </svg>
              </div>

              <div className="font-weight-bold">
                Funds <br /> Transfer
              </div>
            </div>
          </div>

          {/* onClick={() => {this.checkPinSetUpStatus("Payments", "AirtimePurchase")}} */}

          <div
            className="col-lg mobile-bottom-space cp"
            onClick={() => {
              this.checkPinSetUpStatus("Payments", "AirtimePurchase");
            }}
          >
            {/* <div className="col-lg mobile-bottom-space" data-toggle="tooltip" data-placement="top" title="Coming soon."> */}
            <div className="mobile-transfer p-3">
              <div className="text-right pb-3">
                <svg
                  width="3em"
                  height="2em"
                  viewBox="0 0 16 16"
                  className="bi bi-three-dots"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                  />
                </svg>
              </div>

              <div>
                <svg
                  width="2em"
                  height="2em"
                  viewBox="0 0 16 16"
                  className="bi bi-tablet-fill"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm7 11a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
                  />
                </svg>
              </div>

              <div className="font-weight-bold">
                Mobile <br /> Top-Up
              </div>
            </div>
          </div>

          <div
            className="col-lg mobile-bottom-space cp"
            onClick={() => {
              this.switchTab("AllTransactions");
            }}
          >
            <div className="transaction-history p-3">
              <div className="text-right pb-3">
                <svg
                  width="3em"
                  height="2em"
                  viewBox="0 0 16 16"
                  className="bi bi-three-dots"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                  />
                </svg>
              </div>

              <div>
                <svg
                  width="2em"
                  height="2em"
                  viewBox="0 0 16 16"
                  className="bi bi-list-ul"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                  />
                </svg>
              </div>

              <div className="font-weight-bold">
                Transaction <br /> History
              </div>
            </div>
          </div>

          <div
            className="col-lg mobile-bottom-space cp"
            onClick={() => {
              this.checkPinSetUpStatus("Payments", "QuickTellerPayments");
            }}
          >
            {/* <div className="col-lg mobile-bottom-space" data-toggle="tooltip" data-placement="top" title="Coming soon."> */}
            <div className="utility-bills p-3">
              <div className="text-right pb-3">
                <svg
                  width="3em"
                  height="2em"
                  viewBox="0 0 16 16"
                  className="bi bi-three-dots"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                  />
                </svg>
              </div>

              <div>
                <svg
                  width="2em"
                  height="2em"
                  viewBox="0 0 16 16"
                  className="bi bi-credit-card-2-front-fill"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2zm0 3a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                  />
                </svg>
              </div>

              <div className="font-weight-bold">
                Pay <br /> Utility Bills.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  MainLoanPage = () => {
    return (
      <div className="resume-section-content animated fadeIn delay-0.5s">
        <div className="row mb-3">
          <h2 className="mb-0 gen-text-color font-weight-bold mb-3">
            Loan Manager
          </h2>

          <div className="col text-right gen-text-color">
            <svg
              width="3em"
              height="2em"
              viewBox="0 0 16 16"
              className="bi bi-three-dots threedots"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
              />
            </svg>
          </div>
        </div>

        <p className="padding2 mb-0">&nbsp;</p>
        <p className="padding2 mb-0">&nbsp;</p>

        <div className="row">
          <div
            onClick={() => {
              this.switchTab("Loans");
            }}
            className="col-lg font-weight-bold text-white mobile-bottom-space"
          >
            <div className="h55 apply-loan">
              {/* <div
                onClick={() => {
                  this.switchTab("Loans");
                }}
              > */}
              <div className="loan-icons">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="3em"
                  height="2em"
                  fill="currentColor"
                  className="bi bi-cash-coin"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
                  />
                  <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z" />
                  <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z" />
                  <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z" />
                </svg>
              </div>

              <div className="loan-text font-weight-bold">Apply for Loan</div>
              {/* </div> */}
            </div>
          </div>

          <div
            onClick={() => {
              this.switchTab("ManageLoans");
            }}
            className="col-lg font-weight-bold text-white mobile-bottom-space"
          >
            <div className="h55 dash-account">
              {/* <div
                onClick={() => {
                  this.switchTab("ManageLoans");
                }}
              > */}
              <div className="loan-icons">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="3em"
                  height="2em"
                  fill="currentColor"
                  className="bi bi-bank"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 .95 14.61 4h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.379l.5 2A.5.5 0 0 1 15.5 17H.5a.5.5 0 0 1-.485-.621l.5-2A.5.5 0 0 1 1 14V7H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 4h.89L8 .95zM3.776 4h8.447L8 2.05 3.776 4zM2 7v7h1V7H2zm2 0v7h2.5V7H4zm3.5 0v7h1V7h-1zm2 0v7H12V7H9.5zM13 7v7h1V7h-1zm2-1V5H1v1h14zm-.39 9H1.39l-.25 1h13.72l-.25-1z" />
                </svg>
              </div>

              <div className="loan-text font-weight-bold">Manage Loans</div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  BankTransferPage = () => {
    return (
      <div className="animated fadeIn delay-0.5s">
        <div>
          <a className="gen-text-color cp" onClick={this.handleBackButton}>
            <svg
              width="2em"
              height="2em"
              viewBox="0 0 16 16"
              className="bi bi-arrow-left-short"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
              />
            </svg>
            <span className="vam font-weight-bold">Back</span>
          </a>
        </div>

        <div className="gen-text-color font-weight-bold mb-3">
          {this.state.ownaccounttransfer && (
            <h2 className="mb-4 font-weight-bold">Own Account Transfer</h2>
          )}

          {!this.state.ownaccounttransfer && (
            <h2 className="mb-4 font-weight-bold">
              {this.state.activesubtab === "InterbankTransfer"
                ? "Interbank transfer"
                : "Intrabank Transfer"}
            </h2>
          )}
        </div>

        <div className="success-color mb-4">
          <p className="mb-1 pb-2 small font-weight-bold">
            Select debit account
          </p>
          <hr className="h-rule" />
        </div>

        {this.state.listofbankaccounts.length && (
          <div className="row">
            {this.state.listofbankaccounts.map((account, idx) => {
              return (
                <div
                  className={"col-lg-4 cp"}
                  key={account.accountNo}
                  onClick={() => {
                    this.setDebitAccount(account.accountNo);
                  }}
                >
                  <label htmlFor="zoomCheck">
                    <div
                      className={
                        this.state.sourceaccount === account.accountNo
                          ? this.determineCardColor(idx) + " zoom-card"
                          : this.determineCardColor(idx)
                      }
                    >
                      <input type="checkbox" className="d-none" />

                      <div className="text-right">
                        <span className="badge badge-pill sec-color text-white p-2">
                          {account.accountType}
                        </span>
                      </div>

                      <div className="small nearwhite">{account.accountNo}</div>

                      {/*  */}

                      <div>
                        <h3 className="font-weight-bold text-white">
                          <utilities.NairaSympbol />
                          {numeral(account.availableBalance).format("0,0.00")}
                        </h3>
                      </div>

                      <p className="padding2 mb-0">&nbsp;</p>
                    </div>
                  </label>
                </div>
              );
            })}
          </div>
        )}

        {!this.state.listofbankaccounts.length && (
          <div className={"col-lg-4 cp"}>
            <label htmlFor="zoomCheck">
              <div className="payment-account1">
                <input type="checkbox" className="d-none" />

                <div className="text-right">
                  <span className="badge badge-pill sec-color text-white p-2">
                    N/A
                  </span>
                </div>

                <div className="small nearwhite">
                  <h4>Bank account not available.</h4>
                </div>

                <div>
                  <h3 className="font-weight-bold text-white">N/A</h3>
                </div>

                <p className="padding2 mb-0">&nbsp;</p>
              </div>
            </label>
          </div>
        )}

        <div className="text-danger small">
          {!this.state.sourceaccount &&
            this.state.validate &&
            "Select a source account."}
        </div>
        <div className="text-danger small">
          {this.state.sourceaccount === this.state.destinationaccountnumber &&
            this.state.validate &&
            "Source and destination accounts must not be the same."}
        </div>

        {/*I tried to refactor the code below but I guess I'm not as smart.
                You could try O wise one. I bet you can only be allowed to look into this clutter because of your bravery and brilliance!! */}
        {!this.state.ownaccounttransfer && (
          <div>
            <p className="padding2 mt-2">&nbsp;</p>

            <div className="d-flex">
              <div
                className="col-lg-4 cp"
                onClick={() => {
                  this.setBeneficiaryType("saved");
                }}
              >
                <p
                  className={
                    this.state.beneficiarytype === "saved"
                      ? "gen-text-color font-weight-bold mb-1"
                      : "small text-muted font-weight-bold mb-1"
                  }
                >
                  Saved Beneficiary
                </p>
                {this.state.beneficiarytype === "saved" && (
                  <hr className="h-rule acct-active" />
                )}
              </div>

              <div
                className="col-lg-3 cp"
                onClick={() => {
                  this.setBeneficiaryType("new");
                }}
              >
                <p
                  className={
                    this.state.beneficiarytype === "new"
                      ? "gen-text-color font-weight-bold mb-1 ml-2"
                      : "small text-muted font-weight-bold mb-1"
                  }
                >
                  New
                </p>
                {this.state.beneficiarytype === "new" && (
                  <hr className="h-rule acct-active" />
                )}
              </div>
            </div>

            <p className="padding2 mt-2">&nbsp;</p>

            {this.state.beneficiarytype === "new" && (
              <div className="form-row date-range">
                {this.state.activesubtab !== "IntrabankTransfer" && (
                  <div className="form-group col-lg-6">
                    <select
                      name="destinationbankcode"
                      value={this.state.destinationbankcode}
                      onChange={this.handleInput}
                      className="custom-select custommenu"
                      id="exampleFormControlSelect1"
                    >
                      <option value="" disabled={true}>
                        Bank
                      </option>

                      {this.state.listofbanks.map((bank) => {
                        return (
                          <option key={bank.code} value={bank.code}>
                            {bank.name}
                          </option>
                        );
                      })}
                    </select>
                    <div className="text-danger small">
                      {!this.state.destinationbankcode &&
                        this.state.validate &&
                        "Required."}
                    </div>
                  </div>
                )}

                {this.state.beneficiarytype === "new" && (
                  <div className="form-group col-lg">
                    <input
                      disabled={
                        this.state.destinationaccountnumber_called === "yes" ||
                        (!this.state.destinationbankcode &&
                          this.state.activesubtab !== "IntrabankTransfer")
                      }
                      name="destinationaccountnumber"
                      value={this.state.destinationaccountnumber}
                      onChange={this.confirmBankAccount}
                      type="number"
                      maxLength="10"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Account Number"
                    />
                    <div className="text-danger small">
                      {!this.state.destinationaccountnumber &&
                        this.state.validateDestinationAccountNumber &&
                        "Required."}
                    </div>

                    <div className="text-danger small">
                      {isNaN(this.state.destinationaccountnumber) &&
                        this.state.validateDestinationAccountNumber &&
                        "Enter only numeric characters."}
                    </div>
                    <div className="text-danger small">
                      {!this.state.ajaxcalled && this.state.ajaxerror}
                    </div>
                  </div>
                )}
                <div className="mt-3">
                  {this.state.destinationaccountnumber_called === "yes" ? (
                    <utilities.GearBlack />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}

            {this.state.activesubtab === "InterbankTransfer" && (
              <div className="form-row date-range cp">
                {this.state.beneficiarytype === "saved" && (
                  <div className="form-group col-lg pr-0">
                    <select
                      name="destinationaccountnumber"
                      value={this.state.destinationaccountnumber}
                      onChange={this.setInterBankBankCode}
                      className="custom-select custommenu"
                      id="exampleFormControlSelect1"
                    >
                      <option value="" disabled={true}>
                        Beneficiary
                      </option>

                      {this.state.listofinterbankbeneficiaries.map((guy) => {
                        return (
                          <option
                            key={guy.accountnumber}
                            value={guy.accountnumber}
                          >
                            {guy.accountname}
                          </option>
                        );
                      })}
                    </select>
                    <div className="text-danger small">
                      {!this.state.destinationaccountnumber &&
                        this.state.validate &&
                        "Required."}
                    </div>
                    <div className="text-danger small">
                      {!this.state.ajaxcalled && this.state.ajaxerror}
                    </div>
                  </div>
                )}
              </div>
            )}

            {this.state.activesubtab === "IntrabankTransfer" && (
              <div className="form-row date-range cp">
                {this.state.beneficiarytype === "saved" && (
                  <div className="form-group col-lg pr-0">
                    <select
                      name="destinationaccountnumber"
                      value={this.state.destinationaccountnumber}
                      onChange={this.confirmBankAccount}
                      className="custom-select custommenu"
                      id="exampleFormControlSelect1"
                    >
                      <option value="" disabled={true}>
                        Beneficiary
                      </option>

                      {this.state.listoflocalbeneficiaries.map((guy) => {
                        return (
                          <option
                            key={guy.accountnumber}
                            value={guy.accountnumber}
                          >
                            {guy.accountname}
                          </option>
                        );
                      })}
                    </select>
                    <div className="text-danger small">
                      {!this.state.destinationaccountnumber &&
                        this.state.validate &&
                        "Required."}
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className="form-group px-0 date-range col-lg-12 mb-0">
              {this.state.beneficiarytype === "new" && (
                <input
                  disabled
                  name="destinationaccountname"
                  value={
                    this.state.destinationaccountname.split(" ").length > 1
                      ? utilities.toTitleCase(
                          this.state.destinationaccountname.split(" ")[0]
                        ) +
                        " " +
                        utilities.toTitleCase(
                          this.state.destinationaccountname.split(" ")[1]
                        )
                      : utilities.toTitleCase(this.state.destinationaccountname)
                  }
                  onChange={this.handleInput}
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Account Name"
                />
              )}

              {this.state.beneficiarytype === "new" &&
                this.state.activesubtab === "IntrabankTransfer" && (
                  <div
                    className="d-flex my-4"
                    style={{ flexDirection: "row-reverse" }}
                  >
                    <div className="custom-control custom-switch">
                      <input
                        onChange={this.saveBankBeneficiary}
                        name="intrabankbeneficiary"
                        value={this.state.intrabankbeneficiary}
                        disabled={
                          !this.state.destinationaccountname ||
                          !this.state.destinationaccountnumber
                        }
                        type="checkbox"
                        className="custom-control-input"
                        id="switch1"
                      />
                      <label className="custom-control-label" htmlFor="switch1">
                        Save beneficiary
                      </label>
                    </div>
                  </div>
                )}

              {this.state.beneficiarytype === "new" &&
                this.state.activesubtab === "InterbankTransfer" && (
                  <div
                    className="d-flex my-4"
                    style={{ flexDirection: "row-reverse" }}
                  >
                    <div className="custom-control custom-switch">
                      <input
                        onChange={this.saveBankBeneficiary}
                        name="interbankbeneficiary"
                        value={this.state.interbankbeneficiary}
                        disabled={
                          !this.state.destinationbankcode ||
                          !this.state.destinationaccountname ||
                          !this.state.destinationaccountnumber
                        }
                        type="checkbox"
                        className="custom-control-input"
                        id="switch1"
                      />
                      <label className="custom-control-label" htmlFor="switch1">
                        Save beneficiary
                      </label>
                    </div>
                  </div>
                )}
            </div>
          </div>
        )}

        {this.state.ownaccounttransfer && (
          <div>
            <div className="form-group mb-3 date-range">
              <label htmlFor="exampleFormControlTextarea1">
                Destination account
              </label>
              <select
                name="destinationaccountnumber"
                value={this.state.destinationaccountnumber}
                onChange={this.reConfirmOwnAccount}
                className="custom-select custommenu"
                id="exampleFormControlSelect1"
              >
                <option value="" disabled={true}>
                  Select own account
                </option>

                {this.state.listofbankaccounts.map((acct) => {
                  return (
                    <option key={acct.accountNo} value={acct.accountNo}>
                      {acct.accountName + " - " + acct.accountNo}
                    </option>
                  );
                })}
              </select>
              <div className="text-danger small">
                {!this.state.destinationaccountnumber &&
                  this.state.validate &&
                  "Required."}
              </div>
            </div>
          </div>
        )}

        <div className="form-group col-lg-12 date-range px-0 mb-4">
          {/* <label htmlFor="exampleFormControlTextarea1">Amount</label> */}
          <input
            name="paymentamount"
            value={this.state.paymentamount.toLocaleString({
              minimumFractionDigits: 2,
            })}
            onChange={this.handleInput}
            type="number"
            min={1}
            max={1000000000}
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="Amount"
          />
          <div className="text-danger small">
            {!this.state.paymentamount && this.state.validate && "Required."}
          </div>
        </div>

        <div className="form-group date-range mt-3">
          {/* <label htmlFor="exampleFormControlTextarea1">Narrative</label> */}
          <textarea
            name="narrative"
            value={this.state.narrative}
            onChange={this.handleInput}
            className="form-control"
            id="exampleFormControlTextarea1"
            rows="4"
          ></textarea>
          <div className="text-danger small">
            {!this.state.narrative && this.state.validate && "Required."}
          </div>
        </div>

        <div className="text-right">
          <button
            type="button"
            disabled={
              this.state.beneficiarytype !== "saved" &&
              !this.state.destinationaccountname
            }
            className="chat-btn"
            style={{ left: "67%", background: "#29C186", position: "static" }}
            onClick={this.confirmPaymentDetails}
          >
            <svg
              width="2.5em"
              height="2.5em"
              style={{ marginLeft: "-11px", marginTop: "-5px" }}
              viewBox="0 0 16 16"
              className="bi bi-arrow-right-short"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
              />
            </svg>
          </button>
        </div>
      </div>
    );
  };

  MobileTransferPage = () => {
    return (
      <div className="animated fadeIn delay-0.5s">
        <div>
          <a className="gen-text-color cp" onClick={this.handleBackButton}>
            <svg
              width="2em"
              height="2em"
              viewBox="0 0 16 16"
              className="bi bi-arrow-left-short"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
              />
            </svg>
            <span className="vam font-weight-bold">Back</span>
          </a>
        </div>

        <div className="gen-text-color font-weight-bold mb-3">
          {this.state.activesubtab === "AirtimePurchase" && (
            <h2 className="mb-4 font-weight-bold">Airtime Top-Up</h2>
          )}
          {this.state.activesubtab === "DataPurchase" && (
            <h2 className="mb-4 font-weight-bold">Data Purchase</h2>
          )}
        </div>

        <div className="success-color mb-4">
          <p className="mb-1 pb-2 small font-weight-bold">
            Select debit account
          </p>
          <hr className="h-rule" />
        </div>

        {this.state.listofbankaccounts.length && (
          <div className="row">
            {this.state.listofbankaccounts.map((account, idx) => {
              return (
                <div
                  className={"col-lg-4 cp"}
                  key={account.accountNo}
                  onClick={() => {
                    this.setDebitAccount(account.accountNo);
                  }}
                >
                  <label htmlFor="zoomCheck">
                    <div
                      className={
                        this.state.sourceaccount === account.accountNo
                          ? this.determineCardColor(idx) + " zoom-card"
                          : this.determineCardColor(idx)
                      }
                    >
                      <input type="checkbox" className="d-none" />

                      <div className="text-right">
                        <span className="badge badge-pill sec-color text-white p-2">
                          {account.accountType}
                        </span>
                      </div>

                      <div className="small nearwhite">{account.accountNo}</div>

                      <div>
                        <h3 className="font-weight-bold text-white">
                          <utilities.NairaSympbol />
                          {numeral(account.availableBalance).format("0,0.00")}
                        </h3>
                      </div>

                      <p className="padding2 mb-0">&nbsp;</p>
                    </div>
                  </label>
                </div>
              );
            })}
          </div>
        )}

        {!this.state.listofbankaccounts.length && (
          <div className={"col-lg-4 cp"}>
            <label htmlFor="zoomCheck">
              <div className="payment-account1">
                <input type="checkbox" className="d-none" />

                <div className="text-right">
                  <span className="badge badge-pill sec-color text-white p-2">
                    N/A
                  </span>
                </div>

                <div className="small nearwhite">
                  <h4>Bank account not available.</h4>
                </div>

                <div>
                  <h3 className="font-weight-bold text-white">N/A</h3>
                </div>

                <p className="padding2 mb-0">&nbsp;</p>
              </div>
            </label>
          </div>
        )}

        <div className="text-danger small">
          {!this.state.sourceaccount &&
            this.state.validate &&
            "Select a source account."}
        </div>

        <p className="padding2 mt-2">&nbsp;</p>

        {this.state.activesubtab === "DataPurchase" && (
          <div className="form-row date-range">
            <div className="form-group col-lg-6">
              <label htmlFor="exampleFormControlTextarea2">Network</label>
              <select
                name="billerid"
                value={this.state.billerid}
                onChange={this.fetchServiceOptions}
                className="custom-select custommenu"
                id="exampleFormControlSelect2"
              >
                <option value={""} disabled={true}>
                  Select network
                </option>

                {this.state.listofdataproviders.map((provider) => {
                  return (
                    <option key={provider.biller_id} value={provider.biller_id}>
                      {provider.biller_name}
                    </option>
                  );
                })}
              </select>
              <div className="text-danger small">
                {!this.state.billerid && this.state.validate && "Required."}
              </div>
            </div>

            <div className="form-group col-lg-6">
              <label htmlFor="exampleFormControlTextarea1">Data Plan</label>
              <select
                disabled={!this.state.listofdataplans.length}
                name="airtimeserviceid"
                value={this.state.airtimeserviceid}
                onChange={this.setDataPlan}
                className="custom-select custommenu"
                id="exampleFormControlSelect1"
              >
                <option value="" disabled={true}>
                  Select data plan
                </option>

                {this.state.listofdataplans.map((biller) => {
                  return (
                    <option key={biller.service_id} value={biller.service_id}>
                      {"₦" + biller.amount + " " + biller.description}
                    </option>
                  );
                })}
              </select>
              <div className="text-danger small">
                {!this.state.airtimeserviceid &&
                  this.state.validate &&
                  "Required."}
              </div>
            </div>
          </div>
        )}

        <div className="form-row date-range">
          {this.state.activesubtab === "AirtimePurchase" && (
            <div className="form-group col-lg-6">
              <label htmlFor="exampleFormControlTextarea1">Network</label>
              <select
                name="airtimeserviceid"
                value={this.state.airtimeserviceid}
                onChange={this.handleInput}
                className="custom-select custommenu"
                id="exampleFormControlSelect1"
              >
                <option value="" disabled={true}>
                  Select Network
                </option>

                {this.state.listofairtimebillers.map((biller) => {
                  return (
                    <option key={biller.service_id} value={biller.service_id}>
                      {biller.label}
                    </option>
                  );
                })}
              </select>
              <div className="text-danger small">
                {!this.state.airtimeserviceid &&
                  this.state.validate &&
                  "Required."}
              </div>
            </div>
          )}

          <div className="form-group col-lg">
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <label htmlFor="exampleFormControlTextarea1">Phone number</label>

              <div>
                <div className="custom-control custom-switch">
                  <input
                    name="airtimephone"
                    value={this.state.airtimephone}
                    onChange={this.setAirtimePhoneAsSelf}
                    type="checkbox"
                    className="custom-control-input"
                    id="switch1"
                  />
                  <label className="custom-control-label" htmlFor="switch1">
                    Self
                  </label>
                </div>
              </div>
            </div>

            {
              <input
                name="airtimephone"
                disabled={this.state.airtimephoneself}
                value={this.state.airtimephone}
                onChange={this.handleInput}
                type="number"
                maxLength="10"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Phone Number"
              />
            }

            <div className="text-danger small">
              {!this.state.airtimephone && this.state.validate && "Required."}
            </div>
            <div className="text-danger small">
              {this.state.airtimephone.length > 14 &&
                this.state.validate &&
                "Phone number is invalid."}
            </div>
            <div className="text-danger small">
              {this.state.airtimephone.length < 11 &&
                this.state.validate &&
                "Enter at least 11 digits."}
            </div>
          </div>
        </div>

        {this.state.activesubtab === "AirtimePurchase" && (
          <div className="form-group col-lg-12 date-range px-0">
            <label htmlFor="exampleFormControlTextarea1">Amount</label>
            <input
              name="paymentamount"
              value={this.state.paymentamount}
              onChange={this.handleInput}
              type="number"
              min={1}
              max={1000000000}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Amount"
            />
            <div className="text-danger small">
              {!this.state.paymentamount && this.state.validate && "Required."}
            </div>
          </div>
        )}

        <div className="form-group date-range mt-3">
          <label htmlFor="exampleFormControlTextarea1">Narrative</label>
          <textarea
            name="narrative"
            value={this.state.narrative}
            onChange={this.handleInput}
            className="form-control"
            id="exampleFormControlTextarea1"
            rows="4"
          ></textarea>
          <div className="text-danger small">
            {!this.state.narrative && this.state.validate && "Required."}
          </div>
        </div>

        <div className="text-danger small font-weight-bold">
          {!this.state.ajaxcalled &&
            this.state.ajaxerror &&
            this.state.ajaxerror}
        </div>

        <div className="text-right">
          <button
            type="button"
            disabled={this.state.ajaxcalled}
            className="chat-btn bg-success"
            style={
              this.state.ajaxcalled
                ? { left: "67%", position: "static", padding: "1px 12px" }
                : { left: "67%", position: "static" }
            }
            onClick={this.confirmAirtimePayment}
          >
            {this.state.ajaxcalled ? (
              <utilities.Gear style={{}} />
            ) : (
              <svg
                width="2.5em"
                height="2.5em"
                style={{ marginLeft: "-11px", marginTop: "-5px" }}
                viewBox="0 0 16 16"
                className="bi bi-arrow-right-short"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                />
              </svg>
            )}
          </button>
        </div>
      </div>
    );
  };

  QuickTellerPaymentsPage = () => {
    return (
      <div className="animated fadeIn delay-0.5s">
        <div>
          <a className="gen-text-color cp" onClick={this.handleBackButton}>
            <svg
              width="2em"
              height="2em"
              viewBox="0 0 16 16"
              className="bi bi-arrow-left-short"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
              />
            </svg>
            <span className="vam font-weight-bold">Back</span>
          </a>
        </div>

        <div className="gen-text-color font-weight-bold mb-3">
          <h2 className="mb-4 font-weight-bold">Quickteller payments</h2>
        </div>

        <div className="success-color mb-4">
          <p className="mb-1 pb-2 small font-weight-bold">
            Select debit account
          </p>
          <hr className="h-rule" />
        </div>

        {this.state.listofbankaccounts.length && (
          <div className="row">
            {this.state.listofbankaccounts.map((account, idx) => {
              return (
                <div
                  className={"col-lg-4 cp"}
                  key={account.accountNo}
                  onClick={() => {
                    this.setDebitAccount(account.accountNo);
                  }}
                >
                  <label htmlFor="zoomCheck">
                    <div
                      className={
                        this.state.sourceaccount === account.accountNo
                          ? this.determineCardColor(idx) + " zoom-card"
                          : this.determineCardColor(idx)
                      }
                    >
                      <input type="checkbox" className="d-none" />

                      <div className="text-right">
                        <span className="badge badge-pill sec-color text-white p-2">
                          {account.accountType}
                        </span>
                      </div>

                      <div className="small nearwhite">{account.accountNo}</div>

                      <div>
                        <h3 className="font-weight-bold text-white">
                          <utilities.NairaSympbol />
                          {numeral(account.availableBalance).format("0,0.00")}
                        </h3>
                      </div>

                      <p className="padding2 mb-0">&nbsp;</p>
                    </div>
                  </label>
                </div>
              );
            })}
          </div>
        )}

        {!this.state.listofbankaccounts.length && (
          <div className={"col-lg-4 cp"}>
            <label htmlFor="zoomCheck">
              <div className="payment-account1">
                <input type="checkbox" className="d-none" />

                <div className="text-right">
                  <span className="badge badge-pill sec-color text-white p-2">
                    N/A
                  </span>
                </div>

                <div className="small nearwhite">
                  <h4>Bank account not available.</h4>
                </div>

                <div>
                  <h3 className="font-weight-bold text-white">N/A</h3>
                </div>
              </div>
            </label>
          </div>
        )}

        <div className="text-danger small">
          {!this.state.sourceaccount &&
            this.state.validate &&
            "Select a source account."}
        </div>

        <p className="padding2 mt-2">&nbsp;</p>

        <div className="form-row date-range">
          <div className="form-group col-lg-6">
            <label htmlFor="exampleFormControlTextarea1">Category</label>
            <select
              name="categoryid"
              value={this.state.categoryid}
              onChange={this.fetchCategoryBillers}
              className="custom-select custommenu"
              id="exampleFormControlSelect1"
            >
              <option value="" disabled={true}>
                Select Category
              </option>

              {this.state.listofbillercategories.map((cat) => {
                return (
                  <option key={cat.id} value={cat.id}>
                    {cat.name}
                  </option>
                );
              })}
            </select>
            <div className="text-danger small">
              {!this.state.categoryid && this.state.validate && "Required."}
            </div>
          </div>

          <div className="form-group col-lg-6">
            <label htmlFor="exampleFormControlTextarea1">Biller</label>
            <select
              disabled={!this.state.listofcategorybillers.length}
              name="billerid"
              value={this.state.billerid}
              onChange={this.fetchServiceOptions}
              className="custom-select custommenu"
              id="exampleFormControlSelect1"
            >
              <option value="" disabled={true}>
                Select Biller
              </option>

              {this.state.listofcategorybillers.map((biller) => {
                return (
                  <option key={biller.biller_id} value={biller.biller_id}>
                    {biller.biller_name}
                  </option>
                );
              })}
            </select>
            <div className="text-danger small">
              {!this.state.billerid && this.state.validate && "Required."}
            </div>
          </div>
        </div>

        <div className="form-row date-range">
          <div className="form-group col-lg-12">
            <label htmlFor="exampleFormControlTextarea1">Product</label>
            <select
              disabled={!this.state.listofserviceoptions.length}
              name="serviceid"
              value={this.state.serviceid}
              onChange={this.fetchRequiredFields}
              className="custom-select custommenu"
              id="exampleFormControlSelect1"
            >
              <option value="" disabled={true}>
                Product
              </option>

              {this.state.listofserviceoptions.map((option) => {
                return (
                  <option key={option.service_id} value={option.service_id}>
                    {option.description +
                      " " +
                      (Number(option.amount) > 0
                        ? "(₦" + option.amount + ")"
                        : "")}
                  </option>
                );
              })}
            </select>
            <div className="text-danger small">
              {!this.state.serviceid && this.state.validate && "Required."}
            </div>
          </div>
        </div>

        <div className="success-color mb-4">
          <p className="mb-1 pb-2 small font-weight-bold">Beneficiary</p>
          <hr className="h-rule" />
        </div>

        <div className="form-row date-range">
          <div className="form-group col-lg-6">
            <label htmlFor="exampleFormControlTextarea1">
              {this.state.requiredfields.name || "Select product"}
            </label>

            <input
              name="requiredfieldvalue"
              value={this.state.requiredfieldvalue}
              onChange={this.handleInput}
              type="number"
              min={1}
              max={1000000000}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Value"
            />
            <div className="text-danger small">
              {!this.state.requiredfieldvalue &&
                this.state.validate &&
                "Required."}
            </div>
          </div>

          <div className="form-group col-lg-6">
            <label htmlFor="exampleFormControlTextarea1">Email Address</label>

            <input
              name="email"
              value={this.state.email}
              onChange={this.handleInput}
              type="text"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Email"
            />
            <div className="text-danger small">
              {!this.state.email && this.state.validate && "Required."}
            </div>
          </div>
        </div>

        <div className="form-row date-range">
          <div className="form-group col-lg-6">
            <label htmlFor="exampleFormControlTextarea1">Phone Number</label>

            <input
              name="phone"
              value={this.state.phone}
              onChange={this.handleInput}
              type="text"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Phone"
            />
            <div className="text-danger small">
              {!this.state.phone && this.state.validate && "Required."}
            </div>
            <div className="text-danger small">
              {this.state.phone.length > 14 &&
                this.state.validate &&
                "Phone number is invalid."}
            </div>
            <div className="text-danger small">
              {this.state.phone.length < 11 &&
                this.state.validate &&
                "Enter at least 11 digits."}
            </div>
          </div>

          <div className="form-group col-lg-6">
            <label htmlFor="exampleFormControlTextarea1">Amount</label>
            <input
              name="paymentamount"
              value={this.state.paymentamount}
              onChange={this.handleInput}
              type="number"
              min={1}
              max={1000000000}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Amount"
            />
            <div className="text-danger small">
              {!this.state.paymentamount && this.state.validate && "Required."}
            </div>
          </div>
        </div>

        <div className="form-group date-range mt-3">
          <label htmlFor="exampleFormControlTextarea1">Narrative</label>
          <textarea
            name="narrative"
            value={this.state.narrative}
            onChange={this.handleInput}
            className="form-control"
            id="exampleFormControlTextarea1"
            rows="4"
          ></textarea>
          <div className="text-danger small">
            {!this.state.narrative && this.state.validate && "Required."}
          </div>
        </div>

        <div className="text-danger small font-weight-bold">
          {!this.state.ajaxcalled &&
            this.state.ajaxerror &&
            this.state.ajaxerror}
        </div>

        <div className="text-right">
          <button
            type="button"
            disabled={!this.state.listofserviceoptions.length}
            onClick={this.confirmBillingForm}
            className="chat-btn bg-success"
            style={
              this.state.ajaxcalled
                ? {
                    left: "67%",
                    background: "#29C186",
                    position: "static",
                    padding: "1px 12px",
                  }
                : { left: "67%", background: "#29C186", position: "static" }
            }
          >
            {this.state.ajaxcalled ? (
              <utilities.Gear style={{}} />
            ) : (
              <svg
                width="2.5em"
                height="2.5em"
                style={{ marginLeft: "-11px", marginTop: "-5px" }}
                viewBox="0 0 16 16"
                className="bi bi-arrow-right-short"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                />
              </svg>
            )}
          </button>
        </div>
      </div>
    );
  };

  PaymentsPage = () => {
    return (
      <div className="animated fadeIn delay-0.5s">
        <div className="gen-text-color font-weight-bold mb-5">
          <h2 className="mb-4 font-weight-bold">Payments</h2>
          <p className="small font-weight-bold">
            Make inter / intra and own account transfers. Also pay bills &
            purchase airtime.
          </p>
        </div>

        <div className="success-color mb-4">
          <p className="mb-1 pb-2 small font-weight-bold">Cash Transfers</p>
          <hr className="h-rule" />
        </div>

        <div className="row mb-4 signup-cards">
          <div
            className="col-lg account-card p-4 bvn-card cp"
            onClick={() => {
              this.checkPinSetUpStatus("Payments", "InterbankTransfer");
            }}
          >
            <div className="box-part text-left">
              <img
                alt="Account Number Icon"
                src="/assets/imgs/acct-no.png"
                className="w-18"
              />

              <div className="title mt-2">
                <p className="font-weight-bold">Interbank</p>
              </div>

              <div className="text">
                <span className="small">Send Money to other banks.</span>
              </div>
            </div>
          </div>

          <div
            className="col-lg account-card p-4 bvn-card cp"
            onClick={() => {
              this.checkPinSetUpStatus("Payments", "IntrabankTransfer");
            }}
          >
            <div className="box-part text-left">
              <svg
                width="1.5em"
                height="1.5em"
                viewBox="0 0 16 16"
                className="bi bi-arrow-left-right st-icons"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"
                />
              </svg>

              <div className="title mt-2">
                <p className="font-weight-bold">Intra Bank</p>
              </div>

              <div className="text">
                <span className="small">Send to NPF MFB accounts</span>
              </div>
            </div>
          </div>

          <div
            className="col-lg account-card p-4 cp"
            onClick={this.startOwnAccountsTransfer}
          >
            {/* <div className="col-lg account-card p-4 cp"data-toggle="tooltip" data-placement="top" title="Coming soon."> */}

            <div className="box-part text-left">
              <svg
                width="2em"
                height="2em"
                viewBox="0 0 16 16"
                className="bi bi-person"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"
                />
              </svg>

              <div className="title mt-2">
                <p className="font-weight-bold">Own Accounts</p>
              </div>

              <div className="text">
                <span className="small">Send between own accounts</span>
              </div>
            </div>
          </div>
        </div>

        <div className="success-color mb-4 mt-5">
          <p className="mb-1 pb-2 small font-weight-bold">Other Payments</p>
          <hr className="h-rule" />
        </div>

        {/* onClick={() => {this.checkPinSetUpStatus("Payments", "AirtimePurchase")}} */}

        <div className="row mb-4 signup-cards">
          <div
            className="col-lg account-card p-4 bvn-card cp"
            onClick={() => {
              this.checkPinSetUpStatus("Payments", "AirtimePurchase");
            }}
          >
            {/* <div className="col-lg account-card p-4 bvn-card cp" data-toggle="tooltip" data-placement="top" title="Coming soon."> */}

            <div className="box-part text-left">
              <svg
                width="2em"
                height="2em"
                viewBox="0 0 16 16"
                className="bi bi-tablet"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M12 1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z"
                />
                <path
                  fillRule="evenodd"
                  d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                />
              </svg>

              <div className="title mt-2">
                <p className="font-weight-bold">Airtime purchase</p>
              </div>

              <div className="text">
                <span className="small">Top up your airtime balance.</span>
              </div>
            </div>
          </div>

          {/* onClick={() => {this.checkPinSetUpStatus("Payments", "DataPurchase")}} */}

          <div
            className="col-lg account-card p-4 bvn-card cp"
            onClick={() => {
              this.checkPinSetUpStatus("Payments", "DataPurchase");
            }}
          >
            {/* <div className="col-lg account-card p-4 bvn-card cp" data-toggle="tooltip" data-placement="top" title="Coming soon."> */}

            <div className="box-part text-left">
              <svg
                width="2em"
                height="2em"
                viewBox="0 0 16 16"
                className="bi bi-tablet"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M12 1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z"
                />
                <path
                  fillRule="evenodd"
                  d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                />
              </svg>

              <div className="title mt-2">
                <p className="font-weight-bold">Data purchase</p>
              </div>

              <div className="text">
                <span className="small">Top up your data balance.</span>
              </div>
            </div>
          </div>

          {/* onClick={() => {this.checkPinSetUpStatus("Payments", "QuickTellerPayments")}} */}

          <div
            className="col-lg account-card p-4 cp"
            onClick={() => {
              this.checkPinSetUpStatus("Payments", "QuickTellerPayments");
            }}
          >
            <div className="box-part text-left">
              <svg
                width="2em"
                height="2em"
                viewBox="0 0 16 16"
                className="bi bi-cash"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M15 4H1v8h14V4zM1 3a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H1z"
                />
                <path d="M13 4a2 2 0 0 0 2 2V4h-2zM3 4a2 2 0 0 1-2 2V4h2zm10 8a2 2 0 0 1 2-2v2h-2zM3 12a2 2 0 0 0-2-2v2h2zm7-4a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
              </svg>

              <div className="title mt-2">
                <p className="font-weight-bold">Pay bills</p>
              </div>

              <div className="text">
                <span className="small">Send money to other banks</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  SettingsPage = () => {
    return (
      <div className="animated fadeIn delay-0.5s">
        <div className="gen-text-color font-weight-bold mb-5">
          <h2 className="mb-4 font-weight-bold">Settings</h2>
        </div>

        <div className="row mb-4 signup-cards">
          {!store.get("user").has_pin && (
            <div
              className="col-lg account-card p-4 bvn-card cp"
              onClick={() => {
                this.switchSubTab("Settings", "SetUpPin");
              }}
            >
              <div className="box-part text-left">
                <svg
                  width="2em"
                  height="2em"
                  viewBox="0 0 16 16"
                  className="bi bi-lock"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.5 8h-7a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1zm-7-1a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-7zm0-3a3.5 3.5 0 1 1 7 0v3h-1V4a2.5 2.5 0 0 0-5 0v3h-1V4z"
                  />
                </svg>

                <div className="title mt-2">
                  <p className="font-weight-bold">Setup Transaction Pin</p>
                </div>

                {/* <div className="text">
                                <span className="small">Send Money to other banks.</span>
                            </div> */}
              </div>
            </div>
          )}

          {store.get("user").has_pin && (
            <div
              className="col-lg account-card p-4 bvn-card cp"
              onClick={() => {
                this.switchSubTab("Settings", "ChangePin");
              }}
            >
              <div className="box-part text-left">
                <svg
                  width="2em"
                  height="2em"
                  viewBox="0 0 16 16"
                  className="bi bi-unlock"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.655 8H2.333c-.264 0-.398.068-.471.121a.73.73 0 0 0-.224.296 1.626 1.626 0 0 0-.138.59V14c0 .342.076.531.14.635.064.106.151.18.256.237a1.122 1.122 0 0 0 .436.127l.013.001h7.322c.264 0 .398-.068.471-.121a.73.73 0 0 0 .224-.296 1.627 1.627 0 0 0 .138-.59V9c0-.342-.076-.531-.14-.635a.658.658 0 0 0-.255-.237A1.122 1.122 0 0 0 9.655 8zm.012-1H2.333C.5 7 .5 9 .5 9v5c0 2 1.833 2 1.833 2h7.334c1.833 0 1.833-2 1.833-2V9c0-2-1.833-2-1.833-2zM8.5 4a3.5 3.5 0 1 1 7 0v3h-1V4a2.5 2.5 0 0 0-5 0v3h-1V4z"
                  />
                </svg>

                <div className="title mt-2">
                  <p className="font-weight-bold">Change Transaction Pin</p>
                </div>

                {/* <div className="text">
                                    <span className="small">Send to NPF MFB accounts</span>
                                </div> */}
              </div>
            </div>
          )}

          <div
            className="col-lg account-card p-4 cp"
            onClick={this.toResetPassword}
          >
            <div className="box-part text-left">
              <svg
                width="2em"
                height="2em"
                viewBox="0 0 16 16"
                className="bi bi-shield-lock"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M5.443 1.991a60.17 60.17 0 0 0-2.725.802.454.454 0 0 0-.315.366C1.87 7.056 3.1 9.9 4.567 11.773c.736.94 1.533 1.636 2.197 2.093.333.228.626.394.857.5.116.053.21.089.282.11A.73.73 0 0 0 8 14.5c.007-.001.038-.005.097-.023.072-.022.166-.058.282-.111.23-.106.525-.272.857-.5a10.197 10.197 0 0 0 2.197-2.093C12.9 9.9 14.13 7.056 13.597 3.159a.454.454 0 0 0-.315-.366c-.626-.2-1.682-.526-2.725-.802C9.491 1.71 8.51 1.5 8 1.5c-.51 0-1.49.21-2.557.491zm-.256-.966C6.23.749 7.337.5 8 .5c.662 0 1.77.249 2.813.525a61.09 61.09 0 0 1 2.772.815c.528.168.926.623 1.003 1.184.573 4.197-.756 7.307-2.367 9.365a11.191 11.191 0 0 1-2.418 2.3 6.942 6.942 0 0 1-1.007.586c-.27.124-.558.225-.796.225s-.526-.101-.796-.225a6.908 6.908 0 0 1-1.007-.586 11.192 11.192 0 0 1-2.417-2.3C2.167 10.331.839 7.221 1.412 3.024A1.454 1.454 0 0 1 2.415 1.84a61.11 61.11 0 0 1 2.772-.815z"
                />
                <path d="M9.5 6.5a1.5 1.5 0 0 1-1 1.415l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99a1.5 1.5 0 1 1 2-1.415z" />
              </svg>

              <div className="title mt-2">
                <p className="font-weight-bold">Reset Password</p>
              </div>

              {/* <div className="text">
                                <span className="small">Send between own accounts</span>
                            </div> */}
            </div>
          </div>
        </div>

        <div
          className="row mb-4 signup-cards cp"
          onClick={() => {
            this.switchTab("ComingSoon");
          }}
        >
          {/* <div className="row mb-4 signup-cards" data-toggle="tooltip" data-placement="top" title="Coming soon."> */}

          <div
            className="col-lg account-card p-4 cp"
            onClick={() => {
              this.switchSubTab("Support", "SupportBranch");
            }}
          >
            <div className="box-part text-left">
              <svg
                width="2em"
                height="2em"
                viewBox="0 0 16 16"
                className="bi bi-toggle-on"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5zm6 9a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"
                />
              </svg>

              <div className="title mt-2">
                <p className="font-weight-bold">In-app Notifications</p>
              </div>

              {/* <div className="text">
                                <span className="small">Send money to other banks</span>
                            </div> */}
            </div>
          </div>

          <div className="col-lg bvn-card p-4"></div>
        </div>
      </div>
    );
  };

  ChangePin = () => {
    return (
      <div className="auth-guy">
        <div>
          <a className="gen-text-color cp" onClick={this.handleBackButton}>
            <svg
              width="2em"
              height="2em"
              viewBox="0 0 16 16"
              className="bi bi-arrow-left-short"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
              />
            </svg>
            <span className="vam font-weight-bold">Back</span>
          </a>
        </div>

        {/* <div className="web-space">
                    <p className="padding2">&nbsp;</p>
                    <p className={this.state.requeststatus === "error" ? "font-weight-bold text-danger padding2" : "font-weight-bold text-success padding2"}>{this.state.requestcaller === "pinchange" ? this.state.requestmessage : ""}&nbsp;</p>
                </div> */}

        <div className="gen-text-color font-weight-bold mb-2">
          <h2 className="mb-4 font-weight-bold">Change transaction pin.</h2>
          <p className="small font-weight-bold">Enter existing 4 digit pin.</p>
        </div>

        <div id="code-form" className="code-form">
          <input
            onChange={this.handleInput}
            id="expin1"
            type="password"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            onChange={this.handleInput}
            id="expin2"
            type="password"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            onChange={this.handleInput}
            id="expin3"
            type="password"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            onChange={this.handleInput}
            id="expin4"
            type="password"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
        </div>

        <div className="text-danger small mb-4">
          {!this.state.existingpin && this.state.validate && "Required"}
        </div>

        <div className="gen-text-color font-weight-bold mb-2">
          <p className="small font-weight-bold">Enter new 4 digit pin.</p>
        </div>

        <div id="code-form2" className="code-form stubborn-form">
          <input
            onChange={this.handleInput}
            id="npin1"
            type="password"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            onChange={this.handleInput}
            id="npin2"
            type="password"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            onChange={this.handleInput}
            id="npin3"
            type="password"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            onChange={this.handleInput}
            id="npin4"
            type="password"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
        </div>

        <div className="text-danger small mb-4">
          {!this.state.newpin && this.state.validate && "Required"}
        </div>

        <div className="gen-text-color font-weight-bold">
          <p className="small font-weight-bold">Confirm Pin</p>
        </div>

        <div id="code-form1" className="code-form">
          <input
            onChange={this.handleInput}
            id="confirmPin1"
            name="confirmPin1"
            type="password"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            onChange={this.handleInput}
            id="confirmPin2"
            name="confirmPin2"
            type="password"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            onChange={this.handleInput}
            id="confirmPin3"
            name="confirmPin3"
            type="password"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            onChange={this.handleInput}
            id="confirmPin4"
            name="confirmPin4"
            type="password"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
        </div>

        <div className="text-danger small mb-4">
          {this.state.validate &&
            !this.state.doesPinMatch &&
            "PIN does not match"}
        </div>

        <button
          disabled={this.state.ajaxcalled}
          className="login-btn small mt-4"
          type="button"
          onClick={this.changeTransactionPin}
        >
          <span className="w-85 font-weight-bold ml-50">Continue</span>

          <span className="w-15 h-100 slant-arrow">
            {this.state.ajaxcalled ? (
              <utilities.Gear style={{ width: "26px", marginTop: "9px" }} />
            ) : (
              <svg
                width="1.5em"
                height="100%"
                viewBox="0 0 16 16"
                className="bi bi-arrow-right-circle"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                />
                <path
                  fillRule="evenodd"
                  d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"
                />
              </svg>
            )}
          </span>
        </button>

        {/* <div className="text-danger text-center font-weight-bold mt-4">Cancel</div> */}
      </div>
    );
  };

  SetUpPin = () => {
    return (
      <div className="auth-guy">
        <div>
          <a className="gen-text-color cp" onClick={this.handleBackButton}>
            <svg
              width="2em"
              height="2em"
              viewBox="0 0 16 16"
              className="bi bi-arrow-left-short"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
              />
            </svg>
            <span className="vam font-weight-bold">Back</span>
          </a>
        </div>

        {/* <div className="web-space">
                    <p className="padding2">&nbsp;</p>
                    <p className={this.state.requeststatus === "error" ? "font-weight-bold text-danger padding2" : "font-weight-bold text-success padding2"}>{this.state.requestcaller === "pinsetup" ? this.state.requestmessage : ""}&nbsp;</p>
                </div> */}

        <div className="gen-text-color font-weight-bold mb-5">
          <h2 className="mb-4 font-weight-bold">Set up transaction pin.</h2>
          <p className="small font-weight-bold">Choose a 4 digit pin.</p>
        </div>

        <div id="code-form1" className="code-form">
          <input
            onChange={this.handleInput}
            id="pin1"
            type="text"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            onChange={this.handleInput}
            id="pin2"
            type="text"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            onChange={this.handleInput}
            id="pin3"
            type="text"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            onChange={this.handleInput}
            id="pin4"
            type="text"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
        </div>

        <div className="text-danger small mb-4">
          {!this.state.pin && this.state.validate && "Required"}
        </div>

        <div className="gen-text-color font-weight-bold mb-5">
          <p className="small font-weight-bold">Confirm Pin.</p>
        </div>

        <div id="code-form1" className="code-form">
          <input
            onChange={this.handleInput}
            id="confirmPin1"
            name="confirmPin1"
            type="text"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            onChange={this.handleInput}
            id="confirmPin2"
            name="confirmPin2"
            type="text"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            onChange={this.handleInput}
            id="confirmPin3"
            name="confirmPin3"
            type="text"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            onChange={this.handleInput}
            id="confirmPin4"
            name="confirmPin4"
            type="text"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
        </div>

        <div className="text-danger small mb-4">
          {this.state.validate &&
            !this.state.doesPinMatch &&
            "PIN does not match"}
        </div>

        <button
          disabled={this.state.ajaxcalled}
          className="login-btn small mt-4"
          type="button"
          onClick={this.setUpTransactionPin}
        >
          <span className="w-85 font-weight-bold ml-50">Continue</span>

          <span className="w-15 h-100 slant-arrow">
            {this.state.ajaxcalled ? (
              <utilities.Gear style={{ width: "26px", marginTop: "9px" }} />
            ) : (
              <svg
                width="1.5em"
                height="100%"
                viewBox="0 0 16 16"
                className="bi bi-arrow-right-circle"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                />
                <path
                  fillRule="evenodd"
                  d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"
                />
              </svg>
            )}
          </span>
        </button>

        {/* <div className="text-danger text-center font-weight-bold mt-4">Cancel</div> */}
      </div>
    );
  };

  AnalyticsPage = () => {
    return (
      <div className="animated fadeIn delay-0.5s">
        <div className="gen-text-color font-weight-bold mb-5">
          <h2 className="mb-4 font-weight-bold">Analytics</h2>
          <p className="small font-weight-bold">
            Make inter / intra and own account transfers. Also pay bills &
            purchase airtime.
          </p>
        </div>

        <div className="success-color mb-4">
          <p className="mb-1 pb-2 small font-weight-bold">Select account</p>
          <hr className="h-rule" />
        </div>

        <div className="row">
          <div
            className="col-lg cp"
            onClick={() => {
              this.setDebitAccount("allaccounts");
            }}
          >
            <label htmlFor="zoomCheck">
              <div
                className={
                  this.state.sourceaccount === "allaccounts"
                    ? "cp a-account1 h147 zoom-card"
                    : "cp a-account1 h147"
                }
              >
                <input type="checkbox" className="d-none" />
                <div className="text-center">
                  <h4 className="font-weight-bold text-white">All accounts</h4>
                </div>
              </div>
            </label>
          </div>

          {this.state.listofbankaccounts.slice(0, 2).map((account) => {
            return (
              <div
                className="col-lg mobile-bottom-space cp"
                key={account.accountNo}
                onClick={() => {
                  this.setDebitAccount(account.accountNo);
                }}
              >
                <label htmlFor="zoomCheck">
                  <div
                    className={
                      this.state.sourceaccount === account.accountNo
                        ? "cp payment-account2 zoom-card"
                        : "payment-account2 cp"
                    }
                  >
                    <input type="checkbox" className="d-none" />

                    <div className="text-right">
                      <span className="badge badge-pill sec-color p-2 text-white">
                        {account.accountType}
                      </span>
                    </div>

                    <div className="small nearwhite">{account.accountNo}</div>

                    <div>
                      <h3 className="font-weight-bold text-white">
                        <utilities.NairaSympbol />
                        {numeral(account.availableBalance).format("0,0.00")}
                      </h3>
                    </div>

                    <p className="padding2 mb-0">&nbsp;</p>
                  </div>
                </label>
              </div>
            );
          })}

          <div className="col-lg mobile-bottom-space"></div>
        </div>

        <p className="padding2 mt-2">&nbsp;</p>

        <form>
          <p className="small gen-text-color font-weight-bold">Sort by date</p>

          <div className="form-row">
            <div className="col-lg input-group mb-2">
              <div className="input-group-prepend">
                <span
                  className="input-group-text prepend-fix date-input-group gen-text-color font-weight-bold small"
                  id="inputGroup-sizing-default"
                >
                  From:
                </span>
              </div>

              <div>
                <DatePicker
                  selected={this.state.analyticsdatefrom}
                  value={this.state.analyticsdatefrom}
                  onChange={(date) =>
                    this.filterTransactions("analyticsdatefrom", date)
                  }
                  name="analyticsdatefrom"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD / MM / YYYY"
                  className="dateofbirth form-control w-100 rounded-0"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
            </div>

            <div className="col-lg input-group">
              <div className="input-group-prepend">
                <span
                  className="input-group-text prepend-fix date-input-group gen-text-color font-weight-bold small"
                  id="inputGroup-sizing-default"
                >
                  To:
                </span>
              </div>

              <div>
                <DatePicker
                  selected={this.state.analyticsdateto}
                  value={this.state.analyticsdateto}
                  onChange={(date) =>
                    this.filterTransactions("analyticsdateto", date)
                  }
                  name="analyticsdateto"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD / MM / YYYY"
                  className="dateofbirth form-control w-100 rounded-0"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
            </div>
          </div>
        </form>

        <p className="padding2 mt-2">&nbsp;</p>

        <div>
          <p className="gen-text-color font-weight-bold mb-1">Inflow</p>
        </div>
        <div className="progress">
          <div
            className="progress-bar inflow-bar"
            role="progressbar"
            aria-valuenow={this.state.creditpercentage}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: this.state.creditpercentage }}
          ></div>
          <span className="sr-only">
            {this.state.creditpercentage}% Complete
          </span>
        </div>
        <div
          style={{ width: this.state.creditpercentage || 0 }}
          className="text-right font-weight-bold mt-2 inflow-color"
        >
          <utilities.NairaSympbol />
          {numeral(this.state.credit).format("0,0.00")}
        </div>

        <p className="padding2">&nbsp;</p>

        <div>
          <p className="gen-text-color font-weight-bold mb-1">Outflow</p>
        </div>
        <div className="progress">
          <div
            className="progress-bar outflow-bar"
            role="progressbar"
            aria-valuenow={this.state.debitpercentage}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: this.state.debitpercentage }}
          ></div>
          <span className="sr-only">{this.state.debit}% Complete</span>
        </div>
        <div
          style={{ width: this.state.debitpercentage || 0 }}
          className="text-right font-weight-bold mt-2 outflow-color"
        >
          <utilities.NairaSympbol />
          {numeral(this.state.debit).format("0,0.00")}
        </div>
      </div>
    );
  };

  AllTransactions = () => {
    return (
      <div className="animated fadeIn delay-0.5s">
        {/* <div className="mb-3">
                    <a className="gen-text-color cp" onClick={this.handleBackButton}>
                        <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-arrow-left-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                        </svg>
                        <span className="vam font-weight-bold">Back</span>
                    </a>
                </div> */}

        <div className="gen-text-color font-weight-bold mb-5">
          <h2 className="mb-4 font-weight-bold">All Transactions</h2>
        </div>

        <div className="success-color mb-4">
          <p className="mb-1 pb-2 small font-weight-bold">Select account</p>
          <hr className="h-rule" />
        </div>

        <div className="row">
          {/* <div className="col-lg cp" onClick={() => {this.setDebitAccount("allaccounts")}}>
                        
                        <label htmlFor="zoomCheck">
                            <div className={this.state.sourceaccount === "allaccounts" ? "cp a-account1 h147 zoom-card" : "cp a-account1 h147"}>
                                <input type="checkbox" className="d-none" />
                                <div className="text-center">
                                    <h4 className="font-weight-bold text-white">All accounts</h4>
                                </div>
                            </div>
                        </label>
                    </div> */}

          {this.state.listofbankaccounts.map((account) => {
            return (
              <div
                className="col-lg-3 mobile-bottom-space cp"
                key={account.accountNo}
                onClick={() => {
                  this.setDebitAccount(account.accountNo);
                }}
              >
                <label htmlFor="zoomCheck">
                  <div
                    className={
                      this.state.sourceaccount === account.accountNo
                        ? "cp payment-account2 zoom-card"
                        : "payment-account2 cp"
                    }
                  >
                    <input type="checkbox" className="d-none" />

                    <div className="text-right">
                      <span className="badge badge-pill sec-color p-2 text-white">
                        {account.accountType}
                      </span>
                    </div>

                    <div className="small nearwhite">{account.accountNo}</div>

                    <div>
                      <h3 className="font-weight-bold text-white">
                        <utilities.NairaSympbol />
                        {numeral(account.availableBalance).format("0,0.00")}
                      </h3>
                    </div>

                    <p className="padding2 mb-0">&nbsp;</p>
                  </div>
                </label>
              </div>
            );
          })}

          <div className="col-lg mobile-bottom-space"></div>
        </div>

        <p className="padding2 mt-2">&nbsp;</p>

        <form>
          <p className="small gen-text-color font-weight-bold">Sort by date</p>

          <div className="form-row">
            <div className="col-lg-4 input-group mb-2">
              <div className="input-group-prepend">
                <span
                  className="input-group-text prepend-fix date-input-group gen-text-color font-weight-bold small"
                  id="inputGroup-sizing-default"
                >
                  From:
                </span>
              </div>

              <div>
                <DatePicker
                  selected={this.state.analyticsdatefrom}
                  value={this.state.analyticsdatefrom}
                  onChange={(date) =>
                    this.filterTransactions("analyticsdatefrom", date)
                  }
                  name="analyticsdatefrom"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD / MM / YYYY"
                  className="dateofbirth form-control w-100 rounded-0"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
            </div>

            <div className="col-lg-4 input-group">
              <div className="input-group-prepend">
                <span
                  className="input-group-text prepend-fix date-input-group gen-text-color font-weight-bold small"
                  id="inputGroup-sizing-default"
                >
                  To:
                </span>
              </div>

              <div>
                <DatePicker
                  selected={this.state.analyticsdateto}
                  value={this.state.analyticsdateto}
                  onChange={(date) =>
                    this.filterTransactions("analyticsdateto", date)
                  }
                  name="analyticsdateto"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD / MM / YYYY"
                  className="dateofbirth form-control w-100 rounded-0"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  disabled={!this.state.analyticsdatefrom}
                />
              </div>
            </div>
          </div>
        </form>

        <p className="padding2 mt-2">&nbsp;</p>

        <div className="d-flex">
          <div
            className="col-lg-2 cp text-center"
            onClick={() => {
              this.setState({ trxntype: "all" });
            }}
          >
            <p
              className={
                this.state.trxntype === "all"
                  ? "gen-text-color font-weight-bold mb-1"
                  : "small text-muted font-weight-bold mb-1"
              }
            >
              All
            </p>
            {this.state.trxntype === "all" && (
              <hr className="m-0 acct-active" />
            )}
          </div>

          <div
            className="col-lg-2 cp text-center"
            onClick={() => {
              this.setState({ trxntype: "income" });
            }}
          >
            <p
              className={
                this.state.trxntype === "income"
                  ? "gen-text-color font-weight-bold mb-1 ml-2"
                  : "small text-muted font-weight-bold mb-1"
              }
            >
              Income
            </p>
            {this.state.trxntype === "income" && (
              <hr className="m-0 acct-active" />
            )}
          </div>

          <div
            className="col-lg-2 cp text-center"
            onClick={() => {
              this.setState({ trxntype: "expense" });
            }}
          >
            <p
              className={
                this.state.trxntype === "expense"
                  ? "gen-text-color font-weight-bold mb-1 ml-2"
                  : "small text-muted font-weight-bold mb-1"
              }
            >
              Expense
            </p>
            {this.state.trxntype === "expense" && (
              <hr className="m-0 acct-active" />
            )}
          </div>
        </div>

        <div className="w50">
          {this.state.trxntype === "all" &&
            this.state.listofalltransactions.map((trxn, idx) => {
              return (
                <div className="row p-3" key={idx}>
                  <div className="col mr-2 p-0 text-center">
                    {trxn.type === "Credit" && (
                      <svg
                        width="3em"
                        height="3em"
                        viewBox="0 0 16 16"
                        className="credit-icon bi bi-arrow-down-left-circle"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                        />
                        <path
                          fillRule="evenodd"
                          d="M10.828 5.172a.5.5 0 0 0-.707 0L6.025 9.268V6.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H9.5a.5.5 0 0 0 0-1H6.732l4.096-4.096a.5.5 0 0 0 0-.707z"
                        />
                      </svg>
                    )}

                    {trxn.type === "Debit" && (
                      <svg
                        width="3em"
                        height="3em"
                        viewBox="0 0 16 16"
                        className="debit-icon bi bi-arrow-up-right-circle"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                        />
                        <path
                          fillRule="evenodd"
                          d="M5.172 10.828a.5.5 0 0 0 .707 0l4.096-4.096V9.5a.5.5 0 1 0 1 0V5.525a.5.5 0 0 0-.5-.5H6.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707z"
                        />
                      </svg>
                    )}
                  </div>

                  <div className="col p-0 text-center">
                    {(trxn.type === "Debit" && (
                      <div className="gen-text-color font-weight-bold">
                        Withdrawal
                      </div>
                    )) ||
                      ""}
                    {(trxn.type === "Credit" && (
                      <div className="gen-text-color font-weight-bold">
                        Transfer Inwards
                      </div>
                    )) ||
                      ""}

                    <div className="muted-color small">
                      {trxn.description || ""}
                    </div>
                  </div>

                  <div
                    className={
                      trxn.type === "Debit"
                        ? "col text-right red-color font-weight-bold p-0 text-center"
                        : "col text-right greener-color font-weight-bold p-0 text-center"
                    }
                  >
                    {trxn.type === "Debit" && <span>-</span>}
                    {trxn.type === "Credit" && <span>+</span>}
                    <utilities.NairaSympbol />
                    {numeral(trxn.amount).format("0,0.00") || ""}
                  </div>
                </div>
              );
            })}

          {this.state.trxntype === "income" &&
            this.state.credittrxn.map((trxn, idx) => {
              return (
                <div className="row p-3" key={idx}>
                  <div className="col mr-2 p-0 text-center">
                    {trxn.type === "Credit" && (
                      <svg
                        width="3em"
                        height="3em"
                        viewBox="0 0 16 16"
                        className="credit-icon bi bi-arrow-down-left-circle"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                        />
                        <path
                          fillRule="evenodd"
                          d="M10.828 5.172a.5.5 0 0 0-.707 0L6.025 9.268V6.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H9.5a.5.5 0 0 0 0-1H6.732l4.096-4.096a.5.5 0 0 0 0-.707z"
                        />
                      </svg>
                    )}
                  </div>

                  <div className="col p-0 text-center">
                    {(trxn.type === "Credit" && (
                      <div className="gen-text-color font-weight-bold">
                        Transfer Inwards
                      </div>
                    )) ||
                      ""}

                    <div className="muted-color small">
                      {trxn.description || ""}
                    </div>
                  </div>

                  <div
                    className={
                      trxn.type === "Debit"
                        ? "col text-right red-color font-weight-bold p-0 text-center"
                        : "col text-right greener-color font-weight-bold p-0 text-center"
                    }
                  >
                    <span>+</span>
                    <utilities.NairaSympbol />
                    {numeral(trxn.amount).format("0,0.00") || ""}
                  </div>
                </div>
              );
            })}

          {this.state.trxntype === "expense" &&
            this.state.debittrxn.map((trxn, idx) => {
              return (
                <div className="row p-3" key={idx}>
                  <div className="col mr-2 p-0 text-center">
                    {trxn.type === "Debit" && (
                      <svg
                        width="3em"
                        height="3em"
                        viewBox="0 0 16 16"
                        className="debit-icon bi bi-arrow-up-right-circle"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                        />
                        <path
                          fillRule="evenodd"
                          d="M5.172 10.828a.5.5 0 0 0 .707 0l4.096-4.096V9.5a.5.5 0 1 0 1 0V5.525a.5.5 0 0 0-.5-.5H6.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707z"
                        />
                      </svg>
                    )}
                  </div>

                  <div className="col p-0 text-center">
                    {(trxn.type === "Debit" && (
                      <div className="gen-text-color font-weight-bold">
                        Withdrawal
                      </div>
                    )) ||
                      ""}

                    <div className="muted-color small">
                      {trxn.description || ""}
                    </div>
                  </div>

                  <div
                    className={
                      trxn.type === "Debit"
                        ? "col text-right red-color font-weight-bold p-0 text-center"
                        : "col text-right greener-color font-weight-bold p-0 text-center"
                    }
                  >
                    <span>-</span>
                    <utilities.NairaSympbol />
                    {numeral(trxn.amount).format("0,0.00") || ""}
                  </div>
                </div>
              );
            })}

          {/* Empty states */}
          {((this.state.trxntype === "all" &&
            this.state.listofalltransactions.length === 0) ||
            (this.state.trxntype === "income" &&
              this.state.credittrxn.length === 0) ||
            (this.state.trxntype === "expense" &&
              this.state.debittrxn.length === 0)) && (
            <div className="gen-text-color mt-5 text-center">
              <div style={{ fontSize: "200px" }}>
                <NothingFoundIcon />
              </div>
              <p>No Result found</p>
            </div>
          )}
        </div>
      </div>
    );
  };

  TransactionConfirmationModal = () => {
    return (
      <div
        style={{ width: "600px", height: "700px", top: "10px" }}
        id={
          this.state.showmodal &&
          this.state.targetmodal === "transactionconfirmationmodal"
            ? "showmodal"
            : ""
        }
        className="trxnmodal p-5 bvnverifiedmodal animated fadeIn delay-0.5s"
      >
        <div className="row pb-5">
          <div className="col">
            <h1 className="font-weight-bold gen-text-color">Review</h1>
          </div>

          <div className="col text-right">
            <a
              onClick={this.hideModal}
              className="ft20 gen-text-color cp closecontactus"
            >
              &times;
            </a>
          </div>
        </div>

        <div className="">
          <div className="row pb-5">
            <div className="col-lg font-weight-bold gen-text-color">
              Payment Type
            </div>

            <div className="col-lg font-weight-bold gen-text-color">
              {this.state.activesubtab === "IntrabankTransfer" &&
                "NPF MFB Interbank Transfer"}
              {this.state.activesubtab === "InterbankTransfer" &&
                "NPF MFB Intrabank Transfer"}
              {this.state.activesubtab === "AirtimePurchase" &&
                "Airtime Purchase"}
              {this.state.activesubtab === "DataPurchase" && "Data Purchase"}
              {this.state.activesubtab === "QuickTellerPayments" &&
                "Bills Payment"}
            </div>
          </div>

          <div className="row pb-5">
            <div className="col-lg font-weight-bold gen-text-color">From</div>

            <div className="col-lg">
              <div className="gen-text-color font-weight-bold ttc">
                {this.state.dashboardaccount.accountName &&
                this.state.dashboardaccount.accountName.split(" ").length > 1
                  ? utilities.toTitleCase(
                      this.state.dashboardaccount.accountName.split(" ")[0]
                    ) +
                    " " +
                    utilities.toTitleCase(
                      this.state.dashboardaccount.accountName.split(" ")[1]
                    )
                  : this.state.dashboardaccount.accountName}
              </div>
              <div className="gen-text-color small">
                {this.state.sourceaccount}
              </div>
            </div>
          </div>

          <div className="row pb-5">
            <div className="col-lg font-weight-bold gen-text-color">To</div>

            {this.state.activesubtab === "QuickTellerPayments" && (
              <div className="col-lg">
                <div className="gen-text-color font-weight-bold ttc">
                  {this.state.requiredfields.name}
                </div>
                <div className="gen-text-color small">
                  {this.state.requiredfieldvalue}
                </div>
              </div>
            )}

            {(this.state.activesubtab === "AirtimePurchase" ||
              this.state.activesubtab === "DataPurchase") && (
              <div className="col-lg">
                <div className="gen-text-color font-weight-bold ttc">
                  {this.state.airtimephone}
                </div>
                <div className="gen-text-color small">
                  {this.state["airtimeserviceidlabel"]}
                </div>
              </div>
            )}

            {(this.state.activesubtab === "IntrabankTransfer" ||
              this.state.activesubtab === "InterbankTransfer") && (
              <div className="col-lg">
                <div className="gen-text-color font-weight-bold ttc">
                  {this.state.destinationaccountname.split(" ").length > 1
                    ? utilities.toTitleCase(
                        this.state.destinationaccountname.split(" ")[0]
                      ) +
                      " " +
                      utilities.toTitleCase(
                        this.state.destinationaccountname.split(" ")[1]
                      )
                    : utilities.toTitleCase(this.state.destinationaccountname)}
                </div>
                <div className="gen-text-color small">
                  {this.state.destinationaccountnumber}
                </div>
              </div>
            )}
          </div>

          <div className="row pb-5">
            <div className="col-lg font-weight-bold gen-text-color">Amount</div>

            <div className="col-lg">
              <div className="gen-text-color font-weight-bold">
                <utilities.NairaSympbol />
                {numeral(this.state.paymentamount).format("0,0.00")}
              </div>
            </div>
          </div>

          <div className="row pb-5">
            <div className="col-lg font-weight-bold gen-text-color">
              Narrative
            </div>

            <div className="col-lg">
              <div className="gen-text-color font-weight-bold">
                {this.state.narrative}
              </div>
            </div>
          </div>

          <div className="form-row w50 ml-auto">
            <div className="col-9 lh4 font-weight-bold gen-text-color text-right pr-2">
              Confirm with pin
            </div>

            <button
              className="completed-btn bg-success"
              type="button"
              disabled={this.state.ajaxcalled}
              style={
                this.state.ajaxcalled
                  ? { padding: "16px 10px", left: "40%" }
                  : { left: "40%" }
              }
              onClick={() => {
                this.switchInnerTab("PinConfirmation");
              }}
            >
              {this.state.ajaxcalled ? (
                <utilities.Gear style={{ marginTop: "-5px" }} />
              ) : (
                <svg
                  width="2.5em"
                  height="2.5em"
                  style={{ marginLeft: "-11px", marginTop: "-5px" }}
                  viewBox="0 0 16 16"
                  className="bi bi-arrow-right-short"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                  />
                </svg>
              )}
            </button>
          </div>

          <div className="text-danger small font-weight-bold text-center mt-4">
            {!this.state.ajaxcalled &&
              this.state.ajaxerror &&
              this.state.ajaxerror}
          </div>
        </div>
      </div>
    );
  };

  TransactionSuccessfulModal = () => {
    return (
      <div
        style={{ width: "400px", height: "auto", top: "100px" }}
        id={
          this.state.showmodal &&
          this.state.targetmodal === "transactionsuccessfulmodal"
            ? "showmodal"
            : ""
        }
        className="trxnmodal p-4 bvnverifiedmodal animated fadeIn delay-0.5s"
      >
        <div className="row">
          <div className="col">
            <h1 className="font-weight-bold gen-text-color"></h1>
          </div>

          <div className="col text-right">
            <a
              onClick={this.goBackToPayments}
              className="ft20 gen-text-color cp closecontactus"
            >
              &times;
            </a>
          </div>
        </div>

        <div className="text-center">
          <div>
            <svg
              width="3em"
              height="3em"
              viewBox="0 0 16 16"
              className="success-color bi bi-check2-circle"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"
              />
              <path
                fillRule="evenodd"
                d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"
              />
            </svg>
          </div>
          <p className="padding2">&nbsp;</p>

          <div className="choice-menu">
            <div className="gen-text-color font-weight-bold mb-3">
              <h2 className="mb-2 font-weight-bold">Transaction Successful</h2>
              {(this.state.activesubtab === "IntrabankTransfer" ||
                this.state.activesubtab === "InterbankTransfer") && (
                <p className="small font-weight-bold">
                  The funds have been successfully sent to the recipient.
                </p>
              )}
              {this.state.activesubtab === "DataPurchase" && (
                <p className="small font-weight-bold">
                  Data was sent to the recipient.
                </p>
              )}
              {this.state.activesubtab === "AirtimePurchase" && (
                <p className="small font-weight-bold">
                  Airtime was sent to the recepient.
                </p>
              )}
              {this.state.activesubtab === "QuickTellerPayments" && (
                <p className="small font-weight-bold">
                  The bill subscription was sent to the recepient.
                </p>
              )}
            </div>
          </div>

          <div className="form-row">
            {(this.state.activesubtab === "IntrabankTransfer" ||
              this.state.activesubtab === "InterbankTransfer") && (
              <div
                className={`col ${Utilities.mobileCheck() ? "text-left" : ""}`}
              >
                <button
                  onClick={this.handleDownloadReceipt}
                  type="button"
                  className="btn btn-outline-secondary br-25 px-3 py-2"
                >
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className="bi bi-download mr-3"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"
                    />
                  </svg>
                  <span className="gen-text-color font-weight-bold">
                    Download Receipt
                  </span>
                </button>
              </div>
            )}

            {/* {utilities.mobileCheck() && <div className="col text-right mr-3">
                            <button
                                type="button"
                                className="btn btn-outline-secondary br-25"
                                onClick={e => this.handleShareReceipt("https://npf-mfb.s3.amazonaws.com/120/photo/image.jpg")}>
                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-share mr-3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"/>
                                </svg>
                                <span className="gen-text-color font-weight-bold">Share</span>
                            </button>  
                        </div>} */}
          </div>
        </div>
      </div>
    );
  };

  PinOperationModal = () => {
    return (
      <div
        style={{ width: "400px", height: "300px", top: "100px" }}
        id={
          this.state.showmodal && this.state.targetmodal === "pinoperationmodal"
            ? "showmodal"
            : ""
        }
        className="trxnmodal p-4 bvnverifiedmodal animated fadeIn delay-0.5s"
      >
        <div className="row">
          <div className="col">
            <h1 className="font-weight-bold gen-text-color"></h1>
          </div>

          <div className="col text-right">
            <a
              onClick={this.hideModal}
              className="ft20 gen-text-color cp closecontactus"
            >
              &times;
            </a>
          </div>
        </div>

        <div className="text-center">
          <div>
            <svg
              width="3em"
              height="3em"
              viewBox="0 0 16 16"
              className={
                this.state.requeststatus === "error"
                  ? "red-color bi bi-check2-circle"
                  : "success-color bi bi-check2-circle"
              }
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"
              />
              <path
                fillRule="evenodd"
                d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"
              />
            </svg>
          </div>
          <p className="padding2">&nbsp;</p>

          <div className="choice-menu">
            <div className="gen-text-color font-weight-bold mb-5">
              <h2
                className={
                  this.state.requeststatus === "error"
                    ? "text-danger mb-2 font-weight-bold"
                    : "text-success mb-2 font-weight-bold"
                }
              >
                {this.state.requestmessage} &nbsp;
              </h2>
            </div>
          </div>
        </div>
      </div>
    );
  };

  SupportMailsModal = () => {
    return (
      <div
        style={{
          width: "400px",
          height: "300px",
          top: "100px",
          overflowY: "scroll",
        }}
        id={
          this.state.showmodal && this.state.targetmodal === "supportmailsmodal"
            ? "showmodal"
            : ""
        }
        className="trxnmodal p-4 bvnverifiedmodal animated fadeIn delay-0.5s"
      >
        <div className="row">
          <div className="col">
            <h1 className="font-weight-bold gen-text-color"></h1>
          </div>

          <div className="col text-right">
            <a
              onClick={this.hideModal}
              className="ft20 gen-text-color cp closecontactus"
            >
              &times;
            </a>
          </div>
        </div>

        <div className="text-center">
          <div className="choice-menu">
            <div className="gen-text-color font-weight-bold mb-4">
              <h2 className="mb-2 font-weight-bold">Support Mails</h2>
              <p>
                Click any of the email addresses below to send a support mail.
              </p>
            </div>
          </div>
          <div>
            {this.state.listofsupportemails.map((mail) => {
              return (
                <a
                  key={mail.value}
                  href={"mailto:" + mail.value}
                  className="call-center-detail"
                  style={{ padding: "20px 20px 20px 20px" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="mr-3 bi bi-envelope"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z" />
                  </svg>
                  <span>{mail.value}</span>
                </a>
              );
            })}
          </div>
          <p className="mt-3 mb-2">
            Read Our{" "}
            <span
              onClick={() => this.switchSubTab("Support", "SupportFaq")}
              className="cp greencolor"
              style={{ textDecoration: "underline" }}
            >
              FAQs.
            </span>
          </p>
        </div>
      </div>
    );
  };

  CallCenterModal = () => {
    return (
      <div
        style={{
          width: "400px",
          height: "450px",
          top: "100px",
          overflowY: "scroll",
        }}
        id={
          this.state.showmodal && this.state.targetmodal === "callcentermodal"
            ? "showmodal"
            : ""
        }
        className="trxnmodal p-4 bvnverifiedmodal animated fadeIn delay-0.5s"
      >
        <div className="row">
          <div className="col">
            <h1 className="font-weight-bold gen-text-color"></h1>
          </div>

          <div className="col text-right">
            <a
              onClick={this.hideModal}
              className="ft20 gen-text-color cp closecontactus"
            >
              &times;
            </a>
          </div>
        </div>

        <div className="text-center">
          <div className="choice-menu">
            <div className="gen-text-color font-weight-bold mb-3">
              <h2 className="mb-2 font-weight-bold">Call Center</h2>
            </div>
          </div>
          <div>
            {this.state.listofsupportlines.map((line) => {
              return (
                <a
                  key={line.value}
                  href={"tel:" + line.value}
                  className="call-center-detail"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="greencolor mr-4 bi bi-telephone-outbound-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z"
                    />
                  </svg>
                  <span>{line.value}</span>
                </a>
              );
            })}
          </div>
          <p className="mt-3 mb-2">
            Read Our{" "}
            <span
              onClick={() => this.switchSubTab("Support", "SupportFaq")}
              className="cp greencolor"
              style={{ textDecoration: "underline" }}
            >
              FAQs.
            </span>
          </p>
        </div>
      </div>
    );
  };

  AccountPendingModal = () => {
    return (
      <div
        style={{ width: "400px", height: "300px", top: "100px" }}
        id={
          this.state.showmodal &&
          this.state.targetmodal === "accountpendingmodal"
            ? "showmodal"
            : ""
        }
        className="trxnmodal p-4 bvnverifiedmodal animated fadeIn delay-0.5s"
      >
        <div className="row">
          <div className="col">
            <h1 className="font-weight-bold gen-text-color"></h1>
          </div>
        </div>

        <div className="text-center">
          <div>
            <svg
              width="3em"
              height="3em"
              viewBox="0 0 16 16"
              className="text-danger bi bi-exclamation-circle"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
              />
              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
            </svg>
          </div>
          <p className="padding2">&nbsp;</p>

          <div className="choice-menu">
            <div className="gen-text-color font-weight-bold mb-5">
              <p className="small font-weight-bold">
                {this.state.accountmessage}
              </p>
            </div>
          </div>

          <button
            disabled={this.state.ajaxcalled}
            className="login-btn small mt-4"
            type="button"
            onClick={this.handleSignOut}
          >
            <span className="w-85 font-weight-bold ml-50">Sign Out</span>

            <span className="w-15 h-100 slant-arrow">
              {this.state.ajaxcalled ? (
                <utilities.Gear style={{ width: "26px", marginTop: "9px" }} />
              ) : (
                <svg
                  width="1.5em"
                  height="100%"
                  viewBox="0 0 16 16"
                  className="bi bi-arrow-right-circle"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"
                  />
                </svg>
              )}
            </span>
          </button>
        </div>
      </div>
    );
  };

  PinConfirmation = () => {
    return (
      <div className="auth-guy">
        <div>
          <a className="gen-text-color cp" onClick={this.handleBackButton}>
            <svg
              width="2em"
              height="2em"
              viewBox="0 0 16 16"
              className="bi bi-arrow-left-short"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
              />
            </svg>
            <span className="vam font-weight-bold">Back</span>
          </a>
        </div>

        <div className="web-space">
          <p className="padding2">&nbsp;</p>
          <p className="padding2">&nbsp;</p>
        </div>

        <div className="gen-text-color font-weight-bold mb-5">
          <h2 className="mb-4 font-weight-bold">Authentication</h2>
          <p className="small font-weight-bold">
            Kindly input transaction pin to complete.
          </p>
        </div>

        <div className="text-danger small font-weight-bold text-center mb-4">
          {!this.state.ajaxcalled &&
            this.state.ajaxerror &&
            this.state.ajaxerror}
        </div>

        <div id="code-form" className="code-form">
          <input
            type="password"
            onChange={this.handleInput}
            id="trxnpin1"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            type="password"
            onChange={this.handleInput}
            id="trxnpin2"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            type="password"
            onChange={this.handleInput}
            id="trxnpin3"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            type="password"
            onChange={this.handleInput}
            id="trxnpin4"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
        </div>

        <div className="text-danger small mb-4">
          {!this.state.trxnpin && this.state.validate && "Required"}
        </div>

        <button
          disabled={this.state.ajaxcalled}
          className="login-btn small mt-4"
          type="button"
          onClick={this.confirmPin}
        >
          <span className="w-85 font-weight-bold ml-50">Continue</span>

          <span className="w-15 h-100 slant-arrow">
            {this.state.ajaxcalled ? (
              <utilities.Gear style={{ width: "26px", marginTop: "9px" }} />
            ) : (
              <svg
                width="1.5em"
                height="100%"
                viewBox="0 0 16 16"
                className="bi bi-arrow-right-circle"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                />
                <path
                  fillRule="evenodd"
                  d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"
                />
              </svg>
            )}
          </span>
        </button>

        <div
          className="text-danger text-center font-weight-bold mt-4 cp"
          onClick={this.cancelTransaction}
        >
          Cancel Transaction
        </div>
      </div>
    );
  };

  LoansPage = () => {
    return (
      <div className="h1000">
        <iframe
          title={"Loan Application"}
          src={this.state.loansurl}
          className="h-100"
          width="100%"
          height="70%"
          style={{ border: "none" }}
        ></iframe>
      </div>
    );
  };

  ManageLoansPage = () => {
    return (
      <div className="h1000">
        <iframe
          title={"Manage Loans"}
          src={this.state.loansurl}
          className="h-100"
          width="100%"
          style={{ border: "none" }}
        ></iframe>
      </div>
    );
  };

  render() {
    return (
      <article className="animated fadeIn delay-0.5s dash-padding page-bg">
        {/* {this.state.pageloading ? utilities.PageLoader(this.state.pageloading): <div/>} */}

        {!this.state.hidepagecontent && (
          <div className="row mb-4">
            <div className="col">
              <div className="text-left">
                <img
                  src="/assets/imgs/npf-logo-main.png"
                  alt=""
                  className="sign-up-icon"
                  style={{ height: "35px" }}
                />
              </div>
            </div>

            <div className="col px-2 text-right">
              <div className="d-flex jfe">
                {this.state.accountpending && (
                  <div className="bg-danger text-white p-2 rounded mr-3">
                    <div className="mt-1">PND</div>
                  </div>
                )}

                <a
                  className="gen-text-color lh3 mr-3 cp"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <svg
                    width="2em"
                    height="2em"
                    viewBox="0 0 16 16"
                    className="bi bi-grid-fill"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3z"
                    />
                  </svg>
                </a>

                <div>
                  <img
                    src={
                      store.get("user") && store.get("user").photo_location
                        ? store.get("user").photo_location
                        : "https://via.placeholder.com/150"
                    }
                    alt="Avatar"
                    className="avatar"
                  />
                </div>
              </div>
              <div className="font-weight-bold mt-2">
                Last login:{" "}
                <span className="font-weight-normal text-muted">
                  {store.get("user") && store.get("user").last_login_date
                    ? new Date(
                        store.get("user").last_login_date
                      ).toLocaleString()
                    : new Date().toLocaleString()}
                </span>
              </div>
            </div>
          </div>
        )}

        {!this.state.hidepagecontent && (
          <div className="row">
            <nav
              className="navbar navbar-expand-lg navbar-dark col-lg-3 pt-0"
              id="sideNav"
            >
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a
                      onClick={() => {
                        this.switchTab("Dashboard");
                      }}
                      className={
                        this.state.activetab === "Dashboard"
                          ? "cp bg-white nav-link js-scroll-trigger gen-text-color font-weight-bold navlinks"
                          : "cp nav-link js-scroll-trigger gen-text-color font-weight-bold navlinks"
                      }
                    >
                      <svg
                        width="2em"
                        height="2em"
                        viewBox="0 0 16 16"
                        className="bi bi-wallet-fill st-icons"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z" />
                        <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z" />
                      </svg>
                      <span>Dashboard</span>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      onClick={() => {
                        this.switchTab("Payments");
                      }}
                      className={
                        this.state.activetab === "Payments"
                          ? "cp bg-white nav-link js-scroll-trigger gen-text-color font-weight-bold navlinks"
                          : "cp nav-link js-scroll-trigger gen-text-color font-weight-bold navlinks"
                      }
                    >
                      <svg
                        width="1.5em"
                        height="1.5em"
                        viewBox="0 0 16 16"
                        className="bi bi-arrow-left-right st-icons"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"
                        />
                      </svg>
                      <span>Payments</span>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      onClick={() => {
                        this.switchTab("Analytics");
                      }}
                      className={
                        this.state.activetab === "Analytics"
                          ? "cp bg-white nav-link js-scroll-trigger gen-text-color font-weight-bold navlinks"
                          : "cp nav-link js-scroll-trigger gen-text-color font-weight-bold navlinks"
                      }
                    >
                      <svg
                        width="1.5em"
                        height="1.5em"
                        viewBox="0 0 16 16"
                        className="bi bi-pie-chart-fill st-icons"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M15.985 8.5H8.207l-5.5 5.5a8 8 0 0 0 13.277-5.5zM2 13.292A8 8 0 0 1 7.5.015v7.778l-5.5 5.5zM8.5.015V7.5h7.485A8.001 8.001 0 0 0 8.5.015z" />
                      </svg>
                      <span>Analytics</span>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      onClick={() => {
                        this.switchTab("Support");
                      }}
                      className={
                        this.state.activetab === "Support"
                          ? "cp bg-white nav-link js-scroll-trigger gen-text-color font-weight-bold navlinks"
                          : "cp nav-link js-scroll-trigger gen-text-color font-weight-bold navlinks"
                      }
                    >
                      <svg
                        width="1.5em"
                        height="1.5em"
                        viewBox="0 0 16 16"
                        className="bi bi-headset st-icons"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 1a5 5 0 0 0-5 5v4.5H2V6a6 6 0 1 1 12 0v4.5h-1V6a5 5 0 0 0-5-5z"
                        />
                        <path d="M11 8a1 1 0 0 1 1-1h2v4a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V8zM5 8a1 1 0 0 0-1-1H2v4a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V8z" />
                        <path
                          fillRule="evenodd"
                          d="M13.5 8.5a.5.5 0 0 1 .5.5v3a2.5 2.5 0 0 1-2.5 2.5H8a.5.5 0 0 1 0-1h3.5A1.5 1.5 0 0 0 13 12V9a.5.5 0 0 1 .5-.5z"
                        />
                        <path d="M6.5 14a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2h-1a1 1 0 0 1-1-1z" />
                      </svg>
                      <span>Support Center</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      onClick={() => {
                        // console.log("coming soon");
                        this.switchTab("MainLoanPage");
                      }}
                      className={
                        this.state.activetab === "MainLoanPage"
                          ? "cp bg-white nav-link js-scroll-trigger gen-text-color font-weight-bold navlinks"
                          : "cp nav-link js-scroll-trigger gen-text-color font-weight-bold navlinks"
                      }
                    >
                      <svg
                        width="1.5em"
                        height="1.5em"
                        viewBox="0 0 16 16"
                        className="bi bi-cash st-icons"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M15 4H1v8h14V4zM1 3a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H1z"
                        />
                        <path d="M13 4a2 2 0 0 0 2 2V4h-2zM3 4a2 2 0 0 1-2 2V4h2zm10 8a2 2 0 0 1 2-2v2h-2zM3 12a2 2 0 0 0-2-2v2h2zm7-4a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
                      </svg>
                      <span>Loan</span>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      onClick={() => {
                        this.switchTab("Settings");
                      }}
                      className={
                        this.state.activetab === "Settings"
                          ? "cp bg-white nav-link js-scroll-trigger gen-text-color font-weight-bold navlinks"
                          : "cp nav-link js-scroll-trigger gen-text-color font-weight-bold navlinks"
                      }
                    >
                      <svg
                        width="1.5em"
                        height="1.5em"
                        viewBox="0 0 16 16"
                        className="bi bi-gear-fill st-icons"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 0 0-5.86 2.929 2.929 0 0 0 0 5.858z"
                        />
                      </svg>
                      <span>Settings</span>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      onClick={this.handleSignOut}
                      className={
                        this.state.activetab === "SignOut"
                          ? "cp bg-white nav-link js-scroll-trigger gen-text-color font-weight-bold navlinks"
                          : "cp nav-link js-scroll-trigger gen-text-color font-weight-bold navlinks"
                      }
                    >
                      <svg
                        width="1.5em"
                        height="1.5em"
                        viewBox="0 0 16 16"
                        className="bi bi-arrow-bar-left st-icons"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5zM10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5z"
                        />
                      </svg>
                      <span>Sign Out</span>
                    </a>
                  </li>
                </ul>

                <div className="chat-btn" style={{ left: "90px" }}>
                  <svg
                    width="1.5em"
                    height="2em"
                    viewBox="0 0 16 16"
                    className="bi bi-chat-left-fill pr-1"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
                    />
                  </svg>
                </div>
              </div>
            </nav>

            <section
              className="resume-section col-lg mt-4 dash-padding-right"
              id="about"
              style={{
                borderRight:
                  this.state.activesubtab !== "AllTransactions" &&
                  "2px dotted #b1add4",
              }}
            >
              {this.state.activetab === "Dashboard" && <this.DashboardPage />}
              {this.state.activetab === "MainLoanPage" && <this.MainLoanPage />}
              {this.state.activetab === "Payments" &&
                !this.state.activesubtab && <this.PaymentsPage />}
              {this.state.activetab === "Payments" &&
                (this.state.activesubtab === "AirtimePurchase" ||
                  this.state.activesubtab === "DataPurchase") &&
                !this.state.activeinnertab && <this.MobileTransferPage />}
              {this.state.activetab === "Payments" &&
                this.state.activesubtab === "QuickTellerPayments" &&
                !this.state.activeinnertab && <this.QuickTellerPaymentsPage />}
              {this.state.activetab === "Payments" &&
                (this.state.activesubtab === "IntrabankTransfer" ||
                  this.state.activesubtab === "InterbankTransfer") &&
                !this.state.activeinnertab && <this.BankTransferPage />}
              {this.state.activetab === "Payments" &&
                (this.state.activesubtab === "AirtimePurchase" ||
                  this.state.activesubtab === "DataPurchase" ||
                  this.state.activesubtab === "QuickTellerPayments" ||
                  this.state.activesubtab === "IntrabankTransfer" ||
                  this.state.activesubtab === "InterbankTransfer") &&
                this.state.activeinnertab === "PinConfirmation" && (
                  <this.PinConfirmation />
                )}
              {/* {this.state.activetab === "Payments" && this.state.activesubtab === "AllTransactions" && <this.AllTransactions />} */}
              {this.state.activetab === "Analytics" &&
                !this.state.activesubtab && <this.AnalyticsPage />}
              {this.state.activetab === "AllTransactions" &&
                !this.state.activesubtab && <this.AllTransactions />}
              {this.state.activetab === "Support" &&
                !this.state.activesubtab && (
                  <SupportPage
                    listofsupportlines={this.state.listofsupportlines}
                    listofsupportemails={this.state.listofsupportemails}
                    switchTab={this.switchTab}
                    showSuppotMails={() => this.showModal("supportmailsmodal")}
                    showCallCenter={() => this.showModal("callcentermodal")}
                    switchSubTab={this.switchSubTab}
                  />
                )}
              {this.state.activetab === "Support" &&
                this.state.activesubtab === "SupportEmail" && (
                  <SupportEmail
                    listofsupportlines={this.state.listofsupportlines}
                    listofsupportemails={this.state.listofsupportemails}
                    handleBackButton={this.handleBackButton}
                    showModal={this.showModal}
                  />
                )}
              {this.state.activetab === "Support" &&
                this.state.activesubtab === "SupportFaq" && (
                  <SupportFaq handleBackButton={this.handleBackButton} />
                )}
              {this.state.activetab === "Support" &&
                this.state.activesubtab === "SupportBranch" && (
                  <SupportBranch handleBackButton={this.handleBackButton} />
                )}
              {this.state.activetab === "Support" &&
                this.state.activesubtab === "SupportLiveAgent" && (
                  <SupportLiveAgent
                    listofsupportlines={this.state.listofsupportlines}
                    listofsupportemails={this.state.listofsupportemails}
                    handleBackButton={this.handleBackButton}
                    showModal={this.showModal}
                  />
                )}
              {this.state.activetab === "Settings" &&
                !this.state.activesubtab && <this.SettingsPage />}
              {this.state.activetab === "Settings" &&
                this.state.activesubtab === "SetUpPin" && <this.SetUpPin />}
              {this.state.activetab === "Settings" &&
                this.state.activesubtab === "ChangePin" && <this.ChangePin />}
              {this.state.activetab === "ComingSoon" && (
                <utilities.ComingSoonPage
                  history={this.props.history}
                  switchTab={this.switchTab}
                />
              )}

              {this.state.activetab === "Loans" && !this.state.activesubtab && (
                <this.LoansPage />
              )}
              {this.state.activetab === "ManageLoans" &&
                !this.state.activesubtab && <this.ManageLoansPage />}
            </section>

            {this.state.activetab !== "AllTransactions" &&
              this.state.activetab !== "ComingSoon" &&
              !this.state.ismobile && (
                <section
                  className="resume-section col-lg-3 mt-4 pl-4"
                  id="about"
                >
                  <div className="resume-section-content">
                    <h3 className="mb-0 gen-text-color font-weight-bold mb-3">
                      Transactions
                    </h3>

                    <div className="gen-text-color small mb-3">
                      {(this.state.transactionkeys.length &&
                        this.state.transactionkeys[0]) ||
                        "No transaction history."}
                    </div>

                    <div className="today-body mb-3">
                      {(this.state.transactionkeys.length &&
                        this.state.transactionsdisplayed[
                          this.state.transactionkeys[0]
                        ]
                          .slice(0, 3)
                          .map((trxn, idx) => {
                            return (
                              <div className="row mx-1 p-3" key={idx}>
                                <div className="col-lg-2 mr-2 p-0">
                                  {trxn.type === "Credit" && (
                                    <svg
                                      width="3em"
                                      height="3em"
                                      viewBox="0 0 16 16"
                                      className="credit-icon bi bi-arrow-down-left-circle"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                      />
                                      <path
                                        fillRule="evenodd"
                                        d="M10.828 5.172a.5.5 0 0 0-.707 0L6.025 9.268V6.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H9.5a.5.5 0 0 0 0-1H6.732l4.096-4.096a.5.5 0 0 0 0-.707z"
                                      />
                                    </svg>
                                  )}

                                  {trxn.type === "Debit" && (
                                    <svg
                                      width="3em"
                                      height="3em"
                                      viewBox="0 0 16 16"
                                      className="debit-icon bi bi-arrow-up-right-circle"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                      />
                                      <path
                                        fillRule="evenodd"
                                        d="M5.172 10.828a.5.5 0 0 0 .707 0l4.096-4.096V9.5a.5.5 0 1 0 1 0V5.525a.5.5 0 0 0-.5-.5H6.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707z"
                                      />
                                    </svg>
                                  )}
                                </div>

                                <div className="col-lg p-0">
                                  {(trxn.type === "Debit" && (
                                    <div className="gen-text-color font-weight-bold">
                                      Withdrawal
                                    </div>
                                  )) ||
                                    ""}
                                  {(trxn.type === "Credit" && (
                                    <div className="gen-text-color font-weight-bold">
                                      Transfer Inwards
                                    </div>
                                  )) ||
                                    ""}

                                  <div className="muted-color small">
                                    {trxn.description || ""}
                                  </div>
                                </div>

                                <div
                                  className={
                                    trxn.type === "Debit"
                                      ? "col-lg-4 text-right red-color font-weight-bold p-0"
                                      : "col-lg-4 text-right greener-color font-weight-bold p-0"
                                  }
                                >
                                  <utilities.NairaSympbol />
                                  {numeral(trxn.amount).format("0,0.00") || ""}
                                </div>
                              </div>
                            );
                          })) ||
                        ""}
                    </div>

                    <div className="gen-text-color small mb-3">
                      {(this.state.transactionkeys.length &&
                        this.state.transactionkeys.length > 1 &&
                        this.state.transactionkeys[1]) ||
                        ""}
                    </div>

                    <div className="today-body mb-3">
                      {(this.state.transactionkeys.length &&
                        this.state.transactionkeys.length > 1 &&
                        this.state.transactionsdisplayed[
                          this.state.transactionkeys[1]
                        ]
                          .slice(0, 3)
                          .map((trxn, idx) => {
                            return (
                              <div className="row mx-1 p-3" key={idx}>
                                <div className="col-lg-2 mr-2 p-0">
                                  {trxn.type === "Credit" && (
                                    <svg
                                      width="3em"
                                      height="3em"
                                      viewBox="0 0 16 16"
                                      className="credit-icon bi bi-arrow-down-left-circle"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                      />
                                      <path
                                        fillRule="evenodd"
                                        d="M10.828 5.172a.5.5 0 0 0-.707 0L6.025 9.268V6.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H9.5a.5.5 0 0 0 0-1H6.732l4.096-4.096a.5.5 0 0 0 0-.707z"
                                      />
                                    </svg>
                                  )}

                                  {trxn.type === "Debit" && (
                                    <svg
                                      width="3em"
                                      height="3em"
                                      viewBox="0 0 16 16"
                                      className="debit-icon bi bi-arrow-up-right-circle"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                      />
                                      <path
                                        fillRule="evenodd"
                                        d="M5.172 10.828a.5.5 0 0 0 .707 0l4.096-4.096V9.5a.5.5 0 1 0 1 0V5.525a.5.5 0 0 0-.5-.5H6.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707z"
                                      />
                                    </svg>
                                  )}
                                </div>

                                <div className="col-lg p-0">
                                  {(trxn.type === "Debit" && (
                                    <div className="gen-text-color font-weight-bold">
                                      Withdrawal
                                    </div>
                                  )) ||
                                    ""}
                                  {(trxn.type === "Credit" && (
                                    <div className="gen-text-color font-weight-bold">
                                      Transfer Inwards
                                    </div>
                                  )) ||
                                    ""}

                                  <div className="muted-color small">
                                    {trxn.description || ""}
                                  </div>
                                </div>

                                <div
                                  className={
                                    trxn.type === "Debit"
                                      ? "col-lg-4 text-right red-color font-weight-bold p-0"
                                      : "col-lg-4 text-right greener-color font-weight-bold p-0"
                                  }
                                >
                                  <utilities.NairaSympbol />
                                  {numeral(trxn.amount).format("0,0.00") || ""}
                                </div>
                              </div>
                            );
                          })) ||
                        ""}
                    </div>

                    <div className="gen-text-color small mb-3">
                      {(this.state.transactionkeys.length &&
                        this.state.transactionkeys.length > 2 &&
                        this.state.transactionkeys[2]) ||
                        ""}
                    </div>

                    <div className="today-body mb-3">
                      {(this.state.transactionkeys.length &&
                        this.state.transactionkeys.length > 2 &&
                        this.state.transactionsdisplayed[
                          this.state.transactionkeys[2]
                        ]
                          .slice(0, 3)
                          .map((trxn, idx) => {
                            return (
                              <div className="row mx-1 p-3" key={idx}>
                                <div className="col-lg-2 mr-2 p-0">
                                  {trxn.type === "Credit" && (
                                    <svg
                                      width="3em"
                                      height="3em"
                                      viewBox="0 0 16 16"
                                      className="credit-icon bi bi-arrow-down-left-circle"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                      />
                                      <path
                                        fillRule="evenodd"
                                        d="M10.828 5.172a.5.5 0 0 0-.707 0L6.025 9.268V6.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H9.5a.5.5 0 0 0 0-1H6.732l4.096-4.096a.5.5 0 0 0 0-.707z"
                                      />
                                    </svg>
                                  )}

                                  {trxn.type === "Debit" && (
                                    <svg
                                      width="3em"
                                      height="3em"
                                      viewBox="0 0 16 16"
                                      className="debit-icon bi bi-arrow-up-right-circle"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                      />
                                      <path
                                        fillRule="evenodd"
                                        d="M5.172 10.828a.5.5 0 0 0 .707 0l4.096-4.096V9.5a.5.5 0 1 0 1 0V5.525a.5.5 0 0 0-.5-.5H6.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707z"
                                      />
                                    </svg>
                                  )}
                                </div>

                                <div className="col-lg p-0">
                                  {(trxn.type === "Debit" && (
                                    <div className="gen-text-color font-weight-bold">
                                      Withdrawal
                                    </div>
                                  )) ||
                                    ""}
                                  {(trxn.type === "Credit" && (
                                    <div className="gen-text-color font-weight-bold">
                                      Transfer Inwards
                                    </div>
                                  )) ||
                                    ""}

                                  <div className="muted-color small">
                                    {trxn.description || ""}
                                  </div>
                                </div>

                                <div
                                  className={
                                    trxn.type === "Debit"
                                      ? "col-lg-4 text-right red-color font-weight-bold p-0"
                                      : "col-lg-4 text-right greener-color font-weight-bold p-0"
                                  }
                                >
                                  <utilities.NairaSympbol />
                                  {numeral(trxn.amount).format("0,0.00") || ""}
                                </div>
                              </div>
                            );
                          })) ||
                        ""}
                    </div>

                    <div className="gen-text-color small mb-3">
                      {(this.state.transactionkeys.length &&
                        this.state.transactionkeys.length > 3 &&
                        this.state.transactionkeys[3]) ||
                        ""}
                    </div>

                    <div className="today-body mb-3">
                      {(this.state.transactionkeys.length &&
                        this.state.transactionkeys.length > 3 &&
                        this.state.transactionsdisplayed[
                          this.state.transactionkeys[3]
                        ]
                          .slice(0, 3)
                          .map((trxn, idx) => {
                            return (
                              <div className="row mx-1 p-3" key={idx}>
                                <div className="col-lg-2 mr-2 p-0">
                                  {trxn.type === "Credit" && (
                                    <svg
                                      width="3em"
                                      height="3em"
                                      viewBox="0 0 16 16"
                                      className="credit-icon bi bi-arrow-down-left-circle"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                      />
                                      <path
                                        fillRule="evenodd"
                                        d="M10.828 5.172a.5.5 0 0 0-.707 0L6.025 9.268V6.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H9.5a.5.5 0 0 0 0-1H6.732l4.096-4.096a.5.5 0 0 0 0-.707z"
                                      />
                                    </svg>
                                  )}

                                  {trxn.type === "Debit" && (
                                    <svg
                                      width="3em"
                                      height="3em"
                                      viewBox="0 0 16 16"
                                      className="debit-icon bi bi-arrow-up-right-circle"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                      />
                                      <path
                                        fillRule="evenodd"
                                        d="M5.172 10.828a.5.5 0 0 0 .707 0l4.096-4.096V9.5a.5.5 0 1 0 1 0V5.525a.5.5 0 0 0-.5-.5H6.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707z"
                                      />
                                    </svg>
                                  )}
                                </div>

                                <div className="col-lg p-0">
                                  {(trxn.type === "Debit" && (
                                    <div className="gen-text-color font-weight-bold">
                                      Withdrawal
                                    </div>
                                  )) ||
                                    ""}
                                  {(trxn.type === "Credit" && (
                                    <div className="gen-text-color font-weight-bold">
                                      Transfer Inwards
                                    </div>
                                  )) ||
                                    ""}

                                  <div className="muted-color small">
                                    {trxn.description || ""}
                                  </div>
                                </div>

                                <div
                                  className={
                                    trxn.type === "Debit"
                                      ? "col-lg-4 text-right red-color font-weight-bold p-0"
                                      : "col-lg-4 text-right greener-color font-weight-bold p-0"
                                  }
                                >
                                  <utilities.NairaSympbol />
                                  {numeral(trxn.amount).format("0,0.00") || ""}
                                </div>
                              </div>
                            );
                          })) ||
                        ""}
                    </div>

                    <div
                      className="gen-text-color small mb-3 text-center font-weight-bold cp"
                      onClick={() => {
                        this.switchTab("AllTransactions");
                      }}
                    >
                      See All
                    </div>
                  </div>
                </section>
              )}
          </div>
        )}

        {!this.state.hidepagecontent && (
          <utilities.Modal
            showmodal={
              this.state.targetmodal === "transactionsuccessfulmodal"
                ? this.state.showmodal
                : false
            }
            child={this.TransactionSuccessfulModal}
          />
        )}

        {!this.state.hidepagecontent && (
          <utilities.Modal
            showmodal={
              this.state.targetmodal === "transactionconfirmationmodal"
                ? this.state.showmodal
                : false
            }
            child={this.TransactionConfirmationModal}
          />
        )}

        <utilities.Modal
          showmodal={
            this.state.targetmodal === "accountpendingmodal"
              ? this.state.showmodal
              : false
          }
          child={this.AccountPendingModal}
        />

        <utilities.Modal
          showmodal={
            this.state.targetmodal === "pinoperationmodal"
              ? this.state.showmodal
              : false
          }
          child={this.PinOperationModal}
        />

        <utilities.Modal
          showmodal={
            this.state.targetmodal === "callcentermodal"
              ? this.state.showmodal
              : false
          }
          child={this.CallCenterModal}
        />

        <utilities.Modal
          showmodal={
            this.state.targetmodal === "supportmailsmodal"
              ? this.state.showmodal
              : false
          }
          child={this.SupportMailsModal}
        />
      </article>
    );
  }

  handleNavigation = () => {
    let paths = window.location.href.split("/");

    if (paths.length === 5) {
      this.setState(
        {
          activetab: paths[window.location.href.split("/").length - 1],
          activesubtab: "",
        },
        () => {
          if (this.state.activetab === "Loans") {
            this.encryptToken(store.get("token"));
          }
          if (this.state.activetab === "ManageLoans") {
            this.encryptManageToken(store.get("token"));
          }
        }
      );
    } else if (paths.length === 6) {
      this.setState({
        activetab: paths[window.location.href.split("/").length - 2],
        activesubtab: paths[window.location.href.split("/").length - 1],
        ownaccounttransfer: store.get("ownaccounttransfer"),
      });
    } else if (paths.length === 7) {
      this.setState({
        activetab: paths[window.location.href.split("/").length - 3],
        activesubtab: paths[window.location.href.split("/").length - 2],
        activeinnertab: paths[window.location.href.split("/").length - 1],
      });
    } else {
      this.setState({
        activetab: "Dashboard",
      });
    }
  };

  componentDidMount() {
    this.handleNavigation();

    window.addEventListener(
      "popstate",
      function (e) {
        var state = e.state;
        this.handleNavigation();
      }.bind(this)
    );

    if (Utilities.mobileCheck()) {
      this.setState({ ismobile: true });
    }

    let token = store.get("token");

    if (!token) {
      this.props.history.push("/");
    } else if (token && store.get("signupstage")) {
      this.props.history.push("/signup");
    } else {
      this.checkPendingConditions();
    }
  }
}

export { Dashboard };
