import React from 'react';

const TermsAndConditions = props => {
  document.title = "TERMS AND CONDITIONS OF NPF MICROFINANCE BANK PLC";
  return (
    <div className="container text-left">
        <p className="block_16">&nbsp;</p>
      <h1 className="font-weight-bold text-center mb-5">TERMS AND CONDITIONS OF NPF MICROFINANCE BANK PLC</h1>
      <h3 className="text-underline text bold">Terms & Conditions </h3>
      
      <p className="block_1">The following terms and conditions shall govern NPF Microfinance Bank Plc E-Banking Services.</p>
	<p className="block_2">I (hereinafter referred to as “the Customer” which expression shall where the context so admits include his/her heir, administrators, successors in title and assigns) hereby confirm and agree that the following terms and conditions shall govern my Electronic banking transactions with NPF Microfinance Bank Plc (hereinafter referred to as “the Bank” which expression shall where the context so admits include its successors in title and assigns).</p>
	<p className="block_2">Please read these terms and conditions carefully. By accepting the Terms and Conditions, you agree to have read and understood and agree to be bound by the terms contained therein as well as the Laws, rules, regulations and official guidelines now existing or which may be enacted, issued, enforced or become applicable. You also agree that you shall use all Electronic Banking Channels (E-Channels) of the Bank in accordance with the terms and conditions stated therein. </p>
	<p className="block_2">If you do not agree with the Terms contained herein, “<span className="text_">DO NOT ACCEPT or USE THE ELECTRONIC BANKING CHANNELS”.</span></p>
	<p className="block_1">APPLICABILITY </p>
	<p className="block_3">These Terms and Conditions form the contract between the Customer and the Bank for the use of the E-Channels and shall be in addition to and not in derogation of the terms and conditions relating to any Account of the Customer and /or the respective product or the service provided by the Bank.</p>
	<p className="block_3">Any Customer of the Bank, having individual Accounts and a Mobile Phone number registered with the Bank and who is also a current subscriber of a Telecommunications Company is eligible to access the E-Channels subject to the Bank’s terms and conditions. </p>
	<p className="block_5">The E-Channels allows the Customer to give the Bank instructions by use of:</p>
	<ul className="list_">
	<li className="block_6">Telephone, PIN, Password, and Username for the following transactions:</li>
	<li className="block_6">Obtain information regarding customer’s balances as at the last date of business with the bank.</li>
	<li className="block_6">Obtain information with regards to any instrument in clearing or any credit standing in the Customer account as at the last date of transaction on the customer’s account.</li>
	<li className="block_6">Authorize the Bank to debit customer’s account to pay a utility bill as specified by the customer subject however to availability of such bill payment under this service.</li>
	<li className="block_6">Authorizing the Bank to effect a transfer of funds from the Customer’s account to any other account with the Bank.</li>
	<li className="block_6">Authorizing the Bank to effect any stop payment order.</li>
	<li className="block_6">Authorizing the Bank to debit Customer account and load same into value card.</li>
	<li className="block_7">On receipt of instructions, the Bank will endeavor to carry out the Customer’s instructions promptly, excepting all or any unforeseen circumstances such as Act of God, Force Majeure, and other causes<br className="calibre1" />beyond the Bank’s control.</li>
</ul>
	<p className="block_2">Under no circumstances shall the Customer allow anybody access to his/her account through these platforms.</p>
	<p className="block_1">Access Rights</p>
	<p className="block_3">The Customer understands that his/her Password/Pin is used to give instructions to the Bank and accordingly undertakes:</p>
	<ul className="list_">
	<li className="block_7">That under no circumstances shall the Password/Pin be disclosed to anybody.</li>
	<li className="block_7">Not to write the Password/Pin in an open place in order to avoid third party coming across same.</li>
	<li className="block_7">The Customer instructs and authorizes the Bank to comply with any instructions given to the bank through the use of the E-Channels</li>
	<li className="block_7">Once the Bank is instructed by means of the Customer’s Password/ Pin the Bank is shall assume that these are the instructions given by the Customer and will rely on same.</li>
	<li className="block_7">Once a Customer’s Password/Pin is given, it shall be sufficient confirmation of the authenticity of the instruction given.</li>
	<li className="block_7">The Customer shall ensure at all times that access to the mobile phone used for the E-Channels is not granted to a third party</li>
	<li className="block_7">The Bank is exempted from any form of liability whatsoever for complying with any or all instruction(s) given by means of the Customer’s Password/Pin if by any means the Password/Pin is disclosed or becomes known to a third party.</li>
	<li className="block_7">Where a Customer notifies the Bank of his intention to change the Password/Pin arising from loss of memory of same, or that it has come to the notice of a third party, the Bank shall, with the consent of the Customer, delete same and thereafter allow the customer to enter a new Password/Pin PROVIDED THAT the Bank shall not be responsible for any loss that occurs between the period of such loss of memory of the Password/Pin or knowledge of a third party and the time the report is lodged with the bank.</li>
	<li className="block_7">The Customer shall be responsible for any instruction given by means of the Customer’s Password/Pin.</li>
	<li className="block_7"><b className="calibre2">In order to deactivate the E-Channels, the Customer shall either visit the nearest branch or call:08074550514 or email fraudalert@npfmicrofinancebank.com</b></li>
</ul>
	<p className="block_2">Accordingly, the Bank shall not be responsible for any fraudulent, duplicate or erroneous instructions given by means of the Customer’s Password/Pin.</p>
	<p className="block_1">Responsibilities of the Customer</p>
	<ul className="list_">
	<li className="block_8">The customer undertakes to be responsible for safeguarding his/her username, Password/Pin and under no circumstance shall the customer disclose any or all of these to any third party.</li>
	<li className="block_9">That under no circumstances shall the Password/Pin be disclosed to anybody.</li>
	<li className="block_9">The customer undertakes to ensure the secrecy of his/her Password/Pin by not reproducing same in any manner whatsoever either in writing or otherwise capable of making it known to persons other than the customer.</li>
	<li className="block_9">The Bank is expressly exempted from any liability arising from unauthorized access to the customer’s account and/or data as contained in the Bank’s records via the E-Channels, which arises as a result of inability and/or otherwise of the customer to safeguard the password/Pin or failure to log out of the system completely by allowing on-screen display of his account information.</li>
	<li className="block_9">The Bank is further excluded from any liability as regards breach of duty of secrecy arising out of Customer’s inability to observe and implement the terms and conditions and/or instances of breach of such duty by hackers and other unauthorized access to the Customer’s account via the service.</li>
	<li className="block_9">The Customer Password/Pin must be changed immediately it becomes known to anyone else and therefore the customer is under a duty to notify the Bank whenever the Password/Pin has become known to another person.</li>
	<li className="block_9">The Customer shall be responsible for any fraud, loss and/or liability to the Bank or third party arising from usage of the Customer’s Password/Pin being used by a third party and other unauthorized access. Accordingly, the Bank shall not be responsible for any fraud that arises from the usage of the Customer’s Password/Pin.</li>
	<li className="block_9">The Customer agrees not to use the E-Channels for illegal purposes or transmission of material that is unlawful, invasive of another’s privacy or infringe the rights of use by other Customers.</li>
	<li className="block_9">The Customer’s right to use is personal and the Customer undertakes not to assign or make commercial use of the channels. The Customer is only permitted to use as expressly authorized by the service. </li>
	<li className="block_9">The Customer may not reproduce, copy, distribute or create derivative work from the E-Channels. A violation of this may lead to discontinuation of the service by the Bank. </li>
	<li className="block_9">The Customer affirms that he/she is the legal owner of the Accounts and other financial information which may be accessed using E-Channels and agrees to provide accurate, true, complete and current information for use on the E-Channels. The Customer also agrees not to misrepresent his/her identity or information which may include user names, password/pin or other access devices for such accounts. </li>
</ul>
	<p className="block_2"><b className="calibre2">Charges<br className="calibre1" /></b>Upon enrollment on the E-Channels, the Customer hereby authorizes the Bank to debit the User‘s account/s with all charges relating to transactions made through E-Channels and other costs inclusive of monthly convenience fees, legal charges and statutory charges, if any, relating to the use of the E-Channels at prevailing rates or at future rates as determined by the Bank and posted on the Bank’s official website. The Customer authorizes the Bank to set-off any applicable fee or charges from any of its accounts with the Bank</p>
	<p className="block_2"><b className="calibre2">Indemnity</b><br className="calibre1" />Under no circumstances will the Bank be liable for any damages, including without limitation direct or indirect, special, incidental or consequential damages, losses or expenses arising in connection with these E-Channels or use thereof or inability to use by any party, or in connection with any failure of performance, error, omission, interruption, defect, delay in operation, transmission, computer virus or line or system failure, even if the Bank or its representatives thereof are advised of the possibility of such damages, losses or to other internet resources are at the Customer’s risk.</p>
	<p className="block_2">The Customer also agrees to protect and fully compensate the Bank and its Subsidiaries and service providers from any/and all third party claims, liability, damages, expenses and costs (including, but not limited to legal fees) caused by or arising from customer’s use of the E-Channels, violation of the terms or infringement, or infringement by any other user of Customer’s account, of any intellectual property or other right of anyone.</p>
	<p className="block_2">The Bank shall also be indemnified for doing each and any of the following on my account;</p>
	<ol className="list_1">
	<li className="block_10">For processing transactions initiated by me via the Bank’s E-Channels above the approved limits set by the Central Bank of Nigeria (CBN) and the Bank.</li>
	<li className="block_11">For acting on instructions transmitted by me to the Bank via E-mail or SMS which appears to be furnished to the Bank by me whether directly or indirectly at any time regarding the operation of my account(s).</li>
	<li className="block_11">For issuing a debit card for dual signatories further to my application for issuance of a debit card as signatories to my account(s) with the Bank and my confirmation that I have the authority of the other signatories to apply for the card.</li>
	<li className="block_12">For acting on my authority to discontinue SMS notices on my account notwithstanding the Circular issued by CBN through its Guide to Charges by Banks and other Financial Institutions making it mandatory for all Financial Institutions making it mandatory for all Financial Institutions to send SMS notices. I am fully aware of the risk of opting out of the service which could lead to misstatements, non-receipt fraud and any other unauthorized acts by a third party on my account(s).</li>
</ol>
	<p className="block_13">The indemnity is a continuing obligation in respect of all matters connected to or arising from the Bank processing any transaction on my behalf. This indemnity shall remain valid and in full force from the date of my acceptance of the Terms and Conditions. </p>
	<p className="block_1">Intellectual Property Rights</p>
	<p className="block_3">All images, information and material contained/displayed therein are owned and maintained by the Bank.  </p>
	<p className="block_2"><b className="calibre2">Virus</b><br className="calibre1" />The Bank shall not be responsible for any electronic virus or viruses that the Customer may encounter in course of making use of this service.</p>
	<p className="block_1">Complaints</p>
	<p className="block_2"><b className="calibre2">In the event the Customer has any complaint regarding any transaction, same should be brought to the notice of the Bank immediately by visiting the nearest Branch or call the customer care unit’s number on 08074550514 or email </b><a href="mailto:fraudalert@npfmicrofinancebank.com" className="text_1">fraudalert@npfmicrofinancebank.com</a><b className="calibre2"> or </b><a href="mailto:ccare@npfmicrofinancebank.com" className="text_1">ccare@npfmicrofinancebank.com</a><b className="calibre2"> </b></p>
	<p className="block_2">The Customer shall bring to the notice of the Bank any error, discrepancy or omission in transactions noted by the Customer immediately upon receiving Bank statements.</p>
	<p className="block_1">Notice</p>
	<p className="block_3">The Bank may give notices under these Terms and Conditions, by SMS (Short Message Service) or prompt command messages via the E-Channels. In addition, the Bank may also publish notices of general nature, which are applicable to all users of the E-Channels on its website. Such notices will have the same effect as a notice served individually to the Customer. </p>
	<p className="block_1">Amendment and Update</p>
	<p className="block_3">The Bank shall at any time be entitled to amend, supplement or vary any of these terms and conditions, at its absolute discretion and such amendment, supplement or variation shall be binding on the Customer. An updated version of the terms and conditions shall be made available on the Bank’s official website or any other medium that the Bank may adopt from time to time. On publication of an updated version of these Terms and Condition on the Bank’s website or any other medium, the Customer is deemed to have read same and consents to such modification to the Terms and Conditions. The Bank shall determine the privileges attached to the use of the E-Channels and shall have absolute discretion to change, vary add or amend these privileges and conditions attached thereto from time to time as the Bank deems fit.</p>
	<p className="block_1">Discontinuation of E-Channels</p>
	<p className="block_3">The Bank reserves the right to change or discontinue, temporarily or permanently disable the E-Channels at any time without notice. In other to maintain the security and integrity of the service the Bank may also suspend Customer’s access to the service at any time without notice. Customer agrees that the Bank will not be liable to the customer or any third party for any modification or discontinuation of the service. </p>
	<p className="block_1">Joint Venture/Partnership</p>
	<p className="block_2">The Customer and Bank shall be Independent Contractors and nothing contained in this agreement shall be deemed to create any association, partnership, joint venture, or relationship of principal, agent or master and servant, employer or employee between the Bank and Customer. The Bank shall not be considered an agent or other legal representatives of the customer for any purpose by reason of this agreement and/or any other party whom the Customer is using the E-Channels to pay.</p>
	<p className="block_1">Disclaimer</p>
	<p className="block_3">The Customer expressly understands and agrees that the use of the E-Channels is at his/her/its sole risk. The E-Channels are provided on an “as is available” basis. The Bank expressly disclaims all warranties of any kind, whether express or implied, including but not limited to the implied warranties of fitness for a particular purpose and non-infringement. </p>
	<p className="block_3">The Bank makes no warranty that;</p>
	<ol className="list_1">
	<li className="block_10">The E-Channels may meet the Customer’s requirements.</li>
	<li className="block_11">The E-Channels will be uninterrupted, timely and error-free.</li>
</ol>
	<p className="block_1">Governing Law </p>
	<p className="block_3">The E-Banking Channels and these terms and conditions shall be governed by and construed in accordance with the laws of the Federal Republic of Nigeria.</p>
	<p className="block_16">&nbsp;</p>
	<p className="block_16">&nbsp;</p>
	
    </div>
  );
};

export default TermsAndConditions;