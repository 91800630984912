import React from 'react';

const PrivacyPolicy = props => {
  document.title = "PRIVACY POLICY OF NPF MICROFINANCE BANK PLC";
  return (
    <div className="container text-left">
      <h1 className="font-weight-bold text-center mb-5">PRIVACY POLICY OF NPF MICROFINANCE BANK PLC</h1>
      <h3 className="text-underline text bold">Privacy & Terms </h3>
      <p className="font-weight-bold">We Value your privacy</p>
      <p>This privacy Statement relates solely to information supplied by you on the Bank’s
      platforms. At NPF Microfinance Bank Plc (hereinafter called “NPF MFB”) we treat your
      personal information as private and confidential.</p>

      <p>This Policy sets out the basis on which any personal data we collect from you, or that
      you provide to us, will be processed by us. Please read the following carefully to
      understand our views and practises regarding your personal data and how we will treat
      it. By continuing to visit our Platforms and other NPF MFB customer touch points, you
      accept and consent to the practises described in this policy.</p>

      <p>NPF MFB is your data controller and is responsible for processing your personal
      information described in this Privacy Policy. NPF MFB uses the personally identifiable
      information you provide to communicate with you in response to your enquiries, to
      provide the services you request, and to manage your account. Any personally
      identifiable information gathered by NPF MFB will be used for these purposes only. We
      will not disclose or divulge such data outside of NPF MFB, other than to third party
      providers that assist us in providing the services. NPF MFB does not share, sell, rent or
      trade personally identifiable information with third parties for their promotional
      purposes.</p>

      <p className="font-weight-bold">How do we collect information?</p>

      <p>We may collect information from a range of sources and it may relate to any of our
      products or services we currently provide or may have provided in the past.</p>

      <p>We collect personal information when:</p>
      <ol type="a">
        <li>You open an account or perform transactions such as make deposits or
      withdrawal from your account, payment history and transaction records.</li>
        <li>You apply for a loan or use your credit or debit card.</li>
        <li>You seek information from our customer service provider, information
      concerning complaints and disputes.</li>
        <li>We seek information about your credit history from credit bureau.</li>
        <li>You provide account information such as your personal details e,g, name, gender,
        date and place of birth; contact information such as address, email address and
        mobile numbers</li>
        <li>You provide information concerning your identity e.g. Photo ID, Passport
        information, National ID Card and nationality</li>
        <li>You use your login credentials for online banking and mobile banking apps.</li>
        <li>We conduct necessary investigations i.e. due diligence checks and AML/CFT
        checks and obtain information that we need to support our regulatory
        obligations e.g. information about transaction details, detection of any suspicious
        and unusual activities</li>
      </ol>

      <p className="font-weight-bold">Use of your Personal Information:</p>

      NPF MFB will process your personal information for the following purposes;
      <ol type="i">
        <li>To offer and provide our products and services tailored to meet your unique
      needs.</li>
        <li>To fulfil the terms of any service contract(s) you might have with us.</li>
        <li>To improve your service experience with us</li>
        <li>To conduct our business</li>
        <li>To manage our relationship with you.</li>
        <li>To comply with Laws and Regulations provide information to Credit Agencies and other financial infrastructure</li>
        <li>To update your records with us</li>
        <li>To develop statistics as may be required</li>
        <li>To comply with our Internal Policies</li>
        <li>To communicate with you when necessary</li>
      </ol>

      <p>NPF MFB will limit the collection and use of your personal information for the stated
      purposes.</p>

      <p className="text-underline font-weight-bold">Your consent</p>
      <p>You consent to the processing of your Personal Data when you access our platforms, or
      use our services, content, features, technologies or functions offered on our Platform(s)
      or other digital platforms. You can withdraw your consent at any time but such
      withdrawal will not affect the lawfulness of processing based on consent given before
      its withdrawal.</p>

      <p>We will only disclose your personal information if:</p>
      <ul>
        <li>the law requires it;</li>
        <li>we have a public duty to disclose the information;</li>
        <li>our or your legitimate interests require disclosure; or</li>
        <li>You agreed that we may disclose your information.</li>
      </ul>

      <p className="font-weight-bold text-underline">How we protect your information</p>
      <p>We take appropriate technical and organizational measures to prevent loss,
      unauthorized access, misuse, modification or disclosure of information under our
      control. This may include the use of encryption, access controls and other forms of
      security to ensure that your data is protected. We require all parties including our staff
      and third-parties processing data on our behalf to comply with relevant policies and
      2guidelines to ensure confidentiality and that information is protected in use, when
      stored and during transmission. <span className="text-underline">Our security controls and processes are also regularly
      updated to meet industry standards.</span></p>

      <p>Where we have provided you (or where you have chosen) a password which grants you
      access to specific areas on our site, you are responsible for keeping this password
      confidential. We request that you do not to share your password or other authentication
      details with anyone.</p>

      <p> <span className="font-weight-bold text-underline">Information Protection and Retention</span><br/>
      NPF MFB will always ensure that your personal information is adequately protected
      against unauthorised access. We have put in place processes and technologies to ensure
      that your personal information is not modified, lost, damaged or destroyed. Our people
      are trained to ensure that your personal information is not disclosed and safe as stated
      in this policy.</p>

      <p>Where access and use of our electronic platforms requires authentication of the user,
      you shall be responsible for the use and safety of your authentication credentials(s)
      including but not limited to User Name, Personal Identification Number (PIN),
      Password, One Time Passwords (OTP) AND Tokens. We will retain your personal
      information for such length of time as may be required by law, regulation and the
      internal policies of NPF MFB.</p>
      
      <p><span className="font-weight-bold text-underline">Where We Store Your Information</span><br/>
      All Personal Information you provide to us is stored on our secured servers as well as
      secured physical locations and cloud infrastructure (where applicable). Whenever your
      information is transferred to another location, we will take all necessary steps to ensure
      that your data is handled securely and in accordance with this privacy policy.</p>
      
      <p><span className="font-weight-bold text-underline">How Long We Store Your Information</span><br />
      We retain your data for as long as is necessary for the purpose(s) that it was collected.
      Storage of your data is also determined by legal, regulatory, administrative or
      operational requirements. We only retain information that allows us comply with legal
      and regulatory requests for certain data, meet business and audit requirements,
      respond to complaints and queries, or address disputes or claims that may arise.</p>
      
      <p>Data which is not retained is securely destroyed when it is identified that it is no longer
      needed for the purposes for which it was collected.</p>
      
      <p><span className="font-weight-bold text-underline">Your Rights</span> <br />
      <span className="font-weight-bold">The Right to be informed: </span>
      To emphasise the need for transparency over the useage of
      personal data, we ensure fair processing of information typically through this privacy
      policy.</p>
      
      <p><span className="font-weight-bold">The Right to Access: </span>
      Individuals have right to access information the Bank holds,
      access their personal data and other supplementary information and obtain information
      about how we process it.</p>

      <p><span className="font-weight-bold">The Right to Restrict Processing: </span>Individuals have a right to “block” or withdraw their
      consent to our processing of your information, which you can do at any time. When
      processing is restricted, we are permitted to store the personal data, but not further
      process it.</p>

      <p><span className="font-weight-bold">The Right to Rectification: </span>Individuals are entitled to have personal data rectified if it
      is inaccurate or incomplete. If this personal data in question has been disclosed to third
      parties, they must be informed of the rectification where possible. The Bank must also
      inform the individuals about the third parties to whom the data has been disclosed
      where appropriate.</p>

      <p><span className="font-weight-bold">The Right to Erasure: </span>Individuals have the right to request the deletion or removal of
      personal data where there is no compelling legal or regulatory requirement for its
      continued processing. The Bank will ensure that the right is protected.</p>

      <p><span className="font-weight-bold">The Right to Data Portability: </span>We will ensure that personal data is moved, copied or
      transferred easily from one IT environment to another in a safe and secure way without
      hindrance to usability.</p>

      <p><span className="font-weight-bold">The Right to Object: </span>Individuals have the right to object to our processing of their
      information in some circumstances.</p>

      <p><span className="font-weight-bold text-underline">Cookies</span> <br />
      In order to improve our Internet service to you, we will occasionally use a “cookie”
      and/or other similar files or programs which may place certain information on your
      computer’s hard drive when you visit our Platform(s).</p>

      <p>A cookie is a small amount of data that our web server sends to your web browser when
      you visit certain parts of our site. We may use cookies to: </p>
      
      <ol type="i">
        <li>Allow us to recognise the PC you are using when you return to our Platform(s) so
      that we can understand your interest in our Platform(s) and tailor its content
      and advertisements to match your interests (This type of cookie may be stored
      permanently on your PC but does not contain any information that can identify
      you personally).</li>
        <li>Identify you after you have logged in by storing a temporary reference number in
      the cookie so that our web server can conduct a dialogue with you while
      simultaneously dealing with other customers. (Your browser keeps this type of
      cookie until you log off or close down your browser when these types of cookie
      are normally deleted. No other information is stored in this type of cookie).</li>
        <li>Allow you to carry information across pages of our site and avoid having to re-
      enter that information.</li>
        <li>Allow you access to stored information if you register for any of our on-line
      services.</li>
        <li>Enable us to produce statistical information which helps us to improve the
      structure and content of our Platform(s).</li>
        <li>Enable us to evaluate the effectiveness of our advertising and promotions.</li>
      </ol>

      <p>Cookies do not enable us to gather personal information about you unless you give the
      information to our server. Most Internet browser software allows the blocking of all
      cookies or enables you to receive a warning before a cookie is stored. For further
      information, please refer to your Internet browser software instructions or help screen.</p>
      
      <p><span className="font-weight-bold text-underline">Individual Responsibility</span><br />Individuals are responsible for making sure the information provided to the Bank is
      accurate and should inform the Bank of any changes as it occurs, this will enable us to
      update your information with us.</p>
      
      <p><span className="font-weight-bold text-underline">Update to this Privacy Policy</span><br />This policy may be revised to reflect the legal, regulatory and operating environment
      and such revised versions will automatically become applicable to you. We will post any
      revisions we make to our Privacy Policy on this page and such revised policy becomes
      effective as at the time it is posted. We also encourage you to check this page from time
      to time for updates to this policy.</p>
      
      <p><span className="font-weight-bold text-underline">Indemnity</span><br />By utilizing our Platform(s) you agree to the terms and conditions of this privacy policy
      and authorize NPF MFB to collect information about you as outlined above. In addition,
      by using our Platform(s) you agree to hold NPF MFB harmless from any liability or
      damage arising from wrongful data supplied by you, breach or default in the
      performance of any of your obligations and/or misrepresentation of the data provided.</p>

      <p>This indemnity shall be a continuing obligation in respect of any and all matters
      connected to or arising from the Bank processing such transactions and shall remain
      valid and in full force until the Bank discharges me from my obligations herein. This
      indemnity shall be construed in accordance with the laws of the Federal Republic of
      Nigeria.</p>
    </div>
  );
};

export default PrivacyPolicy;