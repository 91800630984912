import React from 'react';
import {Link} from "react-router-dom";
import {Utilities} from "./utilities";
import HttpService from "../httpservice";
import nProgress from 'nprogress';

let httpservice = new HttpService();
let utilities = new Utilities();

class ForgotPassword extends React.Component {
    constructor() {
        super();

        this.state = {
            validate: false,
            ajaxcalled: false,
            ajaxerror: "",
            ajaxsuccess: "",
            phone: "",
            pin1: "",
            pin2: "",
            pin3: "",
            pin4: "",

            listofsupportlines: [],
        }
    };

    handleInput = (e) => {
        const {name, value} = e.target;
        this.setState({
            [name]: value,
            validate: name?.includes?.("pin") ? false : this.state.validate
        });
        let otp = document.querySelectorAll('.code-form');

        if(otp) utilities.activateNextInput();
    };

    handlePasswordReset = () => {
        if(!this.state.phone) {
            this.setState({validate: true});
        } else {

            this.setState({ajaxcalled: true, validate: false});
            let phone = utilities.packagePhoneNumber(this.state.phone);
        
            httpservice.unAuthPost("auth/reset_password", {phone: phone})
                .then(response => {

                    if(response.error) {
                        this.setState({ajaxcalled: false});

                        this.setState({ajaxerror: response.message || response.error});
                    } else if(response.token) {
                        //store.set("token", response.token);

                        this.fetchUser();
                    } else {
                        this.setState({ajaxcalled: false});
                        this.setState({ajaxerror: "Invalid login credentials Verify and try again."});
                    }
                })
                .catch(error => {
                    this.setState({ajaxcalled: false});
                    console.log("error: ", error);
                })
        }
    };

    fetchSupportLines = () => {
        nProgress.start();

        httpservice.unAuthGet("admin/get_support_lines")
            .then(response => {
                nProgress.done();

                if(!response.error) {
                    this.setState({listofsupportlines: response.result})
                }
            })
            .catch(error => {
                this.setState({ajaxcalled: false})
                console.log("error", error);
            })
    };

    startPasswordReset = () => {
        const pin = `${this.state.pin1}${this.state.pin2}${this.state.pin3}${this.state.pin4}`
        if(
            !this.state.phone
            || this.state.phone.length < 11
            || this.state.phone.length > 14
            || !this.state.pin1
            || !this.state.pin2
            || !this.state.pin3
            || !this.state.pin4
            || isNaN(pin)
        ) {
            this.setState({validate: true});
        }
        else {
            this.setState({validate: false, ajaxcalled: true});
            let phone = utilities.packagePhoneNumber(this.state.phone.replace(/\s+/g, ""));

            httpservice.unAuthPost("auth/reset_password", {phone: phone, pin: pin})
                .then(response => {
                    this.setState({
                        ajaxcalled: false,
                        phone: "",
                        pin1: "",
                        pin2: "",
                        pin3: "",
                        pin4: "",
                    });

                    nProgress.done();

                    if(!response.error) {
                        this.setState({ajaxsuccess: response.message || response.error});

                        setTimeout(() => {
                            this.props.history.push("/");
                        }, 3000);
                    } else {
                        this.setState({ajaxerror: response.message});
                    }
                })
                .catch(error => {
                    this.setState({ajaxcalled: false})
                    console.log("error", error);
                })
        }
    };

    ResetPasswordForm = () => {
        return (
            <div className="mx-auto form-guy animated fadeIn delay-0.5s">
                <div className="web-space">
                    <p className="padding2">&nbsp;</p>
                    <p className="padding2">&nbsp;</p>
                </div>
                
                <div className="gen-text-color font-weight-bold mb-5">
                    <h2 className="mb-4 font-weight-bold">Phone</h2>
                    <p className="small font-weight-bold">Enter your phone number to proceed.</p>
                </div>

                <div className="form-group">
                    <label htmlFor="" className="small darkcolor font-weight-bold">Phone Number</label>
                    <input placeholder="234" type="number" className="form-control" value={this.state.phone} name="phone" onChange={this.handleInput} maxLength="14" min={1} minLength="11" pattern="[0-9]{14}" />
                    <div className="text-danger small">{(!this.state.phone && this.state.validate) && "Required"}</div>
                    <div className="text-danger small">{((this.state.phone.length > 14) && this.state.validate) && "Phone number is invalid."}</div>
                    <div className="text-danger small">{((this.state.phone.length < 11) && this.state.validate) && "Enter at least 11 digits."}</div>
                </div>
                
                <label htmlFor="" className="small darkcolor font-weight-bold">Transaction PIN</label>
                <div id="code-form1" className="code-form">
                    <input
                      onChange={this.handleInput}
                      id="pin1"
                      name="pin1"
                      type="password"
                      maxLength="1"
                      size="1"
                      min="0"
                      max="9"
                      value={this.state.pin1}
                      pattern="[0-9]{1}" />
                    <input
                      onChange={this.handleInput}
                      id="pin2"
                      name="pin2"
                      type="password"
                      maxLength="1"
                      size="1"
                      min="0"
                      max="9"
                      value={this.state.pin2}
                      pattern="[0-9]{1}" />
                    <input
                      onChange={this.handleInput}
                      id="pin3"
                      name="pin3"
                      type="password"
                      maxLength="1"
                      size="1"
                      min="0"
                      max="9"
                      value={this.state.pin3}
                      pattern="[0-9]{1}" />
                    <input
                      onChange={this.handleInput}
                      id="pin4"
                      name="pin4"
                      type="password"
                      maxLength="1"
                      size="1"
                      min="0"
                      max="9"
                      value={this.state.pin4}
                      pattern="[0-9]{1}" />
                </div>

                <div className="text-danger small mb-4">
                    {((!this.state.pin1 || !this.state.pin2 || !this.state.pin3 || !this.state.pin4) && this.state.validate) && "Required"}
                    {(this.state.validate && (this.state.pin1 && this.state.pin2 && this.state.pin3 && this.state.pin4) && isNaN(`${this.state.pin1}${this.state.pin2}${this.state.pin3}${this.state.pin4}`)) && "PIN must be numbers"}
                    
                </div>

                <button disabled={this.state.ajaxcalled} className="login-btn small" type="button" onClick={this.startPasswordReset}>

                    <span className="w-85 font-weight-bold ml-50">Continue</span>

                    <span className="w-15 h-100 slant-arrow">
                    {
                        this.state.ajaxcalled 
                            ? 
                            <utilities.Gear style={{width: "26px", marginTop: "9px"}} /> 
                            : 
                            <svg width="1.5em" height="100%" viewBox="0 0 16 16" className="bi bi-arrow-right-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path fillRule="evenodd" d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"/>
                            </svg>
                    }
                    </span>

                </button>

                <div className="text-success font-weight-bold small text-center mt-4">
                    {!this.state.ajaxcalled && this.state.ajaxsuccess}
                </div>

                <div className="text-danger font-weight-bold small text-center mt-4">
                    {!this.state.ajaxcalled && this.state.ajaxerror}
                </div>

                <div className="text-danger font-weight-bold small text-center mt-4">
                    CONTACT SUPPORT IF YOU DO NOT HAVE A TRANSACTION PIN
                </div>

            </div>
        )
    };

    SupportContacts = () => {
        return (
            <div>
                <div className="gen-text-color font-weight-bold mb-5">
                    <h2 className="mb-4 font-weight-bold">Reset Password</h2>
                    <p className="small font-weight-bold">Kindly call any of the numbers listed below to answer security questions for account verification.</p>
                </div>

                <div className="auth-guy mx-auto">
                    {
                        this.state.listofsupportlines.map(line => {
                            return (
                                <div className="row shadow bg-white br-15 p-3 mb-3 mob-side-1" key={line.value}>
                                    <div className="col-3">
                                        <svg width="1.5em" height="2em" viewBox="0 0 16 16" className="bi bi-telephone-plus gen-text-color" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5z"/>
                                        </svg>
                                    </div>

                                    <div className="gen-text-color">
                                        <span className="fs-15">{line.value}</span>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <div className="success-color mb-4 text-center">
                        <Link to="" className="success-color mb-1 pb-2 small">Read our <span className="font-weight-bold" style={{borderBottom: "2px solid"}}>FAQ</span></Link>
                    </div>

                    <div className="font-weight-bold darkcolor mt-5 text-center">
                        <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-headset" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M8 1a5 5 0 0 0-5 5v4.5H2V6a6 6 0 1 1 12 0v4.5h-1V6a5 5 0 0 0-5-5z"/>
                            <path d="M11 8a1 1 0 0 1 1-1h2v4a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V8zM5 8a1 1 0 0 0-1-1H2v4a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V8z"/>
                            <path fillRule="evenodd" d="M13.5 8.5a.5.5 0 0 1 .5.5v3a2.5 2.5 0 0 1-2.5 2.5H8a.5.5 0 0 1 0-1h3.5A1.5 1.5 0 0 0 13 12V9a.5.5 0 0 1 .5-.5z"/>
                            <path d="M6.5 14a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2h-1a1 1 0 0 1-1-1z"/>
                        </svg>

                        <div className="font-weight-bold">Get Help</div>
                    </div>

                </div>
            </div>
        )
    };

    render() {
        return (
            <article className="animated fadeIn delay-0.5s page-bg">
        
                <section className="mx-auto">
                    <div className="wrapper" id="signup-wrapper">

                        <div className="form-inner mx-auto pb-4">
                            <form className="mx-auto">

                                <div className="row mb-4">
                                    <div className="col px-2">
                                        <span>
                                            <a className="gen-text-color cp" onClick={() => {this.props.history.push("/")}}>
                                                <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-arrow-left-short font-weight-bold" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                                                </svg>
                                                <span className="vam font-weight-bold">Back</span>
                                            </a>
                                        </span>
                                    </div>

                                    <div className="col">
                                    </div>
                                </div>

                                <this.ResetPasswordForm />
                            </form>
                        </div>

                        <div className="image-holder">
                            <img src="assets/imgs/herohalf.png" alt="" />
                        </div>
                        
                        <div className="chat-btn">
                            {/* <utilities.ChatButton /> */}
                        </div>

                    </div>

                </section>

            </article>
        );
    }

    componentDidMount() {
       //this.fetchSupportLines();
    }
}

export default ForgotPassword;