import store from "store";
//import {Utilities} from "./components/utilities";

//let utilities = new Utilities();

class HttpService {
    constructor() {}

    unAuthPost = async (url, payload, formdata, method) => {
        //utilities.handleSessionCheck();

        var header = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        };

        try {
            const response = await fetch("/api/v1/" + url, {
                method: method || "POST", 
                body: formdata ? payload : JSON.stringify(payload),
                headers: formdata ? {} : header
            });

            if(response.status === 200) {
                const result = await response.json();
                return result;
            } else {
                const result = await response.json();
                return result;
            }
        // } else {
        //         return response;
        //     }

        } catch (error) {
            console.log("error: ", error);
            return error;
        }
    }

    unAuthGet = async (url) => {
        //utilities.handleSessionCheck();

        try {
            const response = await fetch("/api/v1/" + url, {
                method: "GET",
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            });
            if (response.status === 200) {
                return response.json();
            }
            //const result = undefined;
            return response;
        } catch (error) {
            return error;
        }
    }

    generateReciept = async (url, method) => {
        try {
            const response = await fetch("/api/v1/" + url, {
                method: method || "GET",
                credentials: 'include',
                headers: {
                    "Content-disposition": "attachment; filename=$filename.pdf",
                    "Authorization": `Bearer ${store.get("token")}`
                },
            });

            if (response.status === 401) {
                return response;
            } else {
                return response.blob();
            }
            const result = undefined;
            return result;
        } catch (error) {
            return error;
        }
    }

    authGet = async (url, method) => {
        //utilities.handleSessionCheck();

        try {
            const response = await fetch("/api/v1/" + url, {
                method: method || "GET",
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${store.get("token")}`
                },
            });
            if (response.status === 200) {
                return response.json();
            }
            //const result = undefined;
            return response;
        } catch (error) {
            return error;
        }
    }

    authPost = async (url, payload, formdata, method) => {

        //utilities.handleSessionCheck();

        let headers = {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${store.get("token")}`,
        };

        try {
            const response = await fetch("/api/v1/" + url, {
                method: method || "POST",
                body: formdata ? payload : JSON.stringify(payload),
                credentials: 'include',
                headers: formdata ? {"Authorization": `Bearer ${store.get("token")}`} : headers
            });
            if (response.status === 401) {
                return response;
            }
            else {
                return response.json();
            }
            //const result = undefined;
            return response;
        } catch (error) {
            return error;
        }
    }
}

export default HttpService;

//"proxy": "http://20.42.119.47:8282",