import React from 'react';
import Noty from 'noty';
import store from 'store';
import {Link} from "react-router-dom";
import HttpService from '../httpservice';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

let sessiontimeoutid;
let recorder;

class Utilities {
    constructor() {}

    ComingSoonPage = ({ history, switchTab, isGuest}) => {
        return (
            <div className="animated fadeIn delay-0.5s coming-soon-page">
            <div>
                <a className="gen-text-color cp" onClick={() => history.goBack()}>
                    <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-arrow-left-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                    </svg>
                    <span className="vam font-weight-bold">Back</span>
                </a>
            </div>
                {/* <div className="gen-text-color font-weight-bold mb-5">
                    <a style={{cursor: "pointer"}} onClick={() => this.props.history.goBack()}>Back</a>
                </div> */}
                <div className="text-center" style={{minHeight: "80vh"}}>
                    <h2 className="mb-5">Coming Soon</h2>
                    <p className="mb-5">This feature is currently unavailable but would be rolling out in the nearest future.</p>
                    <div>
                        <button className="btn" onClick={() => {switchTab("Support")}}>
                           <span>Get in touch with us</span>
                           <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 16 16"
                            className="bi bi-arrow-right-circle"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg">
                                <path
                                 fillRule="evenodd"
                                 d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path
                                 fillRule="evenodd"
                                 d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        )
    };

    PageLoader = (props) => {
        return (
            <Loader
                type="Puff"
                color="#00BFFF"
                height={100}
                width={100}
                visible={props.pageloading}
            />
        )
    };

    ChatButton = () => {
        return (
            <div className="chat-btn">
                <svg width="1.5em" height="2em" viewBox="0 0 16 16" className="bi bi-chat-left-fill pr-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                </svg>
            </div>
        )
    };

    ContinueButton = () => {
        return (
            <div className="chat-btn" style={{left: "40%", background: "#29C186"}}>
                <svg width="2.5em" height="2.5em" style={{marginLeft: "-11px", marginTop: "-5px"}} viewBox="0 0 16 16" class="bi bi-arrow-right-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                </svg>
            </div>
        )
    };

    LivelinessCheckImage = (props) => {
        return (
            <section>
                <div className="gen-text-color font-weight-bold mb-5">
                    <h1 className="mb-4 font-weight-bold text-center">Liveliness Check</h1>
                    {!props.captureallowed && <p className="small font-weight-bold text-danger p-3">You need to enable camera and microphone to perform this operation.</p>}
                    {!props.captureallowed && <p className="small font-weight-bold text-danger p-3">Click on the lock icon in the address bar. Then select allow camera and microphone from the menu. Then refresh your page to effect the change.</p>}
                </div>

                <div className="camera mx-auto">
                    <video id="video" className="w-100 border">Video stream not available.</video>
                </div>

                <button disabled={!props.captureallowed} id="startbutton" className="login-btn small w300 my-4" type="button" onClick={() => {Utilities.takePicture(props)}}>

                    <span className="w-85 font-weight-bold ml-50">Take Photo</span>

                    <span className="w-25 h-100 slant-arrow">
                        <svg width="1.5em" height="100%" viewBox="0 0 16 16" className="bi bi-arrow-right-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path fillRule="evenodd" d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"/>
                        </svg>
                    </span>

                </button>

                <canvas id="canvas"></canvas>

                <div className="output">
                    <img id="photo" src="https://via.placeholder.com/150" alt="The screen capture will appear in this box." />
                </div>

                <div className="my-4 text-center">By clicking Continue, you agree to our<Link to="/privacy"> privacy policy</Link>.</div>

                <button disabled={!props.customerphoto} id="startbutton" className="login-btn small w300 my-4" type="button" onClick={() => {props.handler("video-check", "LivelinessCheckVideo", store.get("livecheckparentpath"))}}>

                    <span className="w-85 font-weight-bold ml-50">Continue</span>

                    <span className="w-25 h-100 slant-arrow">
                        {
                            props.ajaxcalled 
                                ? 
                                <Utilities.UtilitiesGear style={{width: "26px", marginTop: "9px"}} /> 
                                : 
                                <svg width="1.5em" height="100%" viewBox="0 0 16 16" className="bi bi-arrow-right-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path fillRule="evenodd" d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"/>
                                </svg>
                        }
                    </span>
                </button>

            </section>
        )
    };

    LivelinessCheckVideo = (props) => {

        return (
            <section className=" mx-auto">
                <div className="gen-text-color font-weight-bold mb-5">
                    <h1 className="mb-4 font-weight-bold text-center">Liveliness Check</h1>

                    {!props.captureallowed && <p className="small font-weight-bold text-danger">You need to enable camera and microphone to perform this operation.</p>}
                    {!props.captureallowed && <p className="small font-weight-bold text-danger">Click on the lock icon in the address bar. Then select allow camera and microphone from the menu. Then refresh your page to effect the change.</p>}

                    {
                        props.captureallowed
                        &&
                        !props.voiceallowed
                        &&
                        Utilities.mobileCheck()
                        &&
                        <div>
                            <p className="small font-weight-bold">Click on Start Recording to make a 5 second verification video.</p>
                            <p className="small font-weight-bold">The video must show the front and two sides of your head.</p>
                            <p className="small font-weight-bold">Follow the steps below to perform this verification:</p>
                            <p>1. Once the recording starts, look straight into the camera.</p>
                            <p>2. Say <b>ONE.</b></p>
                            <p>3. Turn right.</p>
                            <p>4. Say <b>TWO.</b></p>
                            <p>5. Turn left.</p>
                            <p>6. Say <b>THREE.</b> Then click on Stop Recording.</p>
                        </div>
                    }

                    {
                        !props.voiceallowed && props.captureallowed && !props.ismobile && <div className="text-danger">Your browser does not support text to speech. Switch to Chrome or Firefox or continue by following the instructions above.</div>
                    }

                    {
                        props.voiceallowed && props.captureallowed && <div>Follow the voice prompt after you click on "Start Recording".</div>
                    }
                    
                </div>

                <div className="w400 mx-auto">
                    <div className="">
                        <button disabled={props.recordingstarted || !props.captureallowed} id="btn-start-recording" className="login-btn small my-4" type="button" onClick={() => {Utilities.startLivelinessCheckVideo(props.this)}}>

                            <span className="w-85 font-weight-bold ml-50">Start Recording</span>

                            <span className="w-15 h-100 slant-arrow">
                                <svg width="1.5em" height="100%" viewBox="0 0 16 16" className="bi bi-arrow-right-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path fillRule="evenodd" d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"/>
                                </svg>
                            </span>
                        </button>
                    </div>

                    <div className="">
                        <button disabled={!props.recordingstarted} id="btn-start-recording" className="login-btn small my-4" type="button" onClick={() => {Utilities.handleRecordingStop(props.this)}}>

                            <span className="w-85 font-weight-bold ml-50">Stop Recording</span>

                            <span className="w-15 h-100 slant-arrow">
                                <svg width="1.5em" height="100%" viewBox="0 0 16 16" className="bi bi-arrow-right-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path fillRule="evenodd" d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"/>
                                </svg>
                            </span>
                        </button>
                    </div>
                </div>

                <div className="mx-auto text-center">
                    <video id="live-video" controls autoPlay playsInline>Video Stream is not available</video>
                </div>

                {!store.get("user") && 
                    <button type="button" 
                        disabled={props.ajaxcalled || !props.customervideo || props.recordingstarted} 
                        className="chat-btn" style={props.ajaxcalled ? {padding: "16px 10px", left: "40%", background: "#29C186"} : {left: "40%", background: "#29C186"}} 
                        onClick={props.handler}
                        >
                        {
                            props.ajaxcalled 
                                ? 
                                <Utilities.UtilitiesGear style={{marginTop: "-5px"}} /> 
                                : 
                                <svg width="2.5em" height="2.5em" style={{marginLeft: "-11px", marginTop: "-5px"}} viewBox="0 0 16 16" className="bi bi-arrow-right-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                </svg>
                        }
                    </button>
                }

            </section>
        )
    };

    IdDocument = (props) => {

        return (
            <div>
                <div className="row">

                    <div className="col-lg">
                        <div className="form-group mb-0">
                            <label htmlFor="" className="small darkcolor font-weight-bold">Upload ID</label>

                            <label className="form-control">
                                <input name="photo" onChange={props.handleFileUpload} type="file"/>
                                <div className="stubbornfileinputs" style={{marginTop: "14px"}}>{props.photofilename || "No file selected"}</div>

                                <div className="cp icon-correction2 eye-icon" style={{left: "88%", width: "44px", bottom: "33px"}}>
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-upload" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                        <path fillRule="evenodd" d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
                                    </svg>
                                </div>
                            </label>
                        </div>

                        <div className="text-danger small mt-n2 mb-1">{(!props.photo && props.validate) && "Required"}</div>
                        <div className="text-danger small mt-n2 mb-4">{(props.photoinvalid) && "Accepted file types are: jpeg, jpg, png and pdf."}</div>
                    </div>

                    <div className="col-lg">
                        <div className="form-group">
                            <label htmlFor="idtype" className="small darkcolor font-weight-bold">ID Type</label>

                                <select name="idtype" value={props.idtype} onChange={props.handleInput} className="custom-select custommenu" id="exampleFormControlSelect1">
                                    <option value="" disabled={true}>ID Type</option>
                                    {
                                        props.listofdoctypes.map(doc => {
                                            return (
                                                <option key={doc.id} value={doc.id} >{doc.name}</option>
                                            )
                                        })
                                    }
                                </select>
                        
                            <div className="text-danger small">{(!props.idtype && props.validate) && "Required"}</div>

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg">
                        <div className="form-group">
                            <label htmlFor="" className="small darkcolor font-weight-bold">Issue Date</label>
                            <DatePicker
                                selected={props.docissuedate}
                                value={props.docissuedate}
                                onChange={(date) => props.handleChange("docissuedate", date)}
                                name="docissuedate"
                                dateFormat="dd/MM/yyyy"
                                placeholderText="DD / MM / YYYY"
                                className="dateofbirth form-control w-100"
                                maxDate={new Date()}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                locale="en-GB"
                            />

                            {/* <input 
                                placeholder="DD-MM-YYYY" 
                                type="date" 
                                className="dateofbirth form-control" 
                                value={props.docissuedate} 
                                name="docissuedate" 
                                onChange={props.handleInput}
                                max={new Date().toISOString().split("T")[0]}
                            /> */}

                            <div className="text-danger small">{(!props.docissuedate && props.validate) && "Required"}</div>
                        </div>
                    </div>

                    <div className="col-lg">
                        <div className="form-group">
                            <label htmlFor="" className="small darkcolor font-weight-bold">Expiry Date</label>

                            <DatePicker
                                selected={props.docexpirydate}
                                value={props.docexpirydate}
                                onChange={(date) => props.handleChange("docexpirydate", date)}
                                name="docexpirydate"
                                dateFormat="dd/MM/yyyy"
                                placeholderText="DD / MM / YYYY"
                                className="dateofbirth form-control w-100"
                                minDate={new Date()}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                locale="en-GB"
                            />
                            
                            {/* <input 
                                placeholder="DD-MM-YYYY" 
                                min={new Date().toISOString().split("T")[0]}
                                type="date" className="dateofbirth form-control" 
                                value={props.docexpirydate} 
                                name="docexpirydate" 
                                onChange={props.handleInput}
                            /> */}

                            <div className="text-danger small">{(!props.docexpirydate && props.validate) && "Required"}</div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12 px-0">
                    <div className="form-group">
                        <label htmlFor="" className="small darkcolor font-weight-bold">ID Number</label>
                        <input placeholder="ID Number" maxLength="15" type="text" className="form-control" value={props.idnumber} name="idnumber" onChange={props.handleInput}/>
                        <div className="text-danger small">{(!props.idnumber && props.validate) && "Required"}</div>

                        <div className="text-danger small">{(props.idnumber.length < 5 && props.validate) && "ID number must not be below 5 characters."}</div>
                        <div className="text-danger small">{(props.idnumber.length > 20 && props.validate) && "ID number must not exceed 20 characters."}</div>
                        {/* <div className="text-danger small">
                            {isNaN(props.idnumber) && props.validate && "ID must consist of only numeric characters."}
                        </div> */}
                    </div>
                </div>

                <div className="row"></div>
            </div>
        )
    };

    Signature = (props) => {
        return (
            <div className="w-100">
                <div className="form-group mb-0 w-100">
                    <label htmlFor="" className="small darkcolor font-weight-bold">Upload Signature</label>

                    <label className="form-control">
                        <input name="signature" onChange={props.handleFileUpload} type="file"/>
                        <div className="stubbornfileinputs" style={{marginTop: "14px"}}>{props.signaturefilename || "No file selected"}</div>

                        <div className="cp icon-correction2 eye-icon" style={props.size ? {left: "95%", width: "44px", bottom: "34px"} : {left: "83%", width: "44px", bottom: "33px"}}>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-upload" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                <path fillRule="evenodd" d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
                            </svg>
                        </div>
                    </label>
                </div>

                <div className="text-danger small mt-n2 mb-4">{(!props.signature && props.validate) && "Required"}</div>
                <div className="text-danger small mt-n2 mb-4">{(props.signatureinvalid) && "Accepted file types are: jpeg, jpg, png and pdf."}</div>
            </div>
        )
    };

    DocUploadSegment = (props) => {
        return (
            <div>
                <this.IdDocument 
                    photofilename = {props.photofilename}
                    photo = {props.photo}
                    validate = {props.validate}
                    photoinvalid = {props.photoinvalid}
                    listofdoctypes = {props.listofdoctypes}
                    docexpirydate = {props.docexpirydate}
                    dateofbirth = {props.dateofbirth}
                    idtype = {props.idtype}
                    sector = {props.sector}
                    idnumber = {props.idnumber}
                    docissuedate = {props.docissuedate}
                    signature = {props.signature}
                    signatureinvalid = {props.signatureinvalid}
                    signaturefilename = {props.signaturefilename}
                    utilities = {props.utilities}
    
                    handleInput = {props.handleInput}
                    handleChange = {props.handleChange}
                    handleFileUpload = {props.handleFileUpload}
                />

                <div className="col-lg-12 px-0">
                    <this.Signature
                        photofilename = {props.photofilename}
                        photo = {props.photo}
                        validate = {props.validate}
                        photoinvalid = {props.photoinvalid}
                        listofdoctypes = {props.listofdoctypes}
                        docexpirydate = {props.docexpirydate}
                        dateofbirth = {props.dateofbirth}
                        idtype = {props.idtype}
                        sector = {props.sector}
                        idnumber = {props.idnumber}
                        docissuedate = {props.docissuedate}
                        signature = {props.signature}
                        signatureinvalid = {props.signatureinvalid}
                        signaturefilename = {props.signaturefilename}
                        utilities = {props.utilities}
                        size={"95"}
        
                        handleInput = {props.handleInput}
                        handleFileUpload = {props.handleFileUpload}
                    />
                </div>
            </div>
        )
    };

    NairaSympbol = () => {
        return (<span>₦</span>)
    };

    Modal = (props) => {
        let showhideclassname = props.showmodal ? "modal show modalsupport animated fadeIn delay-0.5s" : "modal hide animated fadeOut delay-3s";

        return (
            <div className={showhideclassname}>
                {/* <div className={props.modalwidth + " modal-dialog"} style={{maxWidth: props.maxwidth}}> */}
                        
                        {/* <div className={props.contentpadding ? props.contentpadding + " py-3 modal-content" : "py-3 px-3 modal-content"}>
                        <div className="modal-header pt-2 border-0 mb-3">
                            <div className="modal-title font-weight-bold">{props.modaltitle}</div>
                            <button type="button" className="close" onClick={props.closeModal}>&times;</button>
                        </div> */}

                    <div className="modal-body pt-0">
                        <props.child />
                    </div>
                {/* </div> */}
            </div>
        )
    };

    static UtilitiesGear = (props) => {
        
        return (
            <img className="rotating" src="/assets/imgs/loaders/spinner.svg" alt="loader" style={props.style}/>
        )
    };

    Gear = (props) => {
        
        return (
            <img className="rotating" src="/assets/imgs/loaders/spinner.svg" alt="loader" style={props.style}/>
        )
    };

    GearBlack = () => {
        return (
            <img className="rotating" src="/assets/imgs/loaders/spinner.svg" alt="loader" style={{width: "20px", paddingTop: "2px", float: "right"}}/>
        )
    };

    Notify = (msg, type, done) => {//alert, success, warning, error, info/information
        new Noty({
            type: type,
            layout: 'topRight',
            theme: 'nest',
            text: msg,
            timeout: '5000',
            progressBar: true,
            closeWith: ['click'],
            killer: true,
            callbacks: {
                beforeShow: function() {
                   // console.log('beforeend', 'Preparing... ⏱<br/>');
                },
                onShow: function() {
                   // console.log('beforeend', 'Showed ✨<br/>');
                },
                onHover: function() {
                   // console.log('beforeend', 'Hovered 👀<br/>');
                },
                onClick: function() {
                  // console.log('beforeend', 'Clicked ✅<br/>');
                },
                onClose: function() {
                   // console.log('beforeend', 'Bye 👋🏻<br/>');
                   if(done) return done.call();
                }
            },
        }).show();
    };

    dataURLtoFile = (dataurl, filename="livelinesscheck.png") => {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    };

    fetchDocumentTypes = (props, httpservice) => {
        httpservice.unAuthGet("signup/get_doc_types")
            .then(response => {
                if(!response.error) props.setState({listofdoctypes: response.result});
            })
            .catch(error => {
                console.log("error: ", error);
            });
    };

    packagePhoneNumber = (phone) => {
        switch (phone.length) {
            case 13:
                if(phone.startsWith("234")) {
                    return phone;
                }
                break;
            case 11:
                if(phone.startsWith("0")) {
                    phone = "234" + phone.slice(1);
                    return phone;
                }
                break;
            case 14:
                if(phone.startsWith("+")) {
                    phone = phone.slice(1);
                    return phone;
                }
                break;
            default:
                return phone;
        }
    };

    getYesterdaysDate = () => {
        var currentDate = new Date();
        var yesterdayDate = currentDate.setDate(currentDate.getDate()- 30);
        return new Date(yesterdayDate).toISOString().split("T")[0];
    };

    activateNextInput = () => {
        let otp = document.querySelectorAll('.code-form');

        otp.forEach(guy => {
            for(let pin of guy.children) {
                pin.onkeyup = function() {
                    if(pin.nextElementSibling) {
                        pin.nextElementSibling.focus();
                    }
                }
            }
        })
    };

    toTitleCase = (text) => {
        if(text) {
            let value = text[0].toUpperCase() + text.substring(1).toLowerCase();

            return value;
        }
    };

    validPassword = (password) => {
        let re = /^.*(?=.{8,})(?=.*[a-z])(?=.*\d)(?=.*[A-Z])[0-9a-zA-Z](?=.*[!#$@%&?~')(*+-,./:[;=<>_`{}|"]).*$/;
        return re.test(password);
    };

    isAlphaNumeric = (string) => {
        let re = /^.*(?=.*[a-z])(?=.*\d)/;
        return re.test(string);
    };

    isAlphabetic = (string) => {
        let re = /^[a-zA-Z]+$/;
        return re.test(string);
    };

    isNumeric = (string) => {
        let re = /^\d+$/;
        return re.test(string);
    };

    parseJwt = (token) => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    };

    urlSearchParams = () => new URLSearchParams(window.location.search);

    scrollToTopFunction = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };

    static mobileCheck = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };

    handleSessionCheck = () => {
        clearTimeout(sessiontimeoutid);

        sessiontimeoutid = setTimeout(() => {            
            //store.clearAll();

            //window.location = "/";
        }, 5 * 60 * 1000);
    };

    static handleRecordingStop = (props) => {
        
        Utilities.count = 0;

        if ('speechSynthesis' in window) {
            setTimeout(() => {
                recorder.stopRecording(Utilities.stopRecordingCallback(props));
            }, 100);
        } else {
            recorder.stopRecording(Utilities.stopRecordingCallback(props));
        }
    };

    static stopRecordingCallback = async (props) => {

        props.setState({recordingstarted: false});

        var video = document.getElementById('live-video');

        video.src = video.srcObject = null;
        video.muted = false;
        video.volume = 1;

        console.log("video.video(): ", video);

        console.log("recorder.getBlob(): ", recorder.getBlob())

        //video.src = window.URL.createObjectURL(recorder.getBlob());

        console.log("Utilities.recorder: ", recorder);

        // let blob = Utilities.recorder.getBlob();

        // console.log("blob1: ", blob);
    
        // var file = new File([blob], "livevideo.mp4", {type: "video/mp4"});

        // props.setState({customervideo: file});

        // store.set("livevideo", file);

        // //var mediaStream = new MediaStream();
        // video.srcObject = Utilities.recorder.camera;

        // console.log("mediaStream: ", Utilities.recorder.camera);

        //window.URL.createObjectURL(new Blob([binaryData]));

        //video.src = window.URL.createObjectURL(Utilities.recorder.getBlob());

        //video.src = Utilities.recorder.camera;
        //video.play();



        // var mediasource = new MediaSource();
        // var blob = Utilities.recorder.getBlob();

        // if ('srcObject' in video) {
        //     try {
        //         video.srcObject = mediasource;
        //     } catch (err) {
        //         if (err.name != "TypeError") {
        //             throw err;
        //         }
        //       // Even if they do, they may only support MediaStream
        //         //video.src = URL.createObjectURL(blob);
        //     }
        // } else {
        //     //video.src = URL.createObjectURL(blob);
        // }

        // //var file = new File([mediasource], "livevideo.mp4", {type: "video/mp4"});
        // var file = new File([blob], "livevideo.mp4", {type: "video/mp4"});

        // props.setState({customervideo: file});

        // store.set("livevideo", file);
            
        recorder.camera.stop();
        recorder.destroy();
        recorder = null;

        Utilities.count = 0;
    };

    static captureCamera = (callback) => {
        navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(function(camera) {
            callback(camera);
        }).catch(function(error) {
            //alert('Unable to proceed. Check that your camera is enabled.');
            console.error(error);
        });
    };

    static waitOneSec = (messages, props) => {

        if(Utilities.count === 3) {
            clearTimeout(Utilities.timeout);
            
            if(recorder) Utilities.handleRecordingStop(props);
        }

        if(messages && messages.length && props.state.recordingstarted) {
            let msg = new SpeechSynthesisUtterance();

            msg.text = messages[Utilities.count];
        
            window.speechSynthesis.speak(msg);
        
            msg.onend = function() {
                Utilities.timeout = setTimeout(() => {
        
                    if(Utilities.count !== 3) {
                        Utilities.waitOneSec(messages, props);
                    }

                    if(Utilities.count === 3) {
                        msg.volume = 1;
                        clearTimeout(Utilities.timeout);
                        
                        if(recorder) Utilities.handleRecordingStop(props);
                    }
                }, 50);

                Utilities.count++;
            }.bind(props);
        }
    };

    static startLivelinessCheckVideo = (props) => {

        var video = document.getElementById('live-video');

        props.setState({recordingstarted: true, captureallowed: true});

        Utilities.captureCamera(function(camera) {
            video.muted = true;
            video.volume = 0;
            video.srcObject = camera;
    
            recorder = window.RecordRTC(camera, {
                type: 'video',
                mimeType: 'video/mp4'
            });

            console.log("almighty: ", recorder);

            recorder.camera = camera;

            video.oncanplay = function() {
                if ('speechSynthesis' in window && !Utilities.mobileCheck()) {
                    setTimeout(() => {
                        Utilities.waitOneSec(Utilities.messages, props);
                    });
                } else {
                    setTimeout(() => {
                        Utilities.handleRecordingStop(props);
                    }, 7000);
                }

                recorder.startRecording();

                // if(recorder) {
                //     recorder.startRecording();
    
                //     recorder.camera = camera;
                // }
            }.bind(props);
       
        }.bind(props));
    };

    static startLivelinessCheckImage = (props) => {
        
        var width = 300; // We will scale the photo width to this
        var height = 0; // This will be computed based on the input stream

        var streaming = false;

        let video = document.getElementById('video');
        let canvas = document.getElementById('canvas');
    
        // access video stream from webcam
        window.navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true
        })
        // on success, stream it in video tag
        .then(function(stream) {
            props.setState({captureallowed: true});
            store.set("captureallowed", true);

            console.log("stream: ", stream);

            video.srcObject = stream;
            video.play();
        }.bind(props))
        .catch(function(err) {
            props.setState({captureallowed: false});
            store.set("captureallowed", false);

            console.log("An error occurred: " + err);
        }.bind(props));

        if(video) {
            video.addEventListener('canplay', function(ev) {
                if (!streaming) {
                    height = video.videoHeight / (video.videoWidth / width);
        
                    if (isNaN(height)) {
                        height = width / (4 / 3);
                    }
        
                    video.setAttribute('width', width);
                    video.setAttribute('height', height);
                    canvas.setAttribute('width', width);
                    canvas.setAttribute('height', height);
                    streaming = true;
                }
            }, false);
        
            Utilities.clearPhoto();
        }
    };

    static clearPhoto = () => {
        let canvas = document.getElementById('canvas');
        let photo = document.getElementById('photo');

        var context = canvas.getContext('2d');
        context.fillStyle = "#AAA";
        context.fillRect(0, 0, canvas.width, canvas.height);
    
        var data = canvas.toDataURL('image/png');
        photo.setAttribute('src', data);
    };

    static takePicture = (props) => {

        var width = 300; // We will scale the photo width to this
        var height = document.getElementById("video").getAttribute("height"); // This will be computed based on the input stream

        let video = document.getElementById('video');
        let canvas = document.getElementById('canvas');
        let photo = document.getElementById('photo');

        var context = canvas.getContext('2d');
        if (width) {
            canvas.width = width;
            canvas.height = height;

            context.drawImage(video, 0, 0, width, height);
            var data = canvas.toDataURL('image/png');

            if(props.this) {
                props.this.setState({customerphoto: data});
            } else {
                props.setState({customerphoto: data});
            }

            store.set("customerphoto", data);

            photo.setAttribute('src', data);
        } else {
            Utilities.clearPhoto();
        }
    };

    validmail = (email) => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    //static recorder = {};

    static messages = [
        "Look straight into the camera, and say ONE.",
        "Turn right, and say TWO.",
        "Then turn left, and say THREE",
    ];
    static count = 0;
    static timeout;
}

export {Utilities};