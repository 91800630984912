import React from 'react';
import {Link, Route, Switch} from "react-router-dom";
import {Utilities} from "./utilities";
import HttpService from '../httpservice';
import store from "store";
import SupportPage, { SupportBranch, SupportEmail, SupportFaq } from './supportCenter';
import NProgress from 'nprogress';

let httpservice = new HttpService();
let utilities = new Utilities();

let count = 0;
let recorder = {};
let callbackfunction = {};
let timeout;
let almightyblob;

function retrieveBlob(blob) {

    almightyblob = recorder.getBlob();

    callbackfunction.callback(almightyblob, blob);
};

class Login extends React.Component {
    constructor() {
        super();

        this.state = {
            phone: "",
            password: "",
            validate: false,
            ajaxcalled: false,
            targetmodal: "",
            showmodal: false,

            passwordtype: "password",
            profilecomplete: false,
            pendingtype: [],

            photofilename: "",
            photo: "",
            photoinvalid: "",
            listofdoctypes: [],
            listofsupportemails: [],
            docexpirydate: "",
            dateofbirth: "",
            idtype: "",
            idnumber: "",
            docissuedate: "",
            captureallowed: false,
            voiceallowed: false,
            ismobile: false,
            customervideo: "",
            recordingstarted: false,
            customerphoto: "",
            canproceed: false
        }
    };

    showModal = (targetmodal) => {
        this.setState({targetmodal: targetmodal, showmodal: true});
    };

    hideModal = () => {
        this.setState({showmodal: false});
    };

    handleInput = (e) => {
        const {name, value} = e.target;

        this.setState({
            [name]: name === "password" ? value.trim() : value
        });
    };

    handleChange = (name, date) => {
        this.setState({
            [name]: date
        });
    };

    handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            this.handleSignIn();
        }
    };

    fetchUser = () => {

        httpservice.authGet("auth/check")
            .then(response => {
                this.setState({ajaxcalled: false});

                if(!response.error) {
                    store.set("user", response.data);
                    
                    if(response.data.profilecomplete === "DISAPPROVED" && response.data.documentstatus === "DISAPPROVED") { //DISAPPROVED
                        let newlist = this.state.pendingtype;

                        newlist.push("iddocument");

                        this.setState({profilecomplete: true, pendingtype: newlist});

                        store.set("profilecomplete", true);
                        store.set("pendingtype", newlist);

                        if(!this.state.listofdoctypes.length) {
                            utilities.fetchDocumentTypes(this, httpservice);
                        }

                    } 
                    
                    if(response.data.profilecomplete === "DISAPPROVED" && response.data.signaturestatus === "DISAPPROVED") {
                        let newlist = this.state.pendingtype;
                        newlist.push("signature");

                        this.setState({profilecomplete: true, pendingtype: newlist});

                        store.set("profilecomplete", true);
                        store.set("pendingtype", this.state.pendingtype.push("signature"));

                    } 
                    
                    if(response.data.profilecomplete === "DISAPPROVED" && response.data.photostatus === "DISAPPROVED") {
                        let newlist = this.state.pendingtype;
                        newlist.push("livelinesscheckimage");

                        this.setState({profilecomplete: true, pendingtype: newlist});

                        store.set("profilecomplete", true);
                        store.set("pendingtype", newlist);

                        if(newlist[0] === "livelinesscheckimage") Utilities.startLivelinessCheckImage(this);

                    } 

                    if(response.data.livelinesschecked === "DISAPPROVED") {
                        let newlist = this.state.pendingtype;
                        newlist.push("livelinesscheckvideo");

                        this.setState({profilecomplete: true, pendingtype: newlist});

                        store.set("profilecomplete", true);
                        store.set("pendingtype", newlist);

                        if(Utilities.mobileCheck()) {
                            this.setState({ismobile: true});
                        }

                        if ('speechSynthesis' in window && !Utilities.mobileCheck()) {

                            this.setState({voiceallowed: true});
                
                        } else {
                            this.setState({voiceallowed: false});
                        }

                        if(newlist[0] === "livelinesscheckvideo") this.startLivelinessCheckVideo();
                    } 

                    if(response.data.profilecomplete !== "DISAPPROVED") {
                        this.props.history.push("/dashboard/Dashboard");
                        store.remove("profilecomplete");
                        store.remove("pendingtype");
                    } else {
                        utilities.Notify("Your account is pending approval. Try again later.", "error");
                    }

                } else {
                    utilities.Notify(response.message, "error");
                    this.setState({ajaxerror: response.result, ajaxcalled: false});
                }
            })
            .catch(error => {
                this.setState({ajaxcalled: false})
                console.log("error: ", error);
            });
    };

    handleSignIn = () => {

        if(!this.state.phone) {
            this.setState({validate: true});
        } else {

            this.setState({ajaxcalled: true, validate: false});
            let phone = utilities.packagePhoneNumber(this.state.phone);
            phone = this.state.phone;
        
            httpservice.unAuthPost("auth/sign_in", {phone: phone, password: this.state.password})
                .then(response => {
                    if(response.error) {
                        this.setState({ajaxcalled: false});

                        this.setState({ajaxerror: response.message || response.error});
                    } else if(response.token) {
                        store.set("token", response.token);
                        store.remove("signupstage");

                        this.fetchUser();
                    } else if(response.password_change_required) {
                        this.props.history.push("/resetpassword");
                        store.set("token", response.temp_token);
                    } else {
                        this.setState({ajaxcalled: false});
                        this.setState({ajaxerror: "Invalid login credentials Verify and try again."});
                    }
                })
                .catch(error => {
                    this.setState({ajaxcalled: false});
                    console.log("error: ", error);
                })
        }
    };

    handleFileUpload = (e) => {
        e.persist();

        let file = e.target.files[0];

        if(file && (file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png" || file.type === "application/pdf")) {
            let reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onload = function() {
                this.setState({
                    [e.target.name]: file,
                    [e.target.name + "invalid"] : false,
                    [e.target.name + "string"]: reader.result,
                    [e.target.name + "filename"] : file.name
                });
            }.bind(this);
        } else {
            this.setState({
                [e.target.name + "invalid"] : true
            });
        }
    };

    handleDocUpload = () => {
        if(
        !this.state.photo
        || !this.state.idnumber
        || !this.state.idtype
        || !this.state.docissuedate
        || !this.state.docexpirydate
        ) {
            this.setState({validate: true});
        } else {
            this.setState({validate: false, ajaxcalled: true});

            let photo = utilities.dataURLtoFile(this.state.photostring, "IdentityDoc.png");

            let formdata = new FormData();

            formdata.append("document", photo);
            formdata.append("document_number", this.state.idnumber);
            formdata.append("document_type_id", this.state.idtype);
            formdata.append("document_issue_date", this.state.docissuedate);
            formdata.append("document_expiry_date", this.state.docexpirydate);
            formdata.append("customer_id", store.get("user").id);

            httpservice.authPost("signup/create_document", formdata, true)
                .then(response => {
                    this.setState({ajaxcalled: false});

                    if(response.status === 401) {
                        this.signOut();

                    } else if(response.error) {
                        utilities.Notify(response.message, "error");
                    } else {
                        utilities.Notify("Document successfully uploaded.", "success");

                        let inputs = document.getElementsByTagName('input');
                        for(var i = 0; i < inputs.length; i++) {
                            inputs[i].value = "";
                        }

                        this.setState({
                            idnumber: "",
                            idtype: "",
                            docissuedate: "",
                            docexpirydate: "",
                            photo: "",
                            photostring: "",
                            photofilename: "",
                        });

                        let oldlist = this.state.pendingtype;

                        let newlist = oldlist.filter(value => {
                            return value !== "iddocument";
                        });

                        setTimeout(() => {
                            if(newlist.length) {
                                this.setState({pendingtype: newlist});
                                store.set("pendingtype", newlist);
    
                                if(newlist[0] === "livelinesscheckvideo") {
                                    this.setState({recordingstarted: false, captureallowed: true});
                                } else if(newlist[0] === "livelinesscheckimage") {
                                    Utilities.startLivelinessCheckImage(this);
                                }
    
                            } else {
                                this.props.history.push("/dashboard/Dashboard");
                                store.remove("profilecomplete");
                                store.remove("pendingtype");
                            }
                        }, 1500);
                    }
                })
                .catch(error => {
                    this.setState({ajaxcalled: false});
                    console.log("error: ", error);
                });
            }
    };

    handleSignatureUpload = () => {
        if(!this.state.signature) {
            this.setState({validate: true});
        } else {
            this.setState({validate: false, ajaxcalled: true});
            let formdata = new FormData();

            //let signature = utilities.dataURLtoFile(store.get("personaldetails").signaturestring, "signature.png");

            formdata.append("signature", this.state.signature);
            formdata.append("customer_id", store.get("user").id);
            
            httpservice.authPost("signup/create_signature", formdata, true)
                .then(response => {
                    if(response.status === 401) {
                        this.signOut();

                    } else {
                        switch (response.error) {
                            case true:
                                this.setState({ajaxcalled: false});
                                utilities.Notify(response.message, "error");
                                break;
                            case 401:
                                this.signOut();
                                break;
                            case false:
                                this.setState({ajaxcalled: false});
    
                                utilities.Notify("Upload successful.", "success");

                                let oldlist = this.state.pendingtype;

                                let newlist = oldlist.filter(value => {
                                    return value !== "signature";
                                });

                                setTimeout(() => {
                                    if(newlist.length) {
                                        this.setState({pendingtype: newlist});
                                        store.set("pendingtype", newlist);
            
                                        if(newlist[0] === "livelinesscheckvideo") {
                                            this.setState({recordingstarted: false, captureallowed: true});
                                        } else if(newlist[0] === "livelinesscheckimage") {
                                            Utilities.startLivelinessCheckImage(this);
                                        }
            
                                    } else {
                                        this.props.history.push("/dashboard/Dashboard");
                                        store.remove("profilecomplete");
                                        store.remove("pendingtype");
                                    }
                                }, 1500);

                                break;
                            default:
                                this.setState({ajaxcalled: false});
                                utilities.Notify("Unable to process your request. Please try again later.", "error");
                                break;
                        }
                    }
                })
                .catch(error => {
                    this.setState({ajaxcalled: false})
                    console.log("error: ", error);
                });
        }
    };

    switchToSignIn = () => {
        store.remove("profilecomplete");
        store.remove("pendingtype");

        this.setState({profilecomplete: false, pendingtype: []});
    };

    determineSignUp = () => {
        !"getUserMedia" in window.navigator ? this.setState({ajaxerror: "Live image and video capture are required during signup. Your browser does not support them. Please use another browser. Firefox is recommended."}) : this.props.history.push("/signup")
    };

    LoginScreen = () => {
        return (
            <div className="form-inner mx-auto">
                <Switch>
                    <Route path='/' exact render={() => (
                    <form className="form-guy mx-auto">

                    <p className="padding2">&nbsp;</p>
                    <p className="padding2">&nbsp;</p>

                    <div className="form-header">
                        <img src="assets/imgs/npf-logo-main.png" alt="" className="sign-up-icon w-20" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="" className="small darkcolor mb-1">Phone or Account Number</label>
                        <input placeholder="Phone or Account number" name="phone" value={this.state.phone} onChange={this.handleInput} onKeyPress={this.handleKeyPress} maxLength="14" type="number" className="enterkeyevent form-control" />
                        <div className="text-danger small">{(!this.state.phone && this.state.validate) && "Required."}</div>
                    </div>

                    <div className="form-group" >
                        <label htmlFor="" className="small darkcolor mb-1">Password</label>
                        <input name="password" value={this.state.password} onChange={this.handleInput} onKeyPress={this.handleKeyPress} type={this.state.passwordtype} className="enterkeyevent form-control" data-validation="length" data-validation-length="min8" />

                        <span className="icon-correction1 eye-icon cp" onClick={() => {this.setState({passwordtype: this.state.passwordtype === "text" ? "password" : "text"})}}>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-eye-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                <path fillRule="evenodd" d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                            </svg>
                        </span>
                    </div>

                    <button disabled={this.state.ajaxcalled} className="enterkeyevent login-btn small" type="button" onClick={this.handleSignIn}>
                        <span className="w-85 font-weight-bold ml-50">Sign In</span>

                        <span className="w-15 h-100 slant-arrow">
                            {
                                this.state.ajaxcalled 
                                    ? 
                                    <utilities.Gear style={{width: "26px", marginTop: "9px"}} /> 
                                    : 
                                    <svg width="1.5em" height="100%" viewBox="0 0 16 16" className="bi bi-arrow-right-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path fillRule="evenodd" d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"/>
                                    </svg>
                            }
                        </span>

                    </button>

                    <div className="text-danger small text-center mt-4">
                        {!this.state.ajaxcalled && this.state.ajaxerror && this.state.ajaxerror}
                    </div>

                    <div className="row pt-4 text-center">
                        <div className="col-lg border-right">
                            <Link to="/forgotpassword" className="greencolor font-weight-bold small">Forgot your password</Link>
                        </div>

                        <div className="col-lg">
                            <a onClick={this.determineSignUp} className="greencolor font-weight-bold small cp">Create a new account</a>
                        </div>
                        <div className="col-12 mt-2">
                            <a onClick={this.fetchSupportEmails} className="cp greencolor font-weight-bold small">Need Help?</a>
                        </div>
                    </div>

                    {/* <div className="versionnumber">Version 2</div> */}

                    </form>)}/>
                    <Route path="/support" render={() => <SupportPage showSuppotMails={() => this.showModal("supportmailsmodal")} switchTab={(path) => this.props.history.push("/" + path)} isGuest history={this.props.history} />} />
                    <Route path='/supportfaq' render={({history}) => <SupportFaq isGuest handleBackButton={history.goBack} />} />
                    <Route path='/supportemail' render={({history}) => <SupportEmail showSuppotMails={() => this.showModal("supportmailsmodal")} isGuest handleBackButton={history.goBack} />} />
                    <Route path='/supportbranch' render={({history}) => <SupportBranch isGuest handleBackButton={history.goBack} />} />
                    <Route path="/comingsoon" render={() => <utilities.ComingSoonPage isGuest switchTab={(path) => this.props.history.push("/" + path)} history={this.props.history} />} />
                </Switch>
            </div>
        )
    };

    handleVideoSubmit = () => {

        this.setState({ajaxcalled: true});
        let formdata = new FormData();

        formdata.append("video", this.state.customervideo);
        formdata.append("customer_id", store.get("user").id);

        httpservice.authPost("signup/create_video", formdata, true)
            .then(response => {
                this.setState({ajaxcalled: false});

                if(response.error) {
                    utilities.Notify(response.message, "error");

                } else if(response.status === 401) {
                    this.signOut();

                } else {
                    utilities.Notify("Video uploaded successfully.", "success");
                    this.props.history.push("/dashboard/Dashboard");

                    store.remove("profilecomplete");
                    store.remove("pendingtype");
                    store.remove("livevideo");
                }
            })
            .catch(error => {
                this.setState({ajaxcalled: false});
                console.log("error: ", error);
            });
    };

    handlePhotoSubmit = () => {
        this.setState({ajaxcalled: true});
        let formdata = new FormData();

        let photo = utilities.dataURLtoFile(this.state.customerphoto);

        formdata.append("photo", photo);
        formdata.append("photo_type", "Liveliness check image");
        formdata.append("photo_number", 1);
        formdata.append("customer_id", store.get("user").id);

        httpservice.authPost("signup/create_photo", formdata, true)
            .then(response => {
                this.setState({ajaxcalled: false});

                if(response.error) {
                    utilities.Notify(response.message, "error");

                } else if(response.status === 401) {
                    this.setState({customerphoto: ""});

                    this.signOut();

                } else {
                    utilities.Notify("Image uploaded successfully.", "success");

                    let oldlist = this.state.pendingtype;

                    let newlist = oldlist.filter(value => {
                        return value !== "livelinesscheckimage";
                    });

                    setTimeout(() => {
                        if(newlist.length) {
                            this.setState({pendingtype: newlist});
                            store.set("pendingtype", newlist);

                            if(newlist[0] === "livelinesscheckvideo") {
                                this.setState({recordingstarted: false, captureallowed: true});
                            } else if(newlist[0] === "livelinesscheckimage") {
                                Utilities.startLivelinessCheckImage(this);
                            }

                        } else {
                            this.props.history.push("/dashboard/Dashboard");
                            store.remove("profilecomplete");
                            store.remove("pendingtype");
                        }
                    }, 1500);
                }
            })
            .catch(error => {
                this.setState({ajaxcalled: false});
                console.log("error: ", error);
            });
    };

    signOut = () => {
        store.clearAll();
        this.switchToSignIn();
    };

    fetchSupportEmails = () => {
        NProgress.inc();

        httpservice.authGet("support/get_support_emails")
            .then(response => {
                NProgress.done();
                if(response.result) {

                    this.setState({listofsupportemails: response.result});

                    this.props.history.push("/Support");

                } else if(response.status === 401) this.handleSignOut();
            })
            .catch(error => {
                NProgress.done();
                utilities.Notify(error.message, "error");
            });
    };

    handleRecordingStop = () => {
        
        if ('speechSynthesis' in window) {
            setTimeout(() => {
                recorder.stopRecording(retrieveBlob);
            }, 100);
        } else {
            recorder.stopRecording(retrieveBlob);
        }
    };

    stopRecordingCallback = (almightyblob, actualblob) => {
        console.log("almightyblob: ", almightyblob);

        this.setState({recordingstarted: false});

        var video = document.getElementById('live-video');

        video.src = video.srcObject = null;
        video.muted = false;
        video.volume = 1;

        video.src = actualblob; //URL.createObjectURL(this.getBlob());
    
        var file = new File([almightyblob], "livevideo.mp4", {type: "video/mp4"});

        this.setState({customervideo: file});

        store.set("livevideo", file);

        recorder.camera.stop();
        recorder.destroy();
        recorder = null;

        count = 0;
    };

    captureCamera = (callback) => {
        navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(function(camera) {
            callback(camera);
        }).catch(function(error) {
            //alert('Unable to proceed. Check that your camera is enabled.');
            console.error(error);
        });
    };

    waitOneSec = (messages) => {

        if(count === 3) {
            clearTimeout(timeout);
            
            if(recorder) this.handleRecordingStop();
        }

        if(messages && messages.length && this.state.recordingstarted) {
            let msg = new SpeechSynthesisUtterance();

            msg.text = messages[count];
        
            window.speechSynthesis.speak(msg);
        
            msg.onend = function() {
                timeout = setTimeout(() => {
        
                    if(count !== 3) {
                        this.waitOneSec(Utilities.messages);
                    }

                    if(count === 3) {
                        msg.volume = 1;
                        clearTimeout(timeout);
                        
                        if(recorder) this.handleRecordingStop();
                    }
                }, 50);

                count++;
            }.bind(this);
        }
    };

    startLivelinessCheckVideo = () => {

        var video = document.getElementById('live-video');

        this.setState({recordingstarted: true, captureallowed: true});
        callbackfunction.callback = this.stopRecordingCallback;

        this.captureCamera(function(camera) {
            video.muted = true;
            video.volume = 0;
            video.srcObject = camera;
    
            recorder = window.RecordRTC(camera, {
                type: 'video',
                mimeType: 'video/mp4'
            });

            recorder.camera = camera;

            video.oncanplay = function() {
                if ('speechSynthesis' in window && !Utilities.mobileCheck()) {
                    setTimeout(() => {
                        this.waitOneSec(Utilities.messages);
                    });
                } else {
                    setTimeout(() => {
                        this.handleRecordingStop();
                    }, 7000);
                }

                if(recorder) {
                    recorder.startRecording();
    
                    recorder.camera = camera;
                }
            }.bind(this);
       
        }.bind(this));
    };

    SupportMailsModal = () => {
        return (
            <div style={{width: "400px", height: "300px", top: "100px", overflowY: "scroll"}} id={(this.state.showmodal && this.state.targetmodal === "supportmailsmodal") ? "showmodal" : ""} className="trxnmodal p-4 bvnverifiedmodal animated fadeIn delay-0.5s">

                <div className="row">
                    <div className="col"><h1 className="font-weight-bold gen-text-color"></h1></div>

                    <div className="col text-right"><a onClick={this.hideModal} className="ft20 gen-text-color cp closecontactus">&times;</a></div>
                </div>

                <div className="text-center">
                    <div className="choice-menu">
                        <div className="gen-text-color font-weight-bold mb-4">
                            <h2 className="mb-2 font-weight-bold">Support Mails</h2>
                            <p>Click any of the email addresses below to send a support mail.</p>
                        </div>
                    </div>
                    <div>
                        {
                            this.state.listofsupportemails.map(mail => {
                                return (
                                    <a key={mail.value} href={"mailto:"+mail.value} className="call-center-detail" style={{padding: "20px 20px 20px 20px"}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="mr-3 bi bi-envelope" viewBox="0 0 16 16">
                                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"/>
                                        </svg>
                                        <span>{mail.value}</span>
                                    </a>
                                )
                            })
                        }

                    </div>
                    <p className="mt-3 mb-2">Read Our <span onClick={() => this.switchSubTab("Support","SupportFaq")} className="cp greencolor" style={{textDecoration: "underline"}}>FAQs.</span></p>
                </div>
            </div>
        )
    };

    LivelinessCheckVideo = () => {

        return (
            <section className=" mx-auto">
                <div className="gen-text-color font-weight-bold mb-5">
                    <h1 className="mb-4 font-weight-bold text-center">Liveliness Check</h1>

                    {!this.state.captureallowed && <p className="small font-weight-bold text-danger">You need to enable camera and microphone to perform this operation.</p>}
                    {!this.state.captureallowed && <p className="small font-weight-bold text-danger">Click on the lock icon in the address bar. Then select allow camera and microphone from the menu. Then refresh your page to effect the change.</p>}

                    {
                        this.state.captureallowed
                        &&
                        !this.state.voiceallowed
                        &&
                        Utilities.mobileCheck()
                        &&
                        <div>
                            <p className="small font-weight-bold">Click on Start Recording to make a 5 second verification video.</p>
                            <p className="small font-weight-bold">The video must show the front and two sides of your head.</p>
                            <p className="small font-weight-bold">Follow the steps below to perform this verification:</p>
                            <p>1. Once the recording starts, look straight into the camera.</p>
                            <p>2. Say <b>ONE.</b></p>
                            <p>3. Turn right.</p>
                            <p>4. Say <b>TWO.</b></p>
                            <p>5. Turn left.</p>
                            <p>6. Say <b>THREE.</b> Then click on Stop Recording.</p>
                        </div>
                    }

                    {
                        !this.state.voiceallowed && this.state.captureallowed && !this.state.ismobile && <div className="text-danger">Your browser does not support text to speech. Switch to Chrome or Firefox or continue by following the instructions above.</div>
                    }

                    {
                        this.state.voiceallowed && this.state.captureallowed && <div>Follow the voice prompt after you click on "Start Recording".</div>
                    }
                    
                </div>

                <div className="w400 mx-auto">
                    <div className="">
                        <button disabled={this.state.recordingstarted || !this.state.captureallowed} id="btn-start-recording" className="login-btn small my-4" type="button" onClick={() => {this.startLivelinessCheckVideo()}}>

                            <span className="w-85 font-weight-bold ml-50">Start Recording</span>

                            <span className="w-15 h-100 slant-arrow">
                                <svg width="1.5em" height="100%" viewBox="0 0 16 16" className="bi bi-arrow-right-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path fillRule="evenodd" d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"/>
                                </svg>
                            </span>
                        </button>
                    </div>

                    <div className="">
                        <button disabled={!this.state.recordingstarted} id="btn-start-recording" className="login-btn small my-4" type="button" onClick={() => {this.handleRecordingStop()}}>

                            <span className="w-85 font-weight-bold ml-50">Stop Recording</span>

                            <span className="w-15 h-100 slant-arrow">
                                <svg width="1.5em" height="100%" viewBox="0 0 16 16" className="bi bi-arrow-right-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path fillRule="evenodd" d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"/>
                                </svg>
                            </span>
                        </button>
                    </div>
                </div>

                <div className="mx-auto text-center">
                    <video id="live-video" controls autoPlay playsInline>Video Stream is not available</video>
                </div>

                {!store.get("user") && 
                    <button type="button" 
                        disabled={this.state.ajaxcalled || !this.state.customervideo || this.state.recordingstarted} 
                        className="chat-btn" style={this.state.ajaxcalled ? {padding: "16px 10px", left: "40%", background: "#29C186"} : {left: "40%", background: "#29C186"}} 
                        onClick={this.state.handler}
                        >
                        {
                            this.state.ajaxcalled 
                                ? 
                                <Utilities.UtilitiesGear style={{marginTop: "-5px"}} /> 
                                : 
                                <svg width="2.5em" height="2.5em" style={{marginLeft: "-11px", marginTop: "-5px"}} viewBox="0 0 16 16" className="bi bi-arrow-right-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                </svg>
                        }
                    </button>
                }

            </section>
        )
    };

    render() {
        return (
    
            <article className="animated fadeIn delay-0.5s page-bg">
        
                <section className="mx-auto">
                    <div className="wrapper">

                        {utilities.urlSearchParams().get("source") !== "app" && 
                            <div className="image-holder">
                                <img src="assets/imgs/herohalf.png" alt="" />
                            </div>
                        }

                        {!this.state.profilecomplete && <this.LoginScreen props={this} />}

                        {this.state.profilecomplete && this.state.pendingtype.includes("iddocument") && this.state.pendingtype[0] === "iddocument" && 
                            <div className="form-inner mx-auto mobrow mob_row"> 
                                <p className="padding2">&nbsp;</p>
                                <p className="padding2">&nbsp;</p>

                                <div className="form-header">
                                    <img src="assets/imgs/npf-logo-main.png" alt="" className="sign-up-icon w-20" />
                                </div>

                                <p className="padding2">&nbsp;</p>

                                <p className="text-danger">Your ID document was rejected. Hence you are required to re-upload it using the form below.</p>

                                <p className="padding2">&nbsp;</p>

                                <utilities.IdDocument
                                    this={this}
                                    photofilename = {this.state.photofilename}
                                    photo = {this.state.photo}
                                    validate = {this.state.validate}
                                    photoinvalid = {this.state.photoinvalid}
                                    listofdoctypes = {this.state.listofdoctypes}
                                    docexpirydate = {this.state.docexpirydate}
                                    dateofbirth = {this.state.dateofbirth}
                                    idtype = {this.state.idtype}
                                    idnumber = {this.state.idnumber}
                                    docissuedate = {this.state.docissuedate}
                                    utilities = {utilities}
                    
                                    handleInput = {this.handleInput}
                                    handleFileUpload = {this.handleFileUpload}
                                    handleChange = {this.handleChange}
                                />

                                {/* <div className="col-lg cp text-center" onClick={this.switchToSignIn}>
                                    <a className="greencolor font-weight-bold small">Sign In</a>
                                </div> */}

                                <div className="col-lg cp text-center" onClick={this.signOut}>
                                    <a className="greencolor font-weight-bold small">Sign Out</a>
                                </div>

                                <button disabled={this.state.ajaxcalled} className="w-50 enterkeyevent login-btn small mt-4" type="button" onClick={this.handleDocUpload}>
                                    <span className="w-85 font-weight-bold ml-50">Submit</span>

                                    <span className="w-25 h-100 slant-arrow">
                                        {
                                            this.state.ajaxcalled 
                                                ? 
                                                <utilities.Gear style={{width: "26px", marginTop: "9px"}} /> 
                                                : 
                                                <svg width="1.5em" height="100%" viewBox="0 0 16 16" className="bi bi-arrow-right-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                    <path fillRule="evenodd" d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"/>
                                                </svg>
                                        }
                                    </span>
                                </button>

                                <p className="padding2">&nbsp;</p>
                                <p className="padding2">&nbsp;</p>

                            </div>
                        }

                        {this.state.profilecomplete &&  this.state.pendingtype.includes("livelinesscheckvideo") && this.state.pendingtype[0] === "livelinesscheckvideo" &&
                            <div>
                                <p className="padding2">&nbsp;</p>
                                <p className="padding2">&nbsp;</p>

                                <div className="form-header">
                                    <img src="assets/imgs/npf-logo-main.png" alt="" className="sign-up-icon w-20" />
                                </div>

                                <p className="padding2">&nbsp;</p>

                                <p className="text-danger p-3">Your liveliness check video was disapproved. Hence you are required to re-upload it using the form below.</p>

                                <p className="padding2">&nbsp;</p>

                                <this.LivelinessCheckVideo
                                    // this={this}
                                    // captureallowed = {this.state.captureallowed}
                                    // voiceallowed = {this.state.voiceallowed}
                                    // ismobile = {this.state.ismobile}
                                    // ajaxcalled = {this.state.ajaxcalled}
                                    // recordingstarted = {this.state.recordingstarted}
                                    // customervideo = {this.state.customervideo}
                                    // utilities = {Utilities}
                
                                    // startLivelinessCheckVideo = {Utilities.startLivelinessCheckVideo}
                                    // handleRecordingStop = {Utilities.handleRecordingStop}
                                    // handler = {this.handleSignIn}
                                />

                                <p className="padding2">&nbsp;</p>
                                <p className="padding2">&nbsp;</p>

                                <button disabled={this.state.ajaxcalled || !this.state.customervideo || this.state.recordingstarted} className="w-50 enterkeyevent login-btn small" type="button" onClick={this.handleVideoSubmit}>
                                    <span className="w-85 font-weight-bold ml-50">Submit</span>

                                    <span className="w-15 h-100 slant-arrow">
                                        {
                                            this.state.ajaxcalled 
                                                ? 
                                                <utilities.Gear style={{width: "26px", marginTop: "9px"}} /> 
                                                : 
                                                <svg width="1.5em" height="100%" viewBox="0 0 16 16" className="bi bi-arrow-right-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                    <path fillRule="evenodd" d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"/>
                                                </svg>
                                        }
                                    </span>
                                </button>

                                <p className="padding2">&nbsp;</p>

                                {/* <div className="col-lg cp text-center" onClick={this.switchToSignIn}>
                                    <a className="greencolor font-weight-bold small">Sign In</a>
                                </div> */}

                                <div className="col-lg cp text-center" onClick={this.signOut}>
                                    <a className="greencolor font-weight-bold small">Sign Out</a>
                                </div>

                                <p className="padding2">&nbsp;</p>

                            </div>
                        }

                        {this.state.profilecomplete &&  this.state.pendingtype.includes("livelinesscheckimage") && this.state.pendingtype[0] === "livelinesscheckimage" &&
                            <div>
                                <p className="padding2">&nbsp;</p>
                                <p className="padding2">&nbsp;</p>

                                <div className="form-header">
                                    <img src="assets/imgs/npf-logo-main.png" alt="" className="sign-up-icon w-20" />
                                </div>

                                <p className="padding2">&nbsp;</p>

                                <p className="text-danger p-3">Your liveliness check image was disapproved. Hence you are required to re-upload it using the form below.</p>

                                <utilities.LivelinessCheckImage
                                    this={this}
                                    captureallowed = {this.state.captureallowed}
                                    voiceallowed = {this.state.voiceallowed}
                                    ismobile = {this.state.ismobile}
                                    ajaxcalled = {this.state.ajaxcalled}
                                    recordingstarted = {this.state.recordingstarted}
                                    customervideo = {this.state.customervideo}
                                    customerphoto = {this.state.customerphoto}
                                    utilities = {Utilities}
                
                                    startLivelinessCheckVideo = {this.startLivelinessCheckVideo}
                                    handleRecordingStop = {this.handleRecordingStop}
                                    handler = {this.handlePhotoSubmit}
                                />

                                <div className="col-lg cp text-center" onClick={this.signOut}>
                                    <a className="greencolor font-weight-bold small">Sign Out</a>
                                </div>

                                <p className="padding2">&nbsp;</p>

                            </div>
                        }

                        {this.state.profilecomplete &&  this.state.pendingtype.includes("signature") && this.state.pendingtype[0] === "signature" &&
                            <div className="form-inner mx-auto row"> 
                                <p className="padding2">&nbsp;</p>
                                <p className="padding2">&nbsp;</p>

                                <div className="form-header">
                                    <img src="assets/imgs/npf-logo-main.png" alt="" className="sign-up-icon w-20" />
                                </div>

                                <p className="padding2">&nbsp;</p>

                                <p className="text-danger">Your signature was rejected. Hence you are required to re-upload it using the form below.</p>

                                <p className="padding2">&nbsp;</p>
                                
                                <utilities.Signature 
                                    signature = {this.state.signature}
                                    signatureinvalid = {this.state.signatureinvalid}
                                    signaturefilename = {this.state.signaturefilename}
                                    utilities = {utilities}
                                    size={"95%"}
                    
                                    handleInput = {this.handleInput}
                                    handleFileUpload = {this.handleFileUpload}
                                />

                                {/* <div className="col-lg cp text-center" onClick={this.switchToSignIn}>
                                    <a className="greencolor font-weight-bold small">Sign In</a>
                                </div> */}

                                <div className="col-lg cp text-center my-4" onClick={this.signOut}>
                                    <a className="greencolor font-weight-bold small">Sign Out</a>
                                </div>

                                <div className="w-100">
                                    <button disabled={this.state.ajaxcalled} className="w-50 enterkeyevent login-btn small" type="button" onClick={this.handleSignatureUpload}>
                                        <span className="w-85 font-weight-bold ml-50">Submit</span>

                                        <span className="w-25 h-100 slant-arrow">
                                            {
                                                this.state.ajaxcalled 
                                                    ? 
                                                    <utilities.Gear style={{width: "26px", marginTop: "9px"}} /> 
                                                    : 
                                                    <svg width="1.5em" height="100%" viewBox="0 0 16 16" className="bi bi-arrow-right-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                        <path fillRule="evenodd" d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"/>
                                                    </svg>
                                            }
                                        </span>
                                    </button>
                                </div>

                                <p className="padding2">&nbsp;</p>
                                <p className="padding2">&nbsp;</p>

                            </div>
                        }
                        
                        {utilities.urlSearchParams().get("source") !== "app" && <div className="chat-btn">
                            <utilities.ChatButton />
                        </div>}

                    </div>
                </section>

                <utilities.Modal
                    showmodal={this.state.targetmodal === "supportmailsmodal" ? this.state.showmodal : false} 
                    child={this.SupportMailsModal}
                />

            </article>
        );
    }

    componentDidMount() {

        if(store.get("user") && store.get("user").profilecomplete === "DISAPPROVED" && store.get("pendingtype") && store.get("pendingtype").length) {
            utilities.fetchDocumentTypes(this, httpservice);

            this.setState({profilecomplete: true, pendingtype: store.get("pendingtype")}, () => {
                if(store.get("pendingtype").includes("livelinesscheckvideo") && store.get("pendingtype")[0] === "livelinesscheckvideo") {
                    this.setState({recordingstarted: false, captureallowed: true});

                } else if(store.get("pendingtype").includes("livelinesscheckimage") && store.get("pendingtype")[0] === "livelinesscheckimage") {
                    Utilities.startLivelinessCheckImage(this);
                }
            });

        } else if(store.get("token") && !store.get("signupstage")) {
            this.props.history.push("/dashboard/Dashboard");
        } else {
            //store.clearAll();
        }
    }
}

export default Login;